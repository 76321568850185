import React, { useState } from "react";
import classNames from 'classnames'
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';
// 初始化激活列表，此处为选择填空
function initActiveStatusList(props) {
  const itemListBodyInput = props.TaskItemData['taskBodyItemInput']

  let tempStatusList = []
  itemListBodyInput.map(it => {
    let itList = []
    it['valList'].map((inputunit, inputunitind) => {
      itList.push({ symbFlag: false, valFlag: false, showValDelFlag: false })
      return true
    })
    tempStatusList.push(itList)
    return true
  })
  return tempStatusList
}

// 激活
function activeBlankPlace(text, setActiveStatusList, activeStatusListState, setCurrentChooseVal) {
  // 符号要提示到对应位置
  if (text === '+' || text === '-') {
    const newState = activeStatusListState.map(astate => {
      astate.map(ait => {
        if (ait.symbFlag === false) {
          ait.symbFlag = true
          ait.valFlag = false
        }
        return ait
      })
      return astate
    })
    setActiveStatusList(newState)
  } else if (text === "") {
    // 取消激活
    const newState = activeStatusListState.map(astate => {
      astate.map(ait => {
        ait.valFlag = false
        ait.symbFlag = false
        return ait
      })
      return astate
    })
    setActiveStatusList(newState)
  } else {
    const newState = activeStatusListState.map(astate => {
      astate.map(ait => {
        if (ait.valFlag === false) {
          ait.valFlag = true
          ait.symbFlag = false
        }
        return ait
      })
      return astate
    })
    setActiveStatusList(newState)
  }
  setCurrentChooseVal(text)
}

window.flagInterval = 0; // 长按与点击定时器判断
let flagVal = undefined

let cleanInputUnit = (inputind, unitind, props, type, currentChooseValState) => {
  // pc需要点击删除
  const TaskItemData = Object.assign(props.TaskItemData, {})
  console.log(TaskItemData['taskBodyItemInput'][inputind]['valList'][unitind][type]);
  if (!TaskItemData['taskBodyItemInput'][inputind]['valList'][unitind][type] && !currentChooseValState) return
  TaskItemData['taskBodyItemInput'][inputind]['valList'][unitind][type] = currentChooseValState
  if (TaskItemData['task-body-answer-status']) {
    TaskItemData['task-body-answer-status'][inputind]['valList'][unitind][`valFlag`] = true
  }
  props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
}
let longPress = (inputind, unitind, props, type, currentChooseValState, setActiveStatusList, activeStatusListState, setCurrentChooseVal) => {
  // 如果是长按删除对应按钮内容更新状态
  console.log('长按');
  clearInterval(window.flagInterval)
  flagVal = 'holdtodel'
}




// 更新
function updateFillForm(inputind, unitind, props, type, currentChooseValState, setActiveStatusList, activeStatusListState, setCurrentChooseVal) {
  // TODO: 更新逻辑迁移到useEffect
  if (!currentChooseValState) {
    return
  }
  console.log(currentChooseValState,type);
  // 如果选的是符号，普通位置不允许插入，选择的内容，符号位置不许插入
  if (!["+","-"].includes(currentChooseValState) && type !=='val') return
  if (["+","-"].includes(currentChooseValState) && type !=='symb') return
  const TaskItemData = Object.assign(props.TaskItemData, {})
  TaskItemData['taskBodyItemInput'][inputind]['valList'][unitind][type] = currentChooseValState
  if (TaskItemData['task-body-answer-status']) {
    TaskItemData['task-body-answer-status'][inputind]['valList'][unitind][`${type}Flag`] = true
    // 更新值时将符号状态同时更新
    if (type === 'val') {
      TaskItemData['task-body-answer-status'][inputind]['valList'][unitind][`symbFlag`] = true
    }
  }
  props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
  activeBlankPlace("", setActiveStatusList, activeStatusListState, setCurrentChooseVal)
}

function FillBlankForm(props) {
  const t = props.t
  console.log(props);
  const list = props.TaskItemData.list
  const subIns = props.TaskItemData['subIns']
  const subTips = props.TaskItemData['subTips']
  const itemList = props.TaskItemData['itemList']
  const itemListBodyInput = props.TaskItemData['taskBodyItemInput']
  const currentChooseVal = '';
  let activeStatusList = initActiveStatusList(props) // 存储各个选项框的可激活值作为列表 
  const [activeStatusListState, setActiveStatusList] = useState(activeStatusList)
  console.log(activeStatusListState);
  const [currentChooseValState, setCurrentChooseVal] = useState(currentChooseVal)


  if (!props) return <></>
  return <>
    <div className={classNames({"highLightFinnal":props.TaskItemData['highLightFinal'],task6_3:props.TaskItemData['taskId']==="6_3"},"Fill-blank")}>
      <div className="tab-list">
        <div className="tab-ins">{t(subIns)}</div>
        <div className="tab-tips">{t(subTips)}</div>
        <div className="tab-item-ls">
          {list.map((it, lind) => {
            if (!props.TaskItemData['isVectorChoice']) {
              return <div className={classNames({'active-item-in-bar':it===currentChooseValState},"tab-item")} key={lind + 'tab'} onClick={() => { activeBlankPlace(it, setActiveStatusList, activeStatusListState, setCurrentChooseVal) }}>{it}</div>
            } else {
              return <div className={classNames({'active-item-in-bar':it===currentChooseValState},"tab-item","vector-filled")} key={lind + 'tab'} onClick={() => { activeBlankPlace(it, setActiveStatusList, activeStatusListState, setCurrentChooseVal) }} dangerouslySetInnerHTML={{ __html: it }}></div>
            }
          })}
        </div>
      </div>
      <div className={classNames({ 'integrated': props.TaskItemData['isLineIntegrated'] }, "input-list")}>
        {props.TaskItemData.taskId === "8_1" &&
          <div className="status-List">
            <div className="status-Item">|φ<span className="minfont">0</span>&gt;</div>
            <div className="status-Item">|φ<span className="minfont">1</span>&gt;</div>
            <div className="status-Item">|φ<span className="minfont">2</span>&gt;</div>
            <div className="status-Item">|φ<span className="minfont">3</span>&gt;</div>
          </div>
        }
        {itemList.map((inputit, inputind) => {
          return (<div className="input-Item" key={inputind + 'input'}>
            <div className={classNames({ 'unlimitedName': props.TaskItemData['unlimitedName'] }, "input-name-part")} dangerouslySetInnerHTML={{ __html: t(inputit.name) }}></div>
            {itemListBodyInput[inputind]['valList'].map((unit, unitind) => {
              const valFlag = activeStatusListState[inputind][unitind].valFlag
              const symbFlag = activeStatusListState[inputind][unitind].symbFlag
              const showvalDelFlag = activeStatusListState[inputind][unitind].showvalDelFlag
              const showsymbDelFlag = activeStatusListState[inputind][unitind].showsymbDelFlag
              if (!props.TaskItemData['isVectorChoice']) {
                return (
                  <div className={classNames({ 'placeholderAfter': props.TaskItemData['marginItemNumber'] && props.TaskItemData['marginItemNumber'][unitind] }, 'input-unit')} key={unitind + 'input-unit'}>
                    <div className={classNames({
                       'active': valFlag, 
                       'filled': unit.val, 
                       'showDel': showvalDelFlag && (unit.val !== undefined && unit.val !== ""),
                       'error':(props.TaskItemData['task-body-answer-status'] && props.TaskItemData['task-body-answer-status'][inputind] && props.TaskItemData['task-body-answer-status'][inputind].valList && props.TaskItemData['task-body-answer-status'][inputind].valList[unitind].valFlag===false)  
                       }, "unit-val")} onClick={() => { updateFillForm(inputind, unitind, props, "val", currentChooseValState, setActiveStatusList, activeStatusListState, setCurrentChooseVal) }} >
                        {unit.val}
                        <div className="showDelContent" onClick={(e) => { cleanInputUnit(inputind, unitind, props, "val", currentChooseValState) }}>+</div>
                      </div>
                  </div>
                )
              } else {
                return (
                  <div className={classNames({ 'placeholderAfter': props.TaskItemData['marginItemNumber'] && props.TaskItemData['marginItemNumber'][unitind] }, 'input-unit')} key={unitind + 'input-unit'}>
                    <div className={classNames({ 'active': valFlag, 'filled': unit.val, 'showDel': showvalDelFlag &&
                     (unit.val !== undefined && unit.val !== ""),'error':(props.TaskItemData['task-body-answer-status'] &&
                      props.TaskItemData['task-body-answer-status'][inputind] && props.TaskItemData['task-body-answer-status'][inputind].valList &&
                      props.TaskItemData['task-body-answer-status'][inputind].valList[unitind] && 
                      props.TaskItemData['task-body-answer-status'][inputind].valList[unitind].valFlag===false)  }, "unit-val", "vector-filled")} 
                      onClick={() => { updateFillForm(inputind, unitind, props, "val", currentChooseValState, setActiveStatusList, activeStatusListState, setCurrentChooseVal) }} 
                       >
                        <div dangerouslySetInnerHTML={{ __html: unit.val }} ></div>
                        <div className="showDelContent" onClick={(e) => { cleanInputUnit(inputind, unitind, props, "val", currentChooseValState) }}>+</div>
                       </div>
                  </div>
                )
              }
            })}
          </div>)
        })}

      </div>
    </div>
  </>
}
// export default FillBlankForm
export default withNamespaces()(FillBlankForm);