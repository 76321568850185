/**
 * 根据不同设备提供相同的获取路由列表，跳转
 */
export const nativeUtil = {
  routeList : ["/course/list","/course/detail/123","/task/list","/task/detail"],
  resetUser: (user)=>{
    // 清空历史记录，清空弹窗状态，创建新用户
    // alert("reset")
    // sessionStorage.clear()
    localStorage.clear()
    localStorage.setItem("userId",(user || "test"+new Date().getTime()))
  }
}