import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import './GroupManager.less'
import i18n from '../../i18n';
import { Button, Table, Space, Modal, message, Select, Form, Input, Popover } from 'antd';
import 'antd/dist/antd.css'
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { splitLocationSearch } from '../../utils/index'
import { withNamespaces } from 'react-i18next';
import {fetcher} from '../../utils/fetcher'
import { ReactComponent as GroupList } from "./img/groupList.svg"
import { ReactComponent as Team } from "./img/team.svg"
import { ReactComponent as NoGroup } from "./img/noGroup.svg"
import { ReactComponent as NoTeam } from "./img/noTeam.svg"
import { ReactComponent as Member } from "./img/member.svg"
import { ReactComponent as Complete } from "./img/complete.svg"
import { ReactComponent as Correct } from "./img/correct.svg"
import Loading from '../components/loading'
import Disconnect from './img/disconnect.png'
const { Option } = Select;
function GroupManager({t}){

  console.log(t('groupManager.list'));
  const [isNewTeamVisible,setNewTeamVisible] = useState(false)
  const [isGroupBuildModalVisible,setGroupBuildModalVisible] = useState(false)
  const [inputVal, setInputVal] = useState("") // 创建小组的小组名称输入
  const [editGroupId, setEditGroupId] = useState(-1)
  const [courseList, setCourseList] = useState([])
  const [teamInputObj, setTeamInputObj] = useState({title:"", hardwareType: undefined}) // 小组信息
  const [delModalVisbleId, setDelModalVisibleId] = useState(-1) // 解散小组
  const [choosedCourse, setChooseedCourse] = useState(-1)
  const [teamList, setTeamList] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [teamInputTxtErrMsg, setTeamInputTxtErrMsg] = useState('')
  const [teamInputTypeErrMsg, setTeamInputTypeErrMsg] = useState('')
  const [timeoutStatus, setTimeoutStatus] = useState(false)
  const [showLoadingState, setShowLoading] = useState(false)
  const navigator = useNavigate()
  const location = useLocation()
  function handleCancel() {
    // 初始化创建设置
    setInputVal("")
    setGroupBuildModalVisible(false)
  }
  /**
   * 初始化创建小组信息
   */
  function handleTeamInputCancel() {
    setTeamInputObj({title:"", hardwareType: undefined})
    setNewTeamVisible(false)
    setTeamInputTxtErrMsg('')
    setTeamInputTypeErrMsg('')
  }
  /**
   * 创建新班群
   */
  function createCourse() {
    let fetcherUrl = editGroupId === -1 ? '/user/course/create' : '/user/course/updateName' ;
    let jsonData = editGroupId === -1 ? {name:inputVal.trim()} : {name:inputVal.trim(), id:editGroupId }
    let passFlag = validateGroupName(inputVal)
    if (!passFlag) return
    fetcher(fetcherUrl,'POST',JSON.stringify(jsonData)).then(res =>{
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        setInputVal("")
        setGroupBuildModalVisible(false)
        message.success(editGroupId === -1 ? t('groupManager.createGroup.successTips'):t('groupManager.createGroup.successUpdataInfo'))
        getCourse()
      } else if (re.status === 2006) {
        setErrMsg(t('groupManager.createGroup.nameExist'))
      } else {
        message.error(t('groupManager.createGroup.failedTips'))
      }
    })
  }

  /**
   * 删除班群
   */
  function handleDelGroupData(courseId) {
    fetcher(`/user/course/delete?courseId=${courseId}`,'DELETE').then(res => {
      return res.json()
    }).then(re => {
      if (re.status === 200) {
        message.success(t('groupManager.delSuccess'))
        setDelModalVisibleId(-1)
        getCourse()
      } else {
        message.error(t('groupManager.delFail'))
      }
    })
  }

  /**
   * 创建小组信息
   */
  function createTeam(courseId,teamInputObj) {
    let flag = false;
    let tempObj = {title:teamInputObj.title, hardwareType:teamInputObj.hardwareType}
    tempObj.title = tempObj.title.trim()
    if (!tempObj.title) {
      setTeamInputTxtErrMsg(t('groupManager.createTeam.enterTeamNameTips'))
      flag = true
    }
    if (computeChatLen(tempObj.title)>20) {
      flag = true
    }
    if (!tempObj.hardwareType) {
      setTeamInputTypeErrMsg(t('groupManager.createTeam.chooseAssessmentEquip'))
      flag = true
    }
    if (flag) return
    fetcher('/user/team/teacher/create','POST',JSON.stringify(Object.assign(tempObj,{courseId}))).then(res =>{
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        message.success(t('groupManager.createTeam.successTips'))
        getClassGroupTeamInfo(courseId)
        setNewTeamVisible(false)
      } else {
        message.error(t('groupManager.createTeam.failedTips'))
      }
      setTeamInputObj({title: '', hardwareType: undefined})
    })
  }

  /**
   * 获取班群
   */
  function getCourse() {
    setShowLoading(true)
    fetcher('/user/course/getAllInfo','GET').then(res => {
      if (res.status === 200) {
        return res.json()
      } else {
        setShowLoading(false)
        message.error("获取数据失败")
        throw(new Error("获取异常"))
      }
    }).then(re => {
      console.log(re);
      setTimeoutStatus(false)
      if (re.items) {
        setCourseList(re.items)
        // 如果存在班群，设置第一个默认的用于匹配他的学习小组
        console.log(location);
        const pageSearchObj = splitLocationSearch(location.search)
        console.log(pageSearchObj);
        if (re.items.length === 0) {setShowLoading(false);return;}
        let readCourseId = pageSearchObj === null ? re.items[0].id : Number(pageSearchObj.courseId)
        console.log(readCourseId);
        if (readCourseId) {
          getClassGroupTeamInfo(readCourseId)
          setChooseedCourse(readCourseId)
        }
      }
    }).catch(e => {
      // 超时
      setShowLoading(false)
      // if (e.status === 504) {
      setTimeoutStatus(true)
      // }
      console.log(e);
      message.error(t('stuRegister.netWorkErr'))
    })
  }
  useEffect(()=>{
    getCourse()
  },[])

  /**
   * 获取该班群的学习小组信息
   */
  function getClassGroupTeamInfo(id) {
    fetcher('/user/team/teacher/getAllInfo?courseId='+id,'GET').then(res => {
      return res.json()
    }).then(re =>{
      if (re.status === 200) {
        console.log(re)
        setTeamList(re.items)
      }
      setShowLoading(false)
    })
  }

  /**
   * @param {string} val form表单的校验 
   */
  const validateGroupName = (val) => {
    // val
    let passFlag = false
    console.log(val);
    console.log((computeSpaceLen(val.toString())+50));
    setInputVal(val)
    let uploadVal = val.trim()
    if (!uploadVal) {
      setErrMsg(t('groupManager.createGroup.enterName'))
    } else {
      let reg = new RegExp(/^[\u4e00-\u9fa5a-zA-Z0-9\s]+$/)
      if (!reg.test(uploadVal)) {
        setErrMsg(t('groupManager.createGroup.enterErrCode'))
      } 
      else if (computeChatLen(uploadVal) > 50) {
        // 字数超了
        passFlag = false // 可以省略只是担心省略之后会忘记这个If的意义
      }
      else {
        setErrMsg('')
        console.log(uploadVal);
        console.log(inputVal);
        passFlag = true
      }
    }
    return passFlag
  };

  const validateTeamDataInfo = (val) => {
    setTeamInputObj({title:val,hardwareType:teamInputObj.hardwareType})
    if (!val.trim()) {
      setTeamInputTxtErrMsg(t('groupManager.createTeam.enterTeamNameTips'))
    } else {
      let reg = new RegExp(/^[\u4e00-\u9fa5a-zA-Z0-9\s]+$/)
      if (!reg.test(val.trim())) {
        setTeamInputTxtErrMsg(t('groupManager.createGroup.enterErrCode'))
      }
      else {
        setTeamInputTxtErrMsg('')
      }
    }
  }

  /**
   * 
   * @param {*} id 所修改的组
   * @param {*} name 原名称
   */
  const editGroupName = (id, name) => {
    setGroupBuildModalVisible(true)
    setInputVal(name)
    setEditGroupId(id)
  }

  const PopoverContent = (id,name) => {
    return (<div className="groupPopperButton">
      <Button onClick={()=>{editGroupName(id,name)}}>{t('groupManager.editName')}</Button>
      <div className="lineInBtn"></div>
      <Button onClick={()=>{setDelModalVisibleId(id)}}>{t('groupManager.deComposeGroup')}</Button>
      </div>)
  }
  
  const computeSpaceLen = (val) => {
    let matcher = val.match(/\s+/g)
    let count = 0
    matcher && matcher.map(it => {
      count+=it.length
      return false
    })
    return count
  }

  const computeChatLen = (val) => {
    if (!val) return 0
    // let matcher = val.match(/\S+/g)
    // let count = 0
    // matcher && matcher.map(it => {
    //   count+=it.length
    //   return false
    // })
    return val.toString().length
  }

  return <>
  <div key="groupManager" className="groupManager">
    <div className="classListManager">
      <div className="tag">
        <GroupList></GroupList>
        {t('groupManager.list')}
      </div>
      <div className="content">
        {courseList.length === 0 && <div className={classNames({"timeout":timeoutStatus},"blankWrap")}>
          <div className="blank">
            {timeoutStatus?<img src={Disconnect} />:<NoGroup></NoGroup>}
            <p>{timeoutStatus?t('global.netWorkDisconnected'):t('groupManager.noGroupData')}</p>
          </div>
          {!timeoutStatus && <Button className="classGroupBtn" onClick={()=>{setEditGroupId(-1);setGroupBuildModalVisible(true)}}>+ {t('groupManager.createGroup.tag')}</Button>}
        </div>}
        { courseList.length > 0 &&
          courseList.map((it,ind) => {
            return (<div onClick={()=>{navigator(`/eduManager/groupManager?courseId=${it.id}`);setChooseedCourse(it.id);getClassGroupTeamInfo(it.id)}} className={classNames({active:choosedCourse===it.id},"courseItem")} >
            <div className="indBar">
              <span>{ind+1}</span>
              <Popover placement="leftTop" className="groupPopper" style={{padding:0}} content={PopoverContent(it.id,it.name)} trigger="click">
                <EllipsisOutlined />
              </Popover>
            </div>
            <div className="groupNameContent">
              {it.name}
            </div>
          </div>)
          })
        }
        { courseList.length > 0 && !timeoutStatus && <div className="classGroupWrapper"><Button className="classGroupBtn existBtn" onClick={()=>{setEditGroupId(-1);setGroupBuildModalVisible(true)}}>+ {t('groupManager.createGroup.tag')}</Button></div>}
      </div>
    </div>
    <div className="studyGroupManager">
      <div className="tag">
        <Team></Team>
        {t('groupManager.stuGroup')}
      </div>
      {courseList.length > 0 && <Button type="link" className="addTeam" onClick={()=>{setNewTeamVisible(true)}}>+ {t('groupManager.createTeam.tag')}</Button>}
      <div className="content">
        {teamList.length === 0 && <div className="blankWrap">
          <div className="blank">
            {timeoutStatus?<img src={Disconnect} />:<NoTeam></NoTeam>}
            <p>{timeoutStatus?t('global.netWorkDisconnected'):t('groupManager.noTeamData')}</p>
          </div>
        </div>}
        {teamList.map(team => {
          return (<div key={team.id} className="teamItem" onClick={()=>{navigator(`/eduManager/teamDetail?id=${team.id}&username=${team.username}`)}}>
            <div className="teamTitle">{team.title}</div>
            <div className="teamAttrs">
              <p className="teamAttr members">
                <Member className="svgIconInTeamList memberIcon" />
                <span className="label">{t('groupManager.member')}：</span>
                {team.studentCount === 0 ? '--' : team.studentCount}
                {team.studentCount !== 0 && <span>{t('lang')==='cn'?'人':''}</span>}
              </p>
              <p className="teamAttr complete">
                <Complete className="svgIconInTeamList completeIcon" />
                <span className="label">{t('groupManager.completedExercise')}：</span>
                {team.exerciseFinishedCount === 0 ? '--' : team.exerciseFinishedCount}
                {team.exerciseFinishedCount !== 0 && <span>{t('lang')==='cn'?'道':''}</span>}
              </p>
              <p className="teamAttr correct">
                <Correct className="svgIconInTeamList correctIcon" />
                <span className="label">{t('groupManager.correctRate')}：
                {team.exerciseFinishedCount === 0 ? '--': 
                  Number(team.exerciseSuccessCount)===0 ? '0%': (Number(team.exerciseSuccessCount / team.exerciseFinishedCount) *100).toFixed(0) + '%'}
                </span>
              </p>
            </div>
          </div>)
        })}
      </div>
    </div>
    <Modal
      closable={false}
      className="groupManagerModal" title={editGroupId===-1?t('groupManager.createGroup.newGroup'):t('groupManager.createGroup.updateInfo')} visible={isGroupBuildModalVisible} onCancel={()=>{handleCancel()}}
      footer={[<Button key="cancelbtn2" onClick={()=>{handleCancel()}}>{t('global.cancel')}</Button>,<Button key="submit1" disabled={computeChatLen(inputVal.toString())>50} type="primary" onClick={()=>{createCourse()}}>
      {editGroupId===-1?t('global.confirm'):t('global.save')}
    </Button>]}
    >
      <div className="tips">
        <p>{t('groupManager.createGroup.groupName')}：</p>
        <Form
          name="basic"
          autoComplete="off"
        >
          <Form.Item
          help={errMsg}
        >
          <Input  value={inputVal} onInput={(e)=>{console.log(e);validateGroupName(e.target.value);}} placeholder={t('groupManager.createGroup.valid')}/>
        </Form.Item>
          <p className={classNames({warning:computeChatLen(inputVal.toString())>50},"showLen")}>{computeChatLen(inputVal.toString())}/50</p>
        </Form>
      </div>
    </Modal>
    <Modal
      closable={false}
      key="teamModal" className="groupManagerModal" title={t('groupManager.createTeam.popTit')} visible={isNewTeamVisible} onCancel={()=>{handleTeamInputCancel()}}
      footer={[<Button key="cancelbtn2" onClick={()=>{handleTeamInputCancel()}}>{t('global.cancel')}</Button>,<Button key="submit1" disabled={computeChatLen(teamInputObj.title.toString())>20} type="primary" onClick={()=>{createTeam(choosedCourse,teamInputObj)}}>
      {t('global.confirm')}
    </Button>]}
    >
      <div className="tips">
        <p>{t('groupManager.groupName')}：</p>
        <Form
          name="basic"
          autoComplete="off"
        >
          <Form.Item
            help={teamInputTxtErrMsg}
          >
            <Input value={teamInputObj.title} onInput={(e)=>{validateTeamDataInfo(e.target.value); }} placeholder={t('groupManager.createGroup.valid')} />
            </Form.Item>
            <p className={classNames({warning:computeChatLen(teamInputObj.title.toString())>20},"showLen")}>{computeChatLen(teamInputObj.title.toString())}/20</p>
            <Form.Item
              help={teamInputTypeErrMsg}
            >
            <p className="createTeamTips">
              <span>{t('groupManager.groupTips1')}</span>
              <span className="subTips">{t('groupManager.groupTips2')}</span>
            </p>
            <Select trigger="" placeholder={t('groupManager.createTeam.selPlaceHolder')} value={teamInputObj.hardwareType} onChange={(val)=>{setTeamInputObj({title:teamInputObj.title,hardwareType:val});setTeamInputTypeErrMsg('')}}>
              <Option title="" className="selOptInCreateTeam" value="gemini">{t('equips.gemini')}</Option>
              <Option title="" className="selOptInCreateTeam" value="triangulum">{t('equips.triangulum')}</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
    <Modal
      closable={false}
      key="delModal" className="eduModal delModal" title={t('groupManager.deComposeGroup')} visible={delModalVisbleId!==-1} onCancel={()=>{setDelModalVisibleId(-1)}}
      footer={[<Button key="cancelbtn1" onClick={()=>{setDelModalVisibleId(-1)}}>{t('global.cancel')}</Button>,<Button key="submit2" type="primary" onClick={()=>{handleDelGroupData(delModalVisbleId)}}>
      {t('groupManager.del')}</Button>]}
    >
      <div className="tips">
        <ExclamationCircleOutlined /> {t('groupManager.delTips')}
      </div>
    </Modal>
  </div>
  <div className={classNames({'showLoading':showLoadingState},"loading-wrap")}>
    <Loading />
  </div>
  </>
}
export default withNamespaces()(GroupManager);
