import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import ProNav from '../components/proNav'
import { Button, Input, Space } from 'antd';
import {fetcher} from '../../utils/fetcher'
import SparkMD5 from 'spark-md5';
import { initHandler } from "../../utils/initHandler";

function Login(params) {
  const { t } = params;
  const location = useLocation()
  console.log(location.pathname);
  const [userNameValue, setUserNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [licenseId, setLicenseId] = useState('');
  const [stuCode, setStuCode] = useState('');
  // 设置license
  const handleLicenseChange = (event) => {
    setLicenseId(event.target.value);
  }
  // 设置账号
  const handleUserNameChange = (event) => {
    setUserNameValue(event.target.value);
  };
  // 设置密码
  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
  };
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState('teacher');


  // 教师登录
  const teacherLogin = () => {
    fetcher('/user/teacher/login','POST',JSON.stringify({email: userNameValue, password: SparkMD5.hash(passwordValue)})).then(res => {
      console.log(res)
      return res.json()
    }).then(async (re) => {
      console.log(re);
      if (re.status === 200) {
        await initHandler(JSON.stringify(re))
        // 先调用一下更新license的接口，通过getInfo获取
        fetcher('/user/teacher/getInfo','GET').then(res => {
          return res.json()
        }).then(result => {
          console.log(result,"get teacher info");
          if (result.status === 200) {
            localStorage.setItem('institutionLicense',result.item.institution.license)
            localStorage.setItem('username',result.item.name || result.item.email)
            navigate('/eduManager/stuRegister')
          } else {
            
          }
          // 需要设置token
        })
      } else {
        if (re.status === 2027) {
          setPasswordError(`${t('userLogin.inputError')}`)
        } else {
          setPasswordError(re.msg)
        }
      }
    })
  };

  // 学生登录
  const studentLogin = () => {
    fetcher('/user/team/webLogin','POST',JSON.stringify({institutionLicense: licenseId, teamUsername: stuCode})).then(res => {
      console.log(res)
      return res.json()
    }).then(async (re) => {
      console.log(re);
      if (re.status === 200) {
        await initHandler(JSON.stringify(re))
        localStorage.setItem('username',re.username)
        // 需要设置token
        navigate('/course/list')
      } else {
        setPasswordError(re.msg)
      }
    })
  };

  return (
    <div className="loginWrapper">
      <div className="loginBlock">
        <div className="loginBody">
          <div className="welcome">{t('userLogin.login')}</div>
          <div className="loginType">
            <div onClick={()=>{setLoginType('teacher')}} className={`loginItem ${loginType==='teacher'?'active':''}`}>{t('userLogin.teacherType')}</div>
            <div onClick={()=>{setLoginType('student')}} className={`loginItem ${loginType==='student'?'active':''}`}>{t('userLogin.studentType')}</div>
          </div>
          <div className="inputBlock">
            {
              loginType === 'student' ? 
                <Input value={licenseId} onChange={handleLicenseChange} type="text" placeholder={t('userLogin.licenseInput')} /> :
                <Input value={userNameValue} onChange={handleUserNameChange} type="text" placeholder={t('userLogin.emailInputTips')} />
            }
            {
              loginType === 'student' ?
              <Input.Password value={stuCode} onChange={(e) => {setStuCode(e.target.value)}} onKeyDown={
                (e) => { if(e.key === 'Enter') {studentLogin()} }
              } type="text" placeholder={t('userLogin.studentLoginCodeInput')} /> :
              <Input.Password value={passwordValue} onChange={handlePasswordChange} onKeyDown={
                (e) => { if(e.key === 'Enter') {teacherLogin()} }
              } placeholder={t('userLogin.pwdInputTips')} />
              
            }
          </div>
          
          {
            loginType === 'student' ? 
              <Button onClick={studentLogin} className={`loginBtn ${!licenseId || !stuCode ? 'disabled' : ''}`} disabled={!licenseId || !stuCode}>{t('userLogin.login')}</Button> : 
              <Button onClick={teacherLogin} className={`loginBtn ${!userNameValue || !passwordValue ? 'disabled' : ''}`} disabled={!userNameValue || !passwordValue}>{t('userLogin.login')}</Button>
          }
          {passwordError ? <div className="errorInput">{t('userLogin.inputError')}</div> : null}

          <p><span className="cursorPoint" onClick={()=>{navigate('/user/register')}}>{t('userLogin.registerNow')}</span> <span>|</span> <span className="cursorPoint" onClick={()=>{navigate('/user/resetPassword')}}>{t('userLogin.forgetPwd')}</span> </p>
        </div>
      </div>
      <div className="footerIntrod">
        <p>{t('userLogin.footer1')}</p>
        <p>{t('userLogin.footer2')}</p>
      </div>
    </div>
  );
}



export default withNamespaces()(Login);
