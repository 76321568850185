import React, {useEffect} from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import {nativeUtil} from './nativeUtil'
import { Toast } from 'antd-mobile'
import { uploadDataHandler } from './utils/uploadDataHandler'
import { initHandler } from './utils/initHandler'
import i18n from './i18n';
import { withNamespaces } from 'react-i18next';
import classNames from "classnames";
import "./utils/preloader"
import Header from './pages/header/Header'
// import VConsole from 'vconsole'

function Home({t}) {
  // TODO: 获取如果是安卓端，获取url中的参数匹配 accountId,username,accessToken,username,role
  window.initHandler = initHandler
  window.uploadDataHandler = uploadDataHandler
  // window.vConsole = new VConsole();
  console.log("HOME");
  let navigate = useNavigate();
  const location = useLocation();
  window.setGlobalLang = (lang)=>{
    localStorage.setItem('globalLang',(lang||'cn'))
    i18n.changeLanguage(lang);
    console.log("调用了语言设置:设置语言为"+lang);
    window.location.reload()
  }
  window.NativeUtil = Object.assign(nativeUtil,{navigate})
  
  window.oncontextmenu=function(e){
    //取消默认的浏览器自带右键 很重要！！
    e.preventDefault();
  }
  // 检查如果没有token，跳转/user/login
  useEffect(() => {
    let accessToken = localStorage.getItem('accessToken')
    console.log(accessToken,"accessToken");
    if (!accessToken || accessToken === "-1") {
      if (location.pathname !== '/user/register' && location.pathname !== '/user/resetPassword' && location.pathname !== '/user/login') {
        navigate('/user/login');
      }
    } else {
      // 如果有token，又直接打开网站的空路由，跳转到/course/list
      if (location.pathname === '/') {
        navigate('/course/list');
      }
    }
  }, [navigate, location]);  // 添加navigate为依赖项

  return (
    <>
      {/* 占位，ppt中会用他做计算 */}
      <div id="fixBox">
      </div> 
      <Header></Header>
      <div className={classNames({'enApp':t('lang')==='en'},"app","pro")}>
        <Outlet></Outlet>
      </div>
    </>
  )
}
export default withNamespaces()(Home);
