import CircuitBoardComponent from "./circuitBoard/CircuitBoardComponent.js";
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import TruthTable from "./TruthTable";

function TaskDesignAndValidForm(props,ref) {
  const childRef = useRef();
  const circuitData = props.TaskItemData['taskDataList']
  // 对于线路
  function setDesignCircuit(state) {
    console.log(state);
    const cpTaskItemData = JSON.parse(JSON.stringify(props.TaskItemData))
    cpTaskItemData['taskDataList'][0] = state
    props.setTaskData(cpTaskItemData)
  }
  // 对于表单
  function setValidForm(state) {
    const cpTaskItemData = JSON.parse(JSON.stringify(props.TaskItemData))
    cpTaskItemData['taskDataList'][1] = state
    props.setTaskData(cpTaskItemData)
  }
  function updateData(ind,e) {
    console.log(e.target.value);
    const TaskItemData = Object.assign(props.TaskItemData,{})
    TaskItemData['taskBodyItemInput'][ind] = e.target.value.trim()
    if (TaskItemData['task-body-answer-status']) {
      TaskItemData['task-body-answer-status'][ind] = true
    }
    props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
  }
  useImperativeHandle(ref, () => ({
    validate:(callback) => {
      // TODO: 校验线路与填空正确后调用回调
      // console.log("design validate",callback);
      // circuitSetCallBack(flag)
      childRef.current.validate((isError)=>{
        console.log(isError);
        if (isError) {
          // 该线路异常
          callback(isError)
        } else {
          callback(isError)
        }
      });
    }
  }))
  console.log(CircuitBoardComponent);
  if (props.TaskItemData.type !== 'TaskDesignAndValidForm') return <></>
  const table = <TruthTable key={props.TaskItemData['taskDataList'][1]['taskId']+1} TaskItemData={props.TaskItemData['taskDataList'][1]}  setTaskData={setValidForm}></TruthTable>
  console.log(props);
  // const table = props.TaskItemData['taskDataList'].map((Taskit,ind)=>{
    return (<div className="TaskDesignAndValidForm" key={'TaskDesignAndValidForm'}>
      <div className="circuit-part">
        {
          <CircuitBoardComponent ref={childRef} key={"CircuitBoardComponent"+props.TaskItemData.taskId} TaskItemData={circuitData[0]} setTaskData={setDesignCircuit} />
        }
      </div>
      <div className="form-part">
        {
          table
        }
      </div>
    </div>)
  // })
  // return table
}
export default forwardRef(TaskDesignAndValidForm)