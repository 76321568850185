import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'; // 注意pro和mini这里不一样不能随便合代码啊！！！

import {Container} from './Container'
import { useParams, useNavigate } from "react-router-dom";
// import simulator from '@/utils/simulatorInMatrix/index'
import {GateExecutor} from '@/utils/simulatorInLogic/gateExecutor.ts'
import { usePreview } from 'react-dnd-preview'
import './circuitBoard.less'
import classNames from 'classnames'
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import callEquipmentByMessage from '../../../../utils/bridge'
import { createPortal } from 'react-dom';

const MyPreview = () => {
  const {display, itemType, style, ref, monitor, item} = usePreview()
  if (!display) {
    return null
  }
  style.transform = `${style.transform} scale(0.9)`  // pc由于缩放尺寸需要调整
  style.WebkitTransform = `${style.transform} scale(0.9)` 
  console.log(style);
  return createPortal(<div className={classNames('gate-'+itemType,'gate')} style={{...style,opacity:0.3,zIndex:9}}></div>,document.body)
}

function CircuitBoardComponent(props, ref) {
  console.log("changeComponent");
  const GATESIZE = 98 // 宽高相等，注意要与circuitBoard.less中匹配一致
  const TAGWIDTH = 110 // 左侧tag宽
  const CIRCUITMAXX =  props.TaskItemData.circuitBoardInfo && props.TaskItemData.circuitBoardInfo.maxX // 横向gate最大数
  console.log(props.TaskItemData);
  const CIRCUITMAXY = props.TaskItemData.circuitBoardInfo && props.TaskItemData.circuitBoardInfo.bitsNum + 1 // 纵向最大数
  const PageRenderAttr = {GATESIZE,TAGWIDTH,CIRCUITMAXX,CIRCUITMAXY}
  const [pageRenderAttrState, setPageRenderAttrState] = useState(PageRenderAttr)
  const {courseId2taskId} = useParams()
  const onResize = e => {
    let newRemUnit = Number(window.getComputedStyle(document.documentElement)["fontSize"].split("px")[0])
    PageRenderAttr.GATESIZE = GATESIZE / 256 * newRemUnit
    PageRenderAttr.TAGWIDTH = TAGWIDTH / 256 * newRemUnit
    setPageRenderAttrState(PageRenderAttr)
  }
  // 此处注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // getVal 就是暴露给父组件的方法
    validate: (circuitSetCallBack) => {
      // 处理为QT中需要的数据结构
      window.circuitSetCallBack = circuitSetCallBack
      const composeData = props.TaskItemData.operations.map(op => {
        // 如果为16-1需要获取头部三个H并更改为空格或者X对应上填空部分TODO:
        if (props.TaskItemData.taskId === '16_1') {
          if (op.timeSlot === 1 && op.qubits[0]<=3) {
            // DesignAndComputeCircuit已经帮忙做了过滤，这里都是合法参数
            const inputXStr = props.BodyInputFor16[0];
            let gate = undefined;
            [inputXStr[0],inputXStr[1],inputXStr[2]].map((status,index)=>{
              if (status === '0' && Number(op.qubits[0]) === (index+1)) {gate = undefined}
              else if (status === '1' && Number(op.qubits[0]) === (index+1)){
                gate = {
                  angle:0,
                  controlQubit:-1,
                  controlQubit2:-1,
                  delay:0,
                  qubitIndex:op.qubits[0]-1,
                  timeslot:op.timeSlot,
                  type:'X'
                }
              }
              return true
            })
            return gate
          }
        } else if (['5_2','5_3'].includes(props.TaskItemData.taskId)) {
          // 5_2 5_3 5_4的情况比较特殊需要多种条件进行判断
          if (op.timeSlot < 3 || op.timeSlot > 6) return undefined
        }
        return {
          angle:0,
          controlQubit:op.gate.gtag==='C2'?op.qubits[1]-1:-1,
          controlQubit2:-1,
          delay:0,
          qubitIndex:op.qubits[0]-1,
          timeslot:op.timeSlot,
          type:op.gate.gname
        }
      })
      // let _simulator = new simulator(props.TaskItemData.circuitBoardInfo.bitsNum)
      // _simulator.measure(composeData)
      let bitsNum = props.TaskItemData.circuitBoardInfo.bitsNum
      let executor = new GateExecutor(bitsNum)
      let sortedOperations = composeData.sort((a,b)=>{return a.timeslot-b.timeslot})
      let filterOperations = sortedOperations.filter(it => {
        return it!==undefined && it.timeslot > 0
      })
      let msg = {type:'task',operations:filterOperations,bitsNum}
      let parseRes = executor.execute(filterOperations)
      console.log(parseRes);
      // 调用executor
      // callEquipmentByMessage(JSON.stringify(msg),window.taskCallback = (res)=>{
        // 如果有返回值，判断结果是否匹配正确答案，
        // alert(res)
        // const parseRes = JSON.parse(res)
        // TODO:16-1需要的校验方式不同，主要观察后两位比特是否为1来
        if (props.TaskItemData.taskId === '16_1') {
          // 读取所有结果判断是否在xxx11处和为1
          let totalVal = 0
          const finalRes = parseRes.result.map((reitem,ind) => {
            if ((ind-3)%4===0) {
              totalVal+=Math.abs(parseRes.model[ind])
            }
            if (reitem < 0) {
              return `-${(parseRes.model[ind].toFixed(4))}`
            } else {
              return (parseRes.model[ind].toFixed(4))
            }
          })
          // alert("finalRes: " + finalRes)
          // alert("totalVal"+totalVal)
          // errorflag
          let errFlag16_1 = totalVal.toFixed(1)!=="1.0"
          console.log(errFlag16_1);
          window.circuitSetCallBack && window.circuitSetCallBack(errFlag16_1)
          return null
        }
        // 1.需要result中的符号 ； 2.需要投影概率的值
        const finalRes = parseRes.result.map((reitem,ind) => {
          if (reitem < 0) {
            return `-${(parseRes.model[ind].toFixed(4))}`
          } else {
            return (parseRes.model[ind].toFixed(4))
          }
        })
        // alert("finalRes: " + finalRes)

        // 相位可以完全相反也能算正确
        const RevertfinalRes = parseRes.result.map((reitem,ind) => {
          if (reitem <= 0) {
            return (parseRes.model[ind].toFixed(4))
          } else {
            return `-${(parseRes.model[ind].toFixed(4))}`
          }
        })
        let Ordererrorflag = false
        let Reverterrorflag = false
        for (let i = 0; i < finalRes.length; i++) {
          if (finalRes[i] !== props.TaskItemData.correctAnswer[i] ) {
            Ordererrorflag = true
          }
        }
        for (let i = 0; i < RevertfinalRes.length; i++) {
          if (RevertfinalRes[i] !== props.TaskItemData.correctAnswer[i] ) {
            Reverterrorflag = true
          }
        }
        // alert("correctAnswer: " + props.TaskItemData.correctAnswer)
        // alert(Ordererrorflag&&Reverterrorflag)
        window.circuitSetCallBack && window.circuitSetCallBack(Ordererrorflag&&Reverterrorflag)
      // })
      // 2.call QT
      return sortedOperations
    }
  }));
  useEffect(() => {
    let newRemUnit = Number(window.getComputedStyle(document.documentElement)["fontSize"].split("px")[0])
    PageRenderAttr.GATESIZE = GATESIZE / 256 * newRemUnit
    PageRenderAttr.TAGWIDTH = TAGWIDTH / 256 * newRemUnit
    setPageRenderAttrState(PageRenderAttr)
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])
  if (!props.TaskItemData.circuitBoardInfo || !props.TaskItemData.circuitBoardInfo.bitsNum) {
    return <></>
  } else {
    console.log(props.TaskItemData.circuitBoardInfo);
    console.log(pageRenderAttrState);
  }

  if (props.TaskItemData.type !== 'CircuitBoardComponent') return <></>
  return (<div className={classNames({errFlag:props.TaskItemData.IsCircuitErr},'circuitBoard')}>
    <div id="mask" style={{display:'none'}}></div>
    <DndProvider backend={HTML5Backend} options={{
    enableTouchEvents: true,
    enableMouseEvents: true,
    enableHoverOutsideTarget: true,
  }}>
      <Container TaskItemData={props.TaskItemData} setTaskData={props.setTaskData} PageRenderAttr={pageRenderAttrState}/>
      <MyPreview  />
    </DndProvider>
  </div>)
}
export default forwardRef(CircuitBoardComponent)