import classNames from "classnames";
import i18n from '../../../i18n';
import {isDigit} from '@/utils'
import { withNamespaces } from 'react-i18next';
import { create, all, Complex } from 'mathjs'
const config = { }
const math = create(all, config)
console.log(math.complex("-i"));

function buildTableTips(TaskItemData, t) {
  console.log(TaskItemData);
    return <>{TaskItemData.tips && <div className={classNames({task1_4:TaskItemData.taskId==="1_4"},"tips")}>{t(TaskItemData.tips)}</div>}
    {TaskItemData.subTips && <div className="tab-tips">{t(TaskItemData.subTips)}</div>}
    </>
}

function buildTruthTableHeader(TaskItemData,t) {
  if (TaskItemData.taskId==='6_2' && TaskItemData.tabIndex===0) {
    const taskAppendix = TaskItemData.taskAppendix
    return <div className="fillTableWrapper">
      <div className="tableTipsFor6-2">{t(taskAppendix.tit)}</div>
    </div>
  }
  const MainHeader = TaskItemData['taskHeader'].map((hit,hindex)=>{
    return <div className="table-header-item" key={'header'+hindex} dangerouslySetInnerHTML={{__html:t(hit)}}></div>
  })
  const subHeaderFlag = TaskItemData['taskSubHeader'] ? true : false
  const SubHeader = TaskItemData['taskSubHeader'].map((hsubit,subindex) => {
    return <div className="table-header-item" key={'header-item'+subindex} dangerouslySetInnerHTML={{__html:t(hsubit)}}></div>
  })
  const newHeaderFlag = TaskItemData['newHeader'] ? true : false
  const NewFlexHeader = () => {
    return <div className="table-flex-header">
      <div className="left-header" style={{flex:TaskItemData['newHeader'].left && TaskItemData['newHeader'].left.flex}}>
        <div className="table-header">
          <div className="table-header-item" key={'header-left'}>{t(TaskItemData['taskHeader'][0])}</div>
        </div>
      </div>
      <div className="right-header" style={{flex:TaskItemData['newHeader'].right && TaskItemData['newHeader'].right.flex}}>
        <div className="table-header">
          <div className="table-header-item" key={'header-right'}>{t(TaskItemData['taskHeader'][1])}</div>
        </div>
        <div className="table-header">{SubHeader}</div>
      </div>
    </div>
  }
  return <div className="header-wraper">
    {newHeaderFlag? (NewFlexHeader()) : (<><div className="table-header">
      {MainHeader}
    </div>
    {subHeaderFlag? (<div className="table-header">{SubHeader}</div>):''}</>)
    }
  </div>
}

function buildTruthTableBody(TaskItemData,props) {
  const t = props.t
  // 对于6_2 tab = 0的题目
  const taskId = TaskItemData['taskId']
  if (taskId === '6_2' && TaskItemData.tabIndex===0) {
    const taskAppendixList = TaskItemData.taskAppendix.list.map((it,wrapperInd) => {
      return <div className="singleTask" key={wrapperInd+'singleTaskForFillTable'}>
        <div className="item-topic">{t(it.topic)}</div>
        <div className="item-content">
          <div className="left-part">{it.contentLeft.map((leftit,ind) => {
            if (wrapperInd >= 2) {
              // wrapperInd === 2 或 === 3
              return <div className="label">{wrapperInd === 2? '|100' : '|000'} &gt; → <div className="inputWrap">
                <input autoComplete="off" type="tel" className={
                  classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2][0]===false)})}
                   value={TaskItemData['taskBodyItemInput'][(wrapperInd-2)*2][0]}
                   onChange={(e)=>updateData((wrapperInd-2)*2,0,props,taskId,e)}/></div>&nbsp;→&nbsp;
                   <div className="inputWrap"><input autoComplete="off" type="tel" className={
                  classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2][1]===false)})}
                   value={TaskItemData['taskBodyItemInput'][(wrapperInd-2)*2][1]}
                   onChange={(e)=>updateData((wrapperInd-2)*2,1,props,taskId,e)} /></div></div>}
            else {return <><div className="label" dangerouslySetInnerHTML={{__html:leftit}}></div></>}
          })}</div>
          <div className="right-part">{it.contentRight.map((rightit,ind) => {
            if (wrapperInd >= 2) {return <div className="label">{t('lang')==='en'?'':'输出'}C<i className="minfont">0</i>S=<div className="inputWrap">
              <input autoComplete="off" type="tel" className={
                  classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2+1] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2+1][0]===false)})}
                   value={TaskItemData['taskBodyItemInput'][(wrapperInd-2)*2+1][0]}
                   onChange={(e)=>updateData((wrapperInd-2)*2+1,0,props,taskId,e)} /></div>{wrapperInd === 2 ? ', 1 + 0 =': ', 0 + 0 ='}
                   <div className="inputWrap">
                     <input autoComplete="off" type="tel" className={
                  classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2+1] && TaskItemData['task-body-answer-status'][(wrapperInd-2)*2+1][1]===false)})}
                   value={TaskItemData['taskBodyItemInput'][(wrapperInd-2)*2+1][1]}
                   onChange={(e)=>updateData((wrapperInd-2)*2+1,1,props,taskId,e)}/>
                     </div></div>}
            else {return <><div className="label" dangerouslySetInnerHTML={{__html:t('lang')==='en'?(rightit.replace('输出','')):rightit}}></div></>}
          })}</div>
        </div>
      </div>
    })

    return taskAppendixList
  }
  const bodyRow = TaskItemData['bodyRowNumber']
  const bodyColumn = TaskItemData['bodyColumnNumber']
  let bodyMap = []
  // console.log(TaskItemData);
  for (let rowInd = 0; rowInd < bodyRow; rowInd++) {
    let rowMap = []
    for (let colInd = 0; colInd < bodyColumn; colInd++) {
      if (TaskItemData['taskSideBodyItem'][rowInd] && TaskItemData['taskSideBodyItem'][rowInd][colInd] !== undefined) {
        rowMap.push(<div key={'side-body-item'+colInd} className="table-side-item" style={{flexGrow:TaskItemData.SideTabNum?TaskItemData.SideTabNum:'1',maxWidth:TaskItemData.SideTabNum?'unset':'440px'}}>{t(TaskItemData['taskSideBodyItem'][rowInd][colInd])}</div>)
      } else {
        const sideBarOffsetIndex = TaskItemData['taskSideBodyItem'][rowInd].length
        // console.log(colInd+1-sideBarOffsetIndex,rowInd,TaskItemData);
        rowMap.push(
        <div key={'side-body-input'+colInd} className="table-body-item">
          <div className="inputWrap">
            {/* 4-1,4-2表头有内容可不填，且4-1有空 100/149 需要7个字符，注意如果是0.000001会被强转科学计数不能用string去判断正误 */}
            <input autoComplete="off" maxLength={TaskItemData.taskId === '4_1' && rowInd === 1 && colInd === 2 ? 7: 6} type="tel" key={rowInd+'-'+colInd} name={rowInd+'-'+colInd} 
              className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][rowInd] && TaskItemData['task-body-answer-status'][rowInd][colInd-sideBarOffsetIndex]===false)})}
              value={TaskItemData['taskBodyItemInput'][rowInd][colInd-sideBarOffsetIndex]}
              disabled={(['4_1','4_2'].includes(TaskItemData.taskId) && rowInd===0)||(['5_2','5_3','5_4','5_5'].includes(TaskItemData.taskId) && rowInd===5)}
              onChange={(e)=>updateData(rowInd,(colInd-sideBarOffsetIndex),props,taskId,e)}
            />
          </div>
        </div>
        )
      }
    }
    bodyMap.push(<div key={'table-line'+rowInd} className="table-line">{rowMap}</div>)
  }
  return <div className="table-body">
    {bodyMap}
  </div>
}
// 调用父级set更新全局函数
function updateData(rowind,cindex,props,taskId,event) {
  // 如果为下述几道题，还需要更新平均值
  
  const TaskItemData = Object.assign(props.TaskItemData,{})
  console.log(JSON.stringify(TaskItemData.taskBodyItemInput));
  
  TaskItemData['taskBodyItemInput'][rowind][cindex] = event.target.value.trim()
  if (TaskItemData['task-body-answer-status']) {
    if (!TaskItemData['task-body-answer-status'][rowind]) {
      TaskItemData['task-body-answer-status'][rowind] = []
    }
    TaskItemData['task-body-answer-status'][rowind][cindex] = true
  } else {
    TaskItemData['task-body-answer-status'] = [[],[]]
    TaskItemData['task-body-answer-status'][rowind][cindex] = true
  }
  if (['5_2','5_3','5_4','5_5'].includes(TaskItemData.taskId)) {
    let columnTotal = 0
    let totalCount = 0
    TaskItemData['taskBodyItemInput'].map((li,liindex) => {
      if ((li[cindex] !=='' && li[cindex] !== undefined) && liindex!==5) {
        console.log(li[cindex], li[cindex]!=='');
        let val = 0
        try {
          if (isDigit(li[cindex])) {
            val = Number(li[cindex])
            if (val < 0) {
              val = NaN
            }
          } else {
            val = NaN
          }
        }catch(e) {
          val = NaN
        }
        columnTotal += val
        totalCount++
      }
      return null
    })
    // console.log(TaskItemData['taskBodyItemInput']);
    console.log(columnTotal);
    TaskItemData['taskBodyItemInput'][5][cindex] = totalCount===0? "": !isDigit(columnTotal)? "--": (columnTotal/totalCount).toFixed(2)
  }
  console.log(JSON.stringify(TaskItemData));
  props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
}

function TruthTable(props) {
  console.log(props);
  const t = props.t
  if (props.TaskItemData.type !== 'TruthTable') return <div style={{display:'none'}}></div>
  return <div className={classNames({showDirac:props.TaskItemData.taskId==="3_8"},"table-wrap")}>
    {buildTableTips(props.TaskItemData, t)}
    {buildTruthTableHeader(props.TaskItemData, t)}
    {buildTruthTableBody(props.TaskItemData,props, t)}
  </div>
}
// export default TruthTable
export default withNamespaces()(TruthTable);