import MultipleChoice from "./MultipleChoice";
import classNames from "classnames";

function MultiMultipleChoice(props, ref) {
  if (props.TaskItemData.type !== 'MultiMultipleChoice') return <></>
  console.log(props);
  const question = props.TaskItemData['taskDataList'].map((Taskit,ind)=>{
    return (
      // 如果只有一道小题，而没有题干左对齐，否则跟（1）后的文字对齐
      <div className={classNames({justOneChoiceAlignLeft:props.TaskItemData['taskDataList'].length===1},"MultiMultipleChoice")}>
        <MultipleChoice key={Taskit['taskId']+ind} TaskItemData={Taskit} correctAnswer={props.TaskItemData.correctAnswer[ind]} setTaskData={props.setTaskData}></MultipleChoice>
      </div>
    )
  })
  return question
}
export default MultiMultipleChoice