import { DragSource } from 'react-dnd';
// import { ItemTypes } from './ItemTypes';
import classNames from "classnames";


const style = {
  display: 'inline-block',
  cursor: 'move',
};

const Gate = ({ connectDragSource, props }) => {
  return connectDragSource(<div style={style} className={classNames('gate-' + props.gateInfo.gname, 'gate')}></div>)
}
export default DragSource((props) => { return props.gateInfo.gname }, {
  beginDrag: (props) => {
    // 获取被拖拽元素的x,y
    if (props.onBoard) {
      //console.log(props.position);
      const tempOptions = [...props.operationsState].map(op => {
        if (op.timeSlot === props.position.x && op.qubits[0] === props.position.y) {
          op.draggingSource = true
          return op
        } else return op
      })
      console.log(JSON.parse(JSON.stringify(tempOptions)));
      //console.log("setOperationStateSnapShot");
      props.setOperationStateSnapShot(tempOptions)
    } else {
      //console.log("setOperationStateSnapShot");

      props.setOperationStateSnapShot(props.operationsState)
    }
    // 从toolbar下来还是从线路的移动触发
    return ({})
  },
  endDrag:(props,monitor) => {
    // 如果在线路以外进行drop操作
    props.setIsDragging('drop')
    // 是否有办法知道当前Drop的操作落在了非法的位置，如，落在已有逻辑门上，或者落在禁用拖拽的区域，且该区域非-1，因为-1是需要删除的
    props.updateTempOperation(monitor.getItemType())
    return ({})
  },
  canDrag:(props,monitor) => {
    console.log(props);
    // 被标记禁用拖拽的逻辑门要控制
    // if ("canDrag" in props && props.canDrag === false) return false
    return ({})
  }
}, (connect, monitor, props) => {
  // //console.log(connect,monitor,props);
  return ({
    props,
    monitor,
    connectDragSource: connect.dragSource(),
  })
})(Gate);
