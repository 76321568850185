import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import { Button, Input, Space, Modal, message } from 'antd';
import { ReactComponent as Logo } from './img/account.svg';
import { CheckOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import "./userSetting.less"
import {fetcher} from '../../utils/fetcher'
import SparkMD5 from 'spark-md5';
import { set } from "lodash";

function UserSetting({t}) {
  const location = useLocation()
  const [passwordResetVisible, setPasswordResetVisible] = useState(false)
  const [nameChangeVisible, setNameChangeVisible] = useState(false)
  const navigate = useNavigate()
  // 获取用户信息并显示{email,name, institution}
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    institution: ''
  });
  const [passwordChangeValue, setPasswordChangeValue] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: ''
  })
  // 密码错误提示
  const [passwordError, setPasswordError] = useState({
    oldPasswordErrStr: '',
    passwordErrStr: '',
    confirmPasswordErrStr: ''
  });

  // 名称修改
  const [nameChangeValue, setNameChangeValue] = useState({
    name: ''
  })
  // 名称修改错误提示
  const [nameError, setNameError] = useState({
    nameErrStr: ''
  })
  const [keyForRender, setKeyForRender] = useState(0)
  useEffect(() => {
    fetcher('/user/teacher/getInfo','GET').then(res => {
      return res.json()
    }).then(re => {
      console.log(re,"get teacher info");
      setUserInfo({
        name: re.item.name,
        email: re.item.email,
        institution: re.item.institution.name
      })
    })
  },[])
  // 修改名称的输入框，支持任意中文或英文字符，不支持任意的特殊符号
  const handleNameChange = (event) => {
    if (!/^[a-zA-Z\u4e00-\u9fa5]+$/.test(event.target.value)) {
      setNameError({
        ...nameError,
        nameErrStr: `${t('userSetting.setting.nameTypeErr')}`
      })
    } else {
      setNameError({
        ...nameError,
        nameErrStr: ''
      })
    }
    setNameChangeValue({name: event.target.value})
  }

  // 修改密码的输入框
  const handlePasswordChange = (event) => {
    // 如果值跟密码不一样弹出提示
    if (event.target.name === 'confirmPassword') {
      console.log(event.target.value, passwordChangeValue.password);
      if (event.target.value !== passwordChangeValue.password) {
        setPasswordError({
          ...passwordError,
          confirmPasswordErrStr: `${t('userSetting.setting.passwordTypeErr1')}`
        })
      } else {
        setPasswordError({
          ...passwordError,
          confirmPasswordErrStr: ''
        })
      }
    }
    // 如果新密码格式有误弹出提示
    if (event.target.name === 'password') {
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/.test(event.target.value)) {
        setPasswordError({
          ...passwordError,
          passwordErrStr: `${t('userSetting.setting.passwordTypeErr2')}`
        })
      } else {
        setPasswordError({
          ...passwordError,
          passwordErrStr: ''
        })
      }
    }
    setPasswordChangeValue({
      ...passwordChangeValue,
      [event.target.name]: event.target.value
    })
  }
  // 发送修改密码请求
  const sendPasswordChangeRequest = () => {
    // 密码需要全部转md5
    let filterPassword = JSON.parse(JSON.stringify(passwordChangeValue))
    filterPassword.oldPassword = SparkMD5.hash(filterPassword.oldPassword)
    filterPassword.password = SparkMD5.hash(filterPassword.password)
    filterPassword.confirmPassword = SparkMD5.hash(filterPassword.confirmPassword)
    fetcher('/user/teacher/updatePasswordById','POST',JSON.stringify(filterPassword)).then(res => {
      return res.json()
    }).then(re => {
      console.log(re,"change password");
      if (re.status === 200) {
        message.success('修改密码成功')
        setPasswordResetVisible(false)
      } else {
        if (re.status === 2029) {
          setPasswordError({
            ...passwordError,
            oldPasswordErrStr: `${t('userSetting.setting.oldPasswordErr')}`
          })
        } else {
          message.error('修改密码失败'+re.msg)
        }
      }
    })
  }

  // 发送修改姓名请求
  const sendNameChangeRequest = () => {
    fetcher('/user/teacher/updateName','POST',JSON.stringify({name: nameChangeValue.name})).then(res => {
      return res.json()
    }).then(re => {
      console.log(re,"change name");
      if (re.status === 200) {
        message.success(re.msg)
        setNameChangeVisible(false)
        localStorage.setItem('username',nameChangeValue.name)
        window.location.reload()
      } else {
        message.error(re.msg)
      }
    })
  }

  const resetConfirmBox = () => {
    setKeyForRender(keyForRender+1)
    setPasswordError(
      {
        oldPasswordErrStr: '',
        passwordErrStr: '',
        confirmPasswordErrStr: ''
      }
    )
    
    setPasswordResetVisible(true)
  }

  const resetNameBox = () => {
    setKeyForRender(keyForRender+1)
    setNameError({
      nameErrStr: ''
    })
    setNameChangeVisible(true)
  }
  return (
    <div className="userSettingBlock">
      <div className="bigTit">{t('userSetting.setting.account')}</div>
      <div className="label">{t('userSetting.setting.email')}</div>
      <div className="inputItem">
        <p>
          {userInfo.email}
        </p>
      </div>
      <div className="label">{t('userSetting.setting.password')}</div>
      <div className="inputItem">
        <p>
          ************
        </p>
        <FormOutlined className="editIcon" onClick={()=>{resetConfirmBox()}} />
      </div>
      <div className="bigTit mt50">{t('userSetting.setting.info')}</div>
      <div className="label">{t('userSetting.setting.name')}</div>
      <div className="inputItem">
        <p>
          {userInfo.name}
        </p>
        <FormOutlined className="editIcon" onClick={()=>{resetNameBox()}} />
      </div>
      <div className="label">{t('userSetting.setting.institution')}</div>
      <div className="inputItem">
        <p>
          {userInfo.institution}
        </p>
      </div>
      <Modal 
      className="changePasswordModal" 
      title={t('userSetting.setting.resetPwd')} 
      visible={passwordResetVisible} 
      closable={false} 
      maskClosable={false} 
      closeOnAction={false}
      getContainer={()=>document.querySelector(".userSettingBlock")}
      onCancel={()=>{setPasswordResetVisible(false)}}
      onOk={sendPasswordChangeRequest}
      okText={t('userSetting.setting.confirm')}
      cancelText={t('userSetting.setting.cancel')}
      key={keyForRender}
      >
        <div className="tips">
          <ExclamationCircleOutlined className="iconSvg" />
          <p>{t('userSetting.setting.resetTips')}</p>
        </div>
        <div className="changePasswordModalBody">
          <div className="label">{t('userSetting.setting.oldPwd')}</div>
          <div className="inputItem">
            <Input.Password name="oldPassword" type="password" onChange={handlePasswordChange} placeholder={t('userSetting.setting.enterOldPwd')} />
            <p className="inputErr">{passwordError.oldPasswordErrStr}</p>
          </div>
          <div className="label">{t('userSetting.setting.newPwd')}</div>
          <div className="inputItem">
            <Input.Password name="password" type="password" onChange={handlePasswordChange} placeholder={t('userSetting.setting.enterNewPwd')} />
            <p className="inputErr">{passwordError.passwordErrStr}</p>
          </div>
          <div className="label">{t('userSetting.setting.confirmPwd')}</div>
          <div className="inputItem">
            <Input.Password name="confirmPassword" type="password" onChange={handlePasswordChange} placeholder={t('userSetting.setting.enterConfirmPwd')} />
            <p className="inputErr">{passwordError.confirmPasswordErrStr}</p>
          </div>
        </div>
      </Modal>
      <Modal
      className="changeName changePasswordModal"
      title={t('userSetting.setting.resetName')}
      visible={nameChangeVisible}
      key={keyForRender}
      closable={false} 
      maskClosable={false} 
      closeOnAction={false}
      getContainer={()=>document.querySelector(".userSettingBlock")}
      onCancel={()=>{setNameChangeVisible(false)}}
      onOk={()=>{sendNameChangeRequest()}}
      okText={t('userSetting.setting.confirm')}
      cancelText={t('userSetting.setting.cancel')}

      >
        <div className="tips">
          <ExclamationCircleOutlined className="iconSvg" />
          <p>{t('userSetting.setting.resetNameTips')}</p>
        </div>
        <div className="changePasswordModalBody">
          <div className="label">{t('userSetting.setting.newName')}</div>
          <div className="inputItem">
            <Input type="text" placeholder={t('userSetting.setting.enterNewName')} onChange={(e)=>{handleNameChange(e)}} />
            <p className="inputErr">{nameError.nameErrStr}</p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withNamespaces()(UserSetting);
