import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DecimalConvertComponent from './components/DecimalConvertComponent'
import ArithmeticComponent from './components/ArithmeticComponent'
import Multiplication from './components/Multiplication'
import SimpleTextInput from './components/SimpleTextInput'
import { Modal, Toast } from 'antd-mobile'
import "./taskDetail.less"
import CourseDetailData from '../../data/CourseDetailData.json'
import TaskDetailData from '../../data/TaskDetailData.json'
import {db} from '../../data/db'
import MultiTruthTable from "./components/MultiTruthTable";
import MultiMultipleChoice from "./components/MultiMultipleChoice";
import MultiFillBlankForm from "./components/MultiFillBlankForm";
import classNames from "classnames";
import FailedImg from "./img/TaskDetail/failed.png"
import SucceedImg from "./img/TaskDetail/success.png"
import { isEqual,differenceWith, xorWith, flattenDeep, sum, ceil } from 'lodash'
import {hasDuplicates,getUrlToken,isDigit} from "../../utils/index"
import { RightOutline } from 'antd-mobile-icons'
import CircuitBoardComponent from "./components/circuitBoard/CircuitBoardComponent";
import DesignAndComputeCircuit from "./components/DesignAndComputeCircuit";
import TaskDesignAndValidForm from "./components/TaskDesignAndValidForm";
import callEquipmentByMessage from '../../utils/bridge';
import correctImgSrc from "./img/TaskDetail/correct.png"
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { t } from "i18next";
import { create, all, Complex } from 'mathjs'
import ProNav from '../components/proNav'
import { uploadDataHandler } from '../../utils/uploadDataHandler'

const config = { }
const math = create(all, config)
console.log(math.complex("-i"));

/*
 * 查看input是否没有有意义的结果
 */
function isEmptyInputArray(arr) {
  arr = flattenDeep(arr)
  if (arr.length <= 0) {
    return true
  } else {
    let allEmpty = true
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i] !== "") {
        allEmpty = false
        break;
      } 
    }
    return allEmpty
  }
}

/*
 * 查看两个task input是否用拥有等的值，可以是新输入与旧输入，也可以是输入与标准答案
 * @param: [dfa] - 比较对象1
 * @param: [dfb] - 比较对象2
 */
function euqalTaskInput(taskType, dfa, dfb) {
  if (taskType === 'MultiFillBlankForm' || taskType === 'CircuitBoardComponent') {
    // 由于选项填状态是一个固定结构且受输入限制,可以直接比较降维的JSON
    return JSON.stringify(dfa) === JSON.stringify(dfb)
  }
  else if (taskType === 'DesignAndComputeCircuit') {
    // 填空题逐项判断，线路转json
    const BlankTableForDFA = dfa[1]
    const BlankTableForDFB = dfb[1]
    return (JSON.stringify(dfa[0]) === JSON.stringify(dfb[0])) && (xorWith(BlankTableForDFA,BlankTableForDFB).length===0)
  }
  else if (taskType === 'TaskDesignAndValidForm') {
    const BlankTableForDFA = dfa[1]
    const BlankTableForDFB = dfb[1]
    const flatMapA = flattenDeep(BlankTableForDFA)
    const flatMapB = flattenDeep(BlankTableForDFB)
    return (JSON.stringify(dfa[0]) === JSON.stringify(dfb[0])) && (xorWith(flatMapA,flatMapB).length===0)
  }
  else if (taskType === 'MultiTruthTable' || taskType === 'Arithmetic' || taskType === 'Decimal' || taskType === 'SimpleTextInput' || taskType === 'Multiplication') {
    const flatMapA = flattenDeep(dfa)
    const flatMapB = flattenDeep(dfb)
    console.log("flatMapA = " + JSON.stringify(flatMapA))
    console.log("flatMapB = " + JSON.stringify(flatMapB))
    console.log("difference with res = " + JSON.stringify(differenceWith(flatMapA,flatMapB)))
    // debugger
    return xorWith(flatMapA,flatMapB).length===0
  } else if (taskType === 'MultipleChoice') {
    return xorWith(dfa.sort(),dfb.sort()).length===0
  } else if (taskType === "MultiMultipleChoice") {
    if (dfa.length === dfb.length) {
      let allCorrect = true
      for (let i = 0; i < dfa.length; i++) {
        if (xorWith(dfa[i].sort(),dfb[i].sort()).length>0) {
          allCorrect = false
          break
        }
      }
      return allCorrect
    } else {
      return false
    }
  }
  return false
}
/*
 * 持久化现有答案，不批改
 * @param: [TaskData] - task data, include input answers
 * @param: [setVisible] - setter of showing success/failed modal or not
 * @param: [setResVersion] - setter of result version
 * @param: [setResVersion] - old result version
 */
async function saveAnswer(TaskData, setVisible, setResVersion, resVersionState, navigateCallBack, location) {
  console.log(TaskData);
  let currentUserId = localStorage.getItem('userId') || 'guest'
  const recordId = `${currentUserId}--${TaskData['taskId']}`
  // get input values of the question
  let inputDataForm = TaskData['taskBodyItemInput']
  if (TaskData.type === 'MultiTruthTable' || 
    TaskData.type === 'MultiFillBlankForm' || 
    TaskData.type === 'MultiMultipleChoice' || 
    TaskData.type === 'MultiFillBlankForm') {
    inputDataForm = TaskData['taskDataList'].map(tdlistitem => {
      return tdlistitem['taskBodyItemInput']
    })
    console.log(JSON.stringify(inputDataForm))
  }
  else if (TaskData.type === 'CircuitBoardComponent') {
    inputDataForm = [{operations:TaskData.operations}]
  }
  else if (TaskData.type === 'DesignAndComputeCircuit') {
    // [TaskData.taskDataList[0].operations,TaskData.taskBodyItemInput]
    inputDataForm = [TaskData.taskDataList[0].operations,TaskData.taskBodyItemInput]
  }
  else if (TaskData.type === 'TaskDesignAndValidForm') {
    // [TaskData.taskDataList[0].operations,TaskData.taskBodyItemInput]
    inputDataForm = [TaskData.taskDataList[0].operations,TaskData.taskDataList[1].taskBodyItemInput]
  }
  // TODO 是否有些题需要提前保存
  // 由于题目的回答内容可以删了之后恢复。这个操作应该视为跳过，

  let skipSave = false
  if (TaskData.type === 'MultiTruthTable' || 
  TaskData.type === 'Decimal' || 
  TaskData.type === 'Arithmetic'|| 
  TaskData.type === 'SimpleTextInput'|| 
  TaskData.type === 'Multiplication'|| 
  TaskData.type === 'MultiFillBlankForm' || 
  TaskData.type === 'MultiMultipleChoice' || 
  TaskData.type === 'CircuitBoardComponent' || 
  TaskData.type === 'DesignAndComputeCircuit' || 
  TaskData.type === 'TaskDesignAndValidForm'
  ) {
    const res = await readTaskInputData(TaskData, location)
    console.log(123123);
    // debugger
    if (res) {
      if (euqalTaskInput(TaskData.type, inputDataForm, res["taskInputData"])){
        skipSave = true
        console.log("visit here and set true")
      } 
    } else if (isEmptyInputArray(inputDataForm)) {
      // Optimize: 如果完全没有回答，则可以不保存
      skipSave = true
      console.log("visit here and set true")
    }
  }
  // save data to db
  const taskStatus = "saved"
  // debugger
  if (!skipSave) {
    await db['taskTable'].put({
      recordId,
      userId:currentUserId,
      taskId:TaskData['taskId'],
      courseId:TaskData['taskId'].split('_')[0],
      taskInputData:inputDataForm,
      taskStatus
    })
    setResVersion(++resVersionState)
    setVisible(false)
    navigateCallBack()
  } else {
    setVisible(false)
    navigateCallBack()
  }
}
function gotoQTHOME() {
  callEquipmentByMessage('{"type":"routeChange","path":"/home"}',()=>{})
}
async function saveAndValidAnswer(TaskData,setVisible,setResultState,setResVersion,resVersionState,getValidState,set1_3Animate,t) {
  console.log(TaskData); 
  // 对于填空和选择填空列表，会在multiTruthTable和multiFillBlankForm中，因此数据在taskDataList中，存储时应该注意将同列表的其他数据进行校验后存储
  let currentUserId = localStorage.getItem('userId') || 'guest'
  const recordId = `${currentUserId}--${TaskData['taskId']}`
  let inputDataForm = TaskData['taskBodyItemInput']
  let taskStatus = undefined
  let historyInputDataForm = []
  if (TaskData.type === 'MultiTruthTable' || TaskData.type === 'MultiFillBlankForm' || TaskData.type === 'MultiMultipleChoice') {
    inputDataForm = TaskData['taskDataList'].map(tdlistitem => {
      return tdlistitem['taskBodyItemInput']
    })
    // console.log(JSON.stringify(inputDataForm))
  }
  // 线路是异步保存需要回调执行
  else if (TaskData.type === 'CircuitBoardComponent') {
    inputDataForm = [{operations:TaskData.operations}]
    getValidState(async (flag)=>{
      taskStatus = flag ? 'succeed': 'failed'
      setResultState(flag)
      await db['taskTable']
        .put({
          recordId,
          userId:currentUserId,
          taskId:TaskData['taskId'],
          courseId:TaskData['taskId'].split('_')[0],
          taskInputData:inputDataForm,
          taskStatus,
          IsCircuitErr:!flag
        })
      // 4.批改内容
      setVisible(true)
      setResVersion(++resVersionState)
      uploadDataHandler(true, t)
    })
    return
  }
  else if (TaskData.type === 'DesignAndComputeCircuit') {
    inputDataForm = [TaskData.taskDataList[0].operations,TaskData.taskBodyItemInput]
    getValidState(async (correctFlag)=>{
      // 需要同时保证线路与填空的正确
      
      console.log(correctFlag);
      const FillInputCorrectFlag = (JSON.stringify(TaskData.taskBodyItemInput) === JSON.stringify(TaskData.correctAnswer))
      setResultState(correctFlag && FillInputCorrectFlag)
      taskStatus = (correctFlag && FillInputCorrectFlag)?"succeed":"failed"
      if (correctFlag &&'16_1'===TaskData.taskId) {
        setResultState(correctFlag)
        taskStatus = correctFlag?"succeed":"failed"
      }
      
      
      await db['taskTable']
        .put({
          recordId,
          userId:currentUserId,
          taskId:TaskData['taskId'],
          courseId:TaskData['taskId'].split('_')[0],
          taskInputData:inputDataForm,
          taskStatus,
          IsCircuitErr:!correctFlag
        })
      // 4.批改内容
      setVisible(true)
      setResVersion(++resVersionState)
      uploadDataHandler(true, t)
    })
    return
  }
  else if (TaskData.type === 'TaskDesignAndValidForm') {
    inputDataForm = [TaskData.taskDataList[0].operations,TaskData.taskDataList[1].taskBodyItemInput]
    console.log("save!!!!!!!")
    console.log(TaskData)
    // 校验线路
    getValidState(async (CircuitCorrectFlag)=>{
      console.log(CircuitCorrectFlag);
      // 对于5-3必须要插入至少一个i， 对于5-5 必须插入一个cnot，对于5-6必须插入一个x和一个cnot
      let conditionValidPass = false
      let operations = TaskData.taskDataList[0].operations
      if (TaskData.taskId === "5_2") {
        // operations.some(op => {
        //   if (op.gate.gname === "I") {conditionValidPass = true;return true}
        //   return false
        // })
        console.log(operations);
        // 如果还是最初的5个长度，那么线路就有问题
        if (operations.length === 5) {
          CircuitCorrectFlag = false
        } else {
          conditionValidPass = true
        }
        console.log(conditionValidPass);
      } else if (TaskData.taskId === "5_4") {
        let filterOperation = operations.filter(op => {if (op.gate.gname==="I"){return false} else {return true}})
        console.log(filterOperation);
        filterOperation.some(op => {
          if (op.gate.gname === "CNOT" && filterOperation.length===6) {conditionValidPass = true;return true}
          return false
        })
      } else if (TaskData.taskId === "5_5") {
        let allcheckPass = [false,false]
        let xTime = -1
        let filterOperation = operations.filter(op => {if (op.gate.gname==="I"){return false} else{ return true}})
        for (let i = 0; i < filterOperation.length; i++) {
          if (filterOperation[i].gate.gname === "X" && filterOperation[i].timeSlot >= 3  && filterOperation.length===7) {allcheckPass[0] = true}
          if (filterOperation[i].gate.gname === "CNOT"&& filterOperation[i].timeSlot >= 4  && filterOperation.length===7) {
            if (i===6 && filterOperation[5].gate.gname === "X") {allcheckPass[1] = true}
            if (i===5 && filterOperation[6].gate.gname === "X") {allcheckPass[1] = true}
          }
        }
        if (allcheckPass[0] && allcheckPass[1]) {conditionValidPass = true}
      }
      let correctFlag = CircuitCorrectFlag
      taskStatus = correctFlag?"succeed":"failed"
      console.log(taskStatus);
      if (['5_2','5_4','5_5'].includes(TaskData.taskId) && !conditionValidPass) {
        correctFlag = false
        taskStatus = "failed"
      }
      // 表单不能为空值
      if (['5_2','5_3','5_4','5_5'].includes(TaskData.taskId)){
        let taskBodyItemInput = TaskData.taskDataList[1].taskBodyItemInput
        for (let i = 0; i < taskBodyItemInput.length; i++) {
          for (let j = 0; j < taskBodyItemInput[i].length; j++) {
            if (taskBodyItemInput[i][j] === "" || !isDigit(taskBodyItemInput[i][j])) {
              correctFlag = false
              taskStatus = "failed"
            }
          }
        }
      }
      setResultState(correctFlag)
      await db['taskTable']
          .put({
            recordId,
            userId:currentUserId,
            taskId:TaskData['taskId'],
            courseId:TaskData['taskId'].split('_')[0],
            taskInputData:inputDataForm,
            taskStatus,
            IsCircuitErr:!CircuitCorrectFlag
          })
      // 4.批改内容
      setVisible(true)
      setResVersion(++resVersionState)
      uploadDataHandler(true, t)
    })
    return
  }
  const correctAnswer = TaskData['correctAnswer']
  console.log(inputDataForm,correctAnswer);
  console.log(isEqual(flattenDeep(inputDataForm),flattenDeep(correctAnswer)));

  if (TaskData.type === 'MultipleChoice') {
    if (!isEqual(inputDataForm.sort(),correctAnswer.sort())) {
      setResultState(false)
      taskStatus = "failed"
    } else {
      setResultState(true) 
      taskStatus = "succeed"
    }
  }
  else if (TaskData.type === "MultiMultipleChoice") {
    let allCorrect = true
    if (inputDataForm.length === correctAnswer.length) {
      for (let i = 0; i < inputDataForm.length; i++) {
        if (!isEqual(inputDataForm[i].sort(),correctAnswer[i].sort())) {
          allCorrect = false
          break
        }
      }
    } else {
      allCorrect = false
    }
    if (allCorrect) {
      setResultState(true) 
      taskStatus = "succeed"
    } else {
      console.log(2);

      setResultState(false)
      taskStatus = "failed"
    }
  }
  // flattenDeep可以做递归降维
  else if (TaskData.type === 'MultiTruthTable' || TaskData.type === 'Arithmetic' || TaskData.type === 'Decimal' || TaskData.type === 'SimpleTextInput' || TaskData.type === 'Multiplication') {
    // 对于16_2而言，需要判断是否能够相乘与答案同样两项相乘结果相等
    if (TaskData.taskId==='16_2') {
      let list1 = [inputDataForm[0],inputDataForm[1]].sort((a,b)=>{return a-b})
      let list2 = [inputDataForm[2],inputDataForm[3]].sort((a,b)=>{return a-b})
      let list3 = [inputDataForm[4],inputDataForm[5]].sort((a,b)=>{return a-b})
      historyInputDataForm = inputDataForm.concat([])
      inputDataForm = list1.concat(list2).concat(list3)
    }
    // 处理多表检验，要考虑特殊情况
    if (!isEqual(flattenDeep(inputDataForm),flattenDeep(correctAnswer))) {
      setResultState(false)
      taskStatus = "failed"
    } else {
      setResultState(true) 
      taskStatus = "succeed"
      // 如果是题号1-3需要先显示公式弹窗，确认后再弹出进入下一题弹窗
      if (TaskData.taskId === '1_3') {
        const res = await db['taskTable']
                  .put({
                    recordId,
                    userId:currentUserId,
                    taskId:TaskData['taskId'],
                    courseId:TaskData['taskId'].split('_')[0],
                    taskInputData:inputDataForm,
                    taskStatus
                  })
        document.querySelector(".task-detail").scrollTo(0,0)
        set1_3Animate(true)
        setTimeout(async () => {
          await Modal.alert({
            header: (
              <div className="task1_3Header">{t('exercise.alertTips')}</div>
            ),
            title: t('exercise.alertTipsDesc'),
            content: (
              <>
                <div>﹁（x∧y）=（﹁x）∨（﹁y）</div>
                <div>﹁（x∨y）=（﹁x）∧（﹁y）</div>
              </>
            ),
            // footer: (
            //   <div>{t('exercise.continue')}</div>
            // ),
            className:"tipsAlert",
            confirmText:t('exercise.continue')
          })
          set1_3Animate(false)
          setVisible(true)
          setResVersion(++resVersionState)
          uploadDataHandler(true, t)
        }, 3000);
        return res
      }
    }
  }
  // 选择填空如果遇到带矩阵格式的校验，我的想法是直接整个html进行校验
  else if (TaskData.type === 'MultiFillBlankForm') {
    console.log(JSON.stringify(inputDataForm));
    console.log('_______________________________________')
    console.log(JSON.stringify(correctAnswer));
    // 对于有唯一确定答案的情况，如 8_1,9_1,10_1可以直接拿JSON作比较
    if (['8_1','9_1','10_1'].includes(TaskData.taskId)) {
      if (JSON.stringify(inputDataForm) === JSON.stringify(correctAnswer)) {
        setResultState(true) 
        taskStatus = "succeed"
      } else {
        setResultState(false)
        taskStatus = "failed"
      }
    } else {
      // 非唯一答案，要根据阶段判断是否处于所属阶段，细分为5_1,6_1（不规则的）和6_3 -> 6_6 （统一规则的）
      if (['5_1','6_1'].includes(TaskData.taskId)) {
        console.log(inputDataForm,correctAnswer);
        // 对于不规则填空，只需要存在不重复即可
        let allCorrect = true
        if (TaskData.taskId === '5_1') {
          inputDataForm[0].map((lis,lisind)=>{
            const filterCorrectAnswer = correctAnswer[0][lisind].valList.map(cit => {return JSON.stringify(cit)})
            const filterUserAnswer = lis.valList.map(lit => {return JSON.stringify(lit)})
            if (hasDuplicates(filterUserAnswer)) { allCorrect = false }
            filterUserAnswer.map(it => {
              // 重复项或者不存在项即为错误
              if (!filterCorrectAnswer.includes(it)) { allCorrect = false }
              return true
            })
            return true
          })
        } else {
          // 6_1, 4组表
          for (let i = 0; i < 4; i ++) {
            inputDataForm[i].map((lis,lisind)=>{
              const filterCorrectAnswer = correctAnswer[i][lisind].valList.map(cit => {return JSON.stringify(cit)})
              const filterUserAnswer = lis.valList.map(lit => {return JSON.stringify(lit)})
              if (hasDuplicates(filterUserAnswer)) { allCorrect = false }
              filterUserAnswer.map(it => {
                // 重复项或者不存在项即为错误
                if (!filterCorrectAnswer.includes(it)) { allCorrect = false }
                return true
              })
              return true
            })
          }
        }
        setResultState(allCorrect)
        taskStatus = allCorrect ? "succeed": "failed"
      }
      else if (['6_3','6_4','6_5','6_6'].includes(TaskData.taskId)) {
        // 带统一规则的非唯一答案填空，获取对应分组，只要答案在分组内且只出现一次即正确
        console.log(TaskData);
        const marginItemIndexs = TaskData.taskDataList[0].marginItemIndexs // 获取分组信息
        const groupInd = -1
        let allCorrect = true
        console.log(inputDataForm);
        inputDataForm[0].map((lis,lisind)=>{
          const filterCorrectAnswer = correctAnswer[0][lisind].valList.map(cit => {
            return JSON.stringify(cit)
          })
          const filterUserAnswer = lis.valList.map(lit => {
            return JSON.stringify(lit)
          })
          console.log(filterCorrectAnswer,filterUserAnswer);
          filterUserAnswer.map((it,fitind)=>{
            // 在相同组中找是否有一致答案，没有则报错
            let currentInd = -1
            marginItemIndexs.some((marginItemList,marginInd)=>{
              if (fitind <= marginItemList[1] && fitind >= marginItemList[0]) {
                currentInd = marginInd
                return true
              }
              console.log(marginInd);
              return false
            })
            let groupList = []
            // 检查是否重复
            for (let j = marginItemIndexs[currentInd][0]; j <= marginItemIndexs[currentInd][1]; j++) {
              groupList.push(filterUserAnswer[j])
            }
            if (hasDuplicates(groupList)) { allCorrect = false }
            for (let j = marginItemIndexs[currentInd][0]; j <= marginItemIndexs[currentInd][1]; j++) {
              console.log(filterCorrectAnswer[j]);
              if (filterCorrectAnswer[j]===it) break // 只要有一项匹配就行
              if (j===marginItemIndexs[currentInd][1] && filterCorrectAnswer[j]!==it) {allCorrect = false}
            }
            return true
          })
          return true
        })
        setResultState(allCorrect)
        taskStatus = allCorrect ? "succeed": "failed"
      }
    }
  }
  const res = await db['taskTable']
                .put({
                  recordId,
                  userId:currentUserId,
                  taskId:TaskData['taskId'],
                  courseId:TaskData['taskId'].split('_')[0],
                  taskInputData: TaskData['taskId']==='16_2'?historyInputDataForm: inputDataForm,
                  taskStatus
                })

  // 4.批改内容
  setVisible(true)
  setResVersion(++resVersionState)
  uploadDataHandler(true, t)
  // console.log(res);
  return res
}

// 读取当前练习的答题记录
async function readTaskInputData(TaskData, location) {
  // 如果是教师端访问学生小组的这个页面，需要手动返回记录，记录来源于教师的getRecoredsByTeamId而非这里的本地数据库
  // 获取页面路由
  if (location.pathname === '/eduManager/teamDetail') {
    // 从教师端获取学生小组的答题记录
    if (window.recordData) {
      return window.recordData
    }
  }


  const currentUserId = localStorage.getItem('userId') || 'guest'
  const recordId = `${currentUserId}--${TaskData['taskId']}`
  console.log(recordId);
  console.log("read from record id database");
  const res = await db['taskTable'].get(recordId)
  console.log(res);
  return res
}

// 读取当前课程的所有练习及对应答题情况
async function readCourseList(CourseData,setFinishLessonState) {
  // console.log(CourseData);
  const currentUserId = localStorage.getItem('userId') || 'guest'
  const courseId = CourseData['courseId']
  const list = await db['taskTable'].where({userId:currentUserId,courseId:courseId.toString()}).toArray();
  let finishedLesson = 0
  const tempTaskFilterStatusList = CourseData.taskDetailList.map((tit,tind)=>{
    list.map(it=> {
      if (tit['taskId']===it['taskId']) {
        tit.taskStatus=it.taskStatus
        if (it.taskStatus === 'succeed') {finishedLesson++}
      }
      return true
    })
    return tit
  })
  if (finishedLesson === CourseData.taskDetailList.length) {setFinishLessonState(true)}
  else {setFinishLessonState(false)}
  return tempTaskFilterStatusList
}

// 查看课程时跳转到对应ppt，注意多个习题由于重复可能链接到相同的地址
function linkToPPt(courseId, taskId, navigate) {
  let pptSourceList = CourseDetailData.courseNameList[Number(courseId)-1].pptSourceList
  pptSourceList.map((pptItem,pptIndex) => {
    if (pptItem.relativetask) {
      console.log(pptItem.relativetask.tasks,taskId);
    }
    if (pptItem.relativetask && pptItem.relativetask.tasks.includes(taskId)) {
      navigate('/course/detail/'+courseId+'?page='+(pptIndex+1))
    }
    return true
  })
}


function renderImageList(imageList,isLeft,imageTips) {
  console.log(imageList);
  let tempList = []
  if (imageList && imageList.length>0) {
    tempList = imageList.map((imgit,imgind)=>{
      // return <div className="image-item" style={{backgroundImage:'url("'+imgit+'")'}}></div>
      return <div className="image-wrap" key={imgind+'img'}>
        <img className={classNames({'left':isLeft},"image-item")} src={imgit} alt={imgit} /><p className="imageTips" dangerouslySetInnerHTML={{__html:(imageTips && t(imageTips[imgind]))}}></p>
      </div>
    })
  }
  // console.log(tempList);
  return tempList
}


function TaskDetail(params) {
  console.log("taskdetail");
  callEquipmentByMessage('{"type":"tabChange","style":"default"}')
  let navigate = useNavigate();
  let location = useLocation();
  const t = params.t
  const {courseId2taskId} = useParams()
  let courseId = courseId2taskId && courseId2taskId.split('-')[0] // 课程id，用于匹配后生成左侧列表
  let taskId = courseId2taskId && courseId2taskId.split('-')[1] // 作业id，获取作业详情
  if (params.courseId) {courseId = params.courseId}
  if (params.taskId) {taskId = params.taskId}
  console.log("taskdetail",courseId,taskId);
  let AssignData = {task:undefined}
  let TaskData = {}
  const [visible, setVisible] = useState(false)
  // const [courseIdState,setCourseId] = useState(courseId)
  const [taskIdState,setTaskId] = useState(taskId)
  const [AssignDataState,setAssignData] = useState(AssignData)
  const [TaskDataState, originSetTaskData] = useState(TaskData)
  const [resultState, setResultState] = useState(false)
  const [resVersionState, setResVersion] = useState(1)
  const [taskStatusListState, setTaskStatusListState] = useState([])
  const [finishLessonState, setFinishLessonState] = useState(false)
  const [animateStateFor1_3, set1_3Animate] = useState(false)
  const [buttonActive,setButtonActive] = useState(false)
  const childRef = useRef();
  function setTaskData(state) {
    // 修改题目时，更新左侧栏的对应题目状态为saved
    let updateFlag = false
    const newStatusList = [...taskStatusListState].map(it => {
      if (it.taskId === taskId) {
        if (it.taskStatus !=="saved") {updateFlag = true}
        return {...it,taskStatus:"saved"}
      }
      return it
    })
    if (updateFlag) {setTaskStatusListState(newStatusList)}
    // 重置taskState
    console.log("originset");
    originSetTaskData(state)
  }
  const getValidState = (callback) => {
    childRef.current.validate((isError)=>{
      if (isError) {
        // 该线路异常
        callback(false)
        console.log(childRef);
        // alert("error in detail")  
      } else {
        callback(true)
        // alert("succeess in detail")  
      }
    });
  }
  function updateTaskData(data) {
    console.log("update");
    if (data['tabIndex'] !== undefined) {
      TaskDataState['taskDataList'][data['tabIndex']] = data
      setTaskData(JSON.parse(JSON.stringify(TaskDataState)))
    } else {
      setTaskData(data)
    }
  }
  function backTo() {
    const hash = window.location.hash
    if (hash.includes('?')) {
      const val = getUrlToken('from',hash)
      console.log(val);
      if (val === 'record' && (window.Android||window.qt)) {
        gotoQTHOME()
      } else {
        navigate(-1)
      }
    } else {
      console.log("no record");
      navigate(-1)
    }
  }
  if (taskId !== taskIdState) {
    console.log("setTaskId");
    setTaskId(taskId)
  }
  
  // 如果带有从record页面来的参数，需要返回时回到record页面，因此组件内切换习题的跳转需要保留from=record的参数
  function navigateWithparams(path) {
    const hash = window.location.hash
    console.log(hash);
    if (hash.includes('?')) {
      const val = getUrlToken('from',hash)
      console.log(val);
      if (val === 'record' && (window.Android||window.qt)) {
        navigate(`${path}?from=${val}`,{replace:true})
      } else {
        navigate(path,{replace:true})
      }
    } else {
      navigate(path,{replace:true})
    }
  }
  useEffect(() => {
    console.log("effect");
    let isUnmount = false;
    // console.log('effect')
    setButtonActive(false)
    setTimeout(() => {
      setButtonActive(true)
    }, 20);
    CourseDetailData.courseNameList.some(it => {
      console.log("course detail data map", it.id.toString(), courseId);
      if (it.id.toString() !== `${courseId}`) return false
      TaskDetailData.taskList.map( async (taskit) => {

        console.log("task detail data map");
        if (Number(courseId) === taskit["courseId"]) {
          it.task = taskit
          it.task = await readCourseList(taskit,setFinishLessonState)
          // console.log(it.task);
          // Q:异步导致数据渲染有差异
          if (it.id.toString() === courseId.toString() && !isUnmount) {
            console.log("setTaskStatusListState");
            setTaskStatusListState(it.task)
          }
          return true
        } else return false
      })
      if (it.id && courseId && it.id.toString() === courseId.toString()) {
        // AssignData = it
        if (it.task && it.task.taskDetailList) {
          it.task.taskDetailList.map(async (taski,ind) => {
            if (taski['taskId'].toString()===taskId) {
              // Restore inputed data to task view
              const res = await readTaskInputData(taski,location)
              console.log(res,taski.type);
              if (res) {
                if (taski.type === 'MultiTruthTable' || taski.type === 'MultiFillBlankForm' || taski.type === 'MultiMultipleChoice') {
                  taski['taskDataList'].map((tit,tind) => {
                    tit['taskBodyItemInput'] = res.taskInputData && res.taskInputData[tind]
                    console.log(tit['taskBodyItemInput'],res.taskStatus,TaskDetailData.taskStatus.saved,res.taskInputData);
                    if (taski.type === 'MultiMultipleChoice' && tit['taskBodyItemInput']) {
                      if (res.taskStatus===TaskDetailData.taskStatus.saved) {
                        // debugger
                        const answerStates = tit.choices.map((choice, cidx) => {
                          return true
                        })
                        tit['task-body-answer-status'] = answerStates
                      } else {
                        const correctAnswerList = taski.correctAnswer[tind]
                        const answerStates = tit.choices.map((choice, cidx) => {
                          return !(tit['taskBodyItemInput'].indexOf(choice) !==-1 && correctAnswerList.indexOf(choice)===-1)
                        })
                        console.log(JSON.stringify(answerStates))
                        tit['task-body-answer-status'] = answerStates
                      }
                    }
                    if (taski.type === 'MultiTruthTable') {
                      const list = res.taskInputData
                      list.map((wrapItem,wrapInd)=>{
                        const newWrapItem = wrapItem.map((resline,resind) => {
                          const newLine = resline.map((resit,itind)=>{
                            if (res.taskStatus===TaskDetailData.taskStatus.saved) {
                              return true
                            } else {
                              return (taski['correctAnswer'] && taski['correctAnswer'][wrapInd] && taski['correctAnswer'][wrapInd][resind] && taski['correctAnswer'][wrapInd][resind][itind] === resit)
                            }
                          })
                          return newLine
                        })
                        taski['taskDataList'][wrapInd]['task-body-answer-status'] = newWrapItem
                        return newWrapItem
                      })
                    } else if (taski.type === 'MultiFillBlankForm') {
                      const list = res.taskInputData
                      console.log(list);
                      list.map((wrapItem,wrapInd)=>{
                        const newWrapItem = wrapItem.map((resline,resind) => {
                          const newLine = resline.valList.map((resit,itind) => {
                            if(
                              taski['correctAnswer'] && 
                              taski['correctAnswer'][wrapInd] && 
                              taski['correctAnswer'][wrapInd][resind] && 
                              taski['correctAnswer'][wrapInd][resind].valList &&
                              taski['correctAnswer'][wrapInd][resind].valList[itind]
                            ) {
                              if (res.taskStatus===TaskDetailData.taskStatus.saved) { return {symbFlag:true,valFlag:true} }
                              const val = taski['correctAnswer'][wrapInd][resind].valList[itind].val
                              // 由于5_1,6_1,6_3 -> 6_6 答案不唯一，所以需要做的是判断是否存在且是否不重复，而8,9,10则唯一，这一步用于标记错误内容
                              // return {symbFlag:symb===resit.symb,valFlag:val ===resit.val}
                              if (['8_1','9_1','10_1'].includes(taskId)) {
                                return {valFlag:val ===resit.val}
                              }
                              else if (['5_1','6_1'].includes(taskId)) {
                                // 不规则无阶段且不唯一,先判断是否存在，存在应该以val做标准，再判断是否重复
                                let existFlag = false
                                let existInd = -1
                                taski['correctAnswer'][wrapInd][resind].valList.some((corrvalit,corrvalitind) => {
                                  if (corrvalit.val === resit.val) {existFlag = true;existInd = corrvalitind; return true}
                                  return false
                                })
                                // 如果存在
                                if (existFlag) {
                                  // 判断是否重复出现
                                  console.log(resline.valList);
                                  let duplicateInd = -1
                                  const hasDuplicates = resline.valList.filter((vit,vind)=>{if (vit.val === resit.val && vind > 0 && duplicateInd === -1 ) {duplicateInd = vind}; return vit.val===resit.val})
                                  console.log(hasDuplicates.length,existInd,itind,duplicateInd);
                                  if (hasDuplicates.length>1 && itind >= duplicateInd) {
                                    return {valFlag: false}
                                  } else {
                                    return {valFlag: true}
                                  }
                                } else {
                                  // 如果值错了，那么我们认为他都错了
                                  return {valFlag:false}
                                }
                              } else if (['6_3','6_4','6_5','6_6']) {
                                // 存在分组规则，先判断存在于对应分组，再判断重复，最后判断符号
                                // 1.找到分组
                                const marginItemIndexs = taski['taskDataList'][wrapInd]['marginItemIndexs']
                                let currentMarginInd = -1
                                marginItemIndexs.some((marginit,cuindex) => {
                                  if (itind>=marginit[0] && itind <= marginit[1]) {
                                    currentMarginInd = cuindex
                                  }
                                  return false
                                })
                                // 2.判断是否在该分组存在
                                let existFlag = false
                                let existInd = -1
                                let groupListForCheckDuplicate = []
                                for (let i = marginItemIndexs[currentMarginInd][0]; i <= marginItemIndexs[currentMarginInd][1]; i++) {
                                  groupListForCheckDuplicate.push(resline.valList[i].val)
                                  console.log(groupListForCheckDuplicate);
                                  if (resit.val === taski['correctAnswer'][wrapInd][resind].valList[i].val){
                                    existFlag = true
                                    existInd = i
                                  }
                                }
                                // 不存在于该分组
                                if (!existFlag) { console.warn("didnot exist");   return {valFlag:false}}
                                // 3.判断是否重复
                                else {
                                  console.log(resit.val);
                                  // console.log(groupListForCheckDuplicate);
                                  console.log(marginItemIndexs[currentMarginInd][0],marginItemIndexs[currentMarginInd][1]);
                                  let duplicateInd = -1
                                  const hasDuplicates = resline.valList.filter((vit,vind)=>{
                                    if (vit.val === resit.val && vind >= marginItemIndexs[currentMarginInd][0] && vind <= marginItemIndexs[currentMarginInd][1] ) {
                                      if (duplicateInd === -1) {
                                        duplicateInd = vind;
                                      }
                                      return vit.val===resit.val
                                    }
                                    return false
                                  })
                                  console.log(hasDuplicates.length,existInd,itind,duplicateInd);
                                  if (hasDuplicates.length>1 && itind > duplicateInd) {
                                    return {valFlag:false}
                                  }
                                  return {valFlag:true}
                                }
                              }
                              return false
                            } else {return {}}
                          })
                          return ({valList:newLine})
                        })
                        taski['taskDataList'][wrapInd]['task-body-answer-status'] = newWrapItem
                        return newWrapItem
                      })
                      console.log(taski['taskDataList']);
                    }
                    return true
                  })
                }
                else if (taski.type === 'CircuitBoardComponent') {
                  taski.operations = res.taskInputData[0].operations
                  taski['IsCircuitErr'] = res.IsCircuitErr
                }
                else if (taski.type === 'MultipleChoice') {
                  taski['taskBodyItemInput'] = res.taskInputData
                  const correctAnswerList = taski.correctAnswer
                  const answerStates = taski.choices.map((choice, cidx) => {
                    return taski['taskBodyItemInput'].indexOf(choice) !==-1 && correctAnswerList.indexOf(choice)===-1
                  })
                  console.log(JSON.stringify(answerStates))
                  taski['task-body-answer-status'] = answerStates
                  return answerStates
                }
                else if (taski.type === 'DesignAndComputeCircuit') {
                  if (res.taskStatus===TaskDetailData.taskStatus.saved) {
                    taski.taskDataList[0]['IsCircuitErr'] = false
                    taski['task-body-answer-status'] = res.taskInputData[1].map((it)=>{return true})
                  } else {
                    // 16_1仅要求不为空
                    if (taski['taskId']==='16_1') {
                      if (res.taskInputData[1][0].trim()==="") {
                        taski['task-body-answer-status'] = [false]
                      } else {
                        taski['task-body-answer-status'] = [true]
                      }
                    } else {
                      taski['task-body-answer-status'] = res.taskInputData[1].map((resit,resind) => {
                        return (taski['correctAnswer'][resind] === resit)
                      })
                    }
                    taski.taskDataList[0]['IsCircuitErr'] = res.IsCircuitErr
                  }
                  taski.taskDataList[0]['operations'] = res.taskInputData[0]
                  taski['taskBodyItemInput'] = res.taskInputData[1]
                  console.log(taski);
                }
                else if (taski.type === 'TaskDesignAndValidForm') {
                  console.log(res,TaskDetailData.taskStatus);
                  if (res.taskStatus===TaskDetailData.taskStatus.saved) {
                    taski.taskDataList[0]['IsCircuitErr'] = false
                    taski.taskDataList[1]['task-body-answer-status'] = res.taskInputData[1].map((it)=>{return new Array(res.taskInputData[1].length).fill(true)})
                  } else {
                    // 校验并获取，不能为空
                    taski.taskDataList[1]['task-body-answer-status'] = (res.taskInputData[1]).map((list,listind)=>{
                      if (listind === res.taskInputData[1].length-1) return new Array(res.taskInputData[1].length).fill(true)
                      return list.map(it => {
                        let val = 0
                        try {
                          if (isDigit(it)) {
                            val = Number(it)
                            if (val < 0) {
                              val = NaN
                            }
                          } else {
                            val = NaN
                          }
                        } catch (error) {
                          val = NaN
                        }
                        if(it===""||!isDigit(val)){
                          return false
                        } else {return true}
                      })
                      // return new Array(res.taskInputData[1].length).fill(true)
                    })
                    console.log(taski.taskDataList[1]['task-body-answer-status']);
                    taski.taskDataList[0]['IsCircuitErr'] = res.IsCircuitErr
                  }
                  taski.taskDataList[0]['operations'] = res.taskInputData[0]
                  taski.taskDataList[1]['taskBodyItemInput'] = res.taskInputData[1]
                }
                else {
                  console.log(res.taskInputData);
                  taski['taskBodyItemInput'] = res.taskInputData
                  // 标记错误答案该配置可以在修改答案时被重新修改结构应该与taskBodyItemInput一致
                  if (res.taskStatus===TaskDetailData.taskStatus.saved) {
                    taski['task-body-answer-status'] = res.taskInputData.map((resit,resind) => {
                      return true
                    })
                  } else {
                    // 16_2由于可以交换，判断正误的方式需要调整不能简单粗暴的判断相等，我的想法是
                    // 1.第二项不跟第一项重复，否则第二项为错
                    // 2.两项需要都处在正确答案中
                    if (taski['taskId']==='16_2') {
                      taski['task-body-answer-status'] = new Array(res.taskInputData.length)
                      for (let i = 0; i < res.taskInputData.length; i++) {
                        if (i%2===1) {
                          if (res.taskInputData[i]===res.taskInputData[i-1]) {
                            taski['task-body-answer-status'][i] = false
                            continue
                          }
                        }
                        // 创建一个
                        let correctRangeList = i%2===0? [taski['correctAnswer'][i],taski['correctAnswer'][i+1]]:[taski['correctAnswer'][i-1],taski['correctAnswer'][i]]
                        console.log(correctRangeList,res.taskInputData[i]);
                        if (!correctRangeList.includes(res.taskInputData[i])) {
                          taski['task-body-answer-status'][i] = false
                          continue
                        }
                        taski['task-body-answer-status'][i] = true
                      }
                      console.log(taski['task-body-answer-status']);
                    } 
                    else {
                      taski['task-body-answer-status'] = res.taskInputData.map((resit,resind) => {
                        return (taski['correctAnswer'][resind] === resit)
                      })
                    }
                  }
                }
              }
              if (!isUnmount) {
                setAssignData(it)
                console.log(AssignDataState);
                setTaskData(taski)
              }
              return true
            }
          })
        }
        return true
      }
      return false
    })
    // 左侧课程状态栏更新
    return () => isUnmount = true; // 重置mounted，该状态避免卸载组件仍然更新
  },[taskIdState,courseId,taskId,resVersionState]) // 需要跟踪的有课程id,作业id,当前提交答案的版本(每次提交答案都需要重新比较并标记错误答案)

  useEffect(() => {
    document.querySelector(".task-detail").scrollTo(0,0)
    return () => {
    }
  }, [taskId])
  
  return (
    <>
      {/* <ProNav t={t}></ProNav> */}
      <div className="nav-tit task-detail-nav" id="nav-tit">
        <div className="returnButton-wrapper">
          <div className="returnButton iconfont icon-icon-left-arr" onClick={()=>{saveAnswer(TaskDataState,setVisible,setResVersion,resVersionState,backTo, location);}}><span style={{marginLeft:"10px"}}>{t('global.back')}</span></div>
          <div className="title">{t('CourseList.courseInd',{ind:courseId})} {t(AssignDataState.name)}</div>
          <div className="course-helper">
            <div className="helper-content" onClick={()=>{saveAnswer(TaskDataState,setVisible,setResVersion,resVersionState,()=>{linkToPPt(courseId,taskId,navigate)}, location)
              }}>{t('exercise.tips')}</div>
          </div>
        </div>
      </div>
      <div className="task padding-wrap">
        <div className="task-content">
          <div className="task-list">
          {
            // 单独监听左侧列表状态
            taskStatusListState && taskStatusListState.map((it,index) => {
              return <button className={classNames({"active":taskId===it.taskId},"list-item")} key={'item'+index}>
                {taskId===it.taskId && <RightOutline className="rightArr" />}
                <div className="item-content" onClick={(e)=>{
                    saveAnswer(TaskDataState,setVisible,setResVersion,resVersionState,()=>{navigateWithparams('/task/detail/' + courseId + '-' + it['taskId'])}, location)
                  }}>
                  <div className={
                      classNames({"succeed":(taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='succeed'),
                      "failed":(taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='failed')},
                      "big-tit")}
                  >
                    {t('lang')==='cn'?it['taskName']:it['taskName'].replace('练习','Exercise').replace('选做题','Optional').replace('选题','Optional')}
                  </div>
                  <div className={classNames({"complete":(taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='succeed'),"failed":(taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='failed')},"status")}>
                    {
                      taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='succeed'? (t('lang')==='cn'?t('record.completed'):'') : ''
                    }
                    {
                      taskStatusListState[index] && taskStatusListState[index]['taskStatus']==='failed'? '●': 
                        taskStatusListState[index]['taskStatus']==='succeed'? <img src={correctImgSrc}/>: ''
                    }
                  </div>
                  <div className="sub-tit" dangerouslySetInnerHTML={{__html:t(it['typeStr'])+(it.title?'：'+it.title : '')}}></div>
                </div>
              </button>
            })
          }
          </div>
          <div className="task-detail">
            <div className={classNames({animateStateFor1_3},"answer-sheet","answer-sheet-"+taskId)}>
              {
                TaskDataState['taskName'] && <h1 dangerouslySetInnerHTML={{__html:(t('lang')==='cn'?TaskDataState['taskName']:TaskDataState['taskName'].replace('练习','Exercise').replace('选做题','Optional').replace('选题','Optional'))+': ' +t(TaskDataState['typeStr'])}}></h1>
              }
              <div className="sub-tit" dangerouslySetInnerHTML={{__html:t(TaskDataState['instructions'])}}></div>
              
              {TaskDataState.circuitIns && <div className="circuitIns" dangerouslySetInnerHTML={{__html:t(TaskDataState.circuitIns)}}></div>}
              <div className="imageList">
                {
                  TaskDataState.taskId===taskId &&
                  renderImageList(t('lang')==='cn'?TaskDataState['imageList']:((TaskDataState['imageListEN']&&TaskDataState['imageListEN'].length>0)?TaskDataState['imageListEN']:TaskDataState['imageList']),TaskDataState['imagePositionLeft'],TaskDataState['imageTips'])
                }
              </div>
              {
                TaskDataState  && (TaskDataState.taskId===taskId) && (TaskDataState.type==="DesignAndComputeCircuit") && <DesignAndComputeCircuit t={t} ref={childRef} TaskItemData={TaskDataState} setTaskData={setTaskData} />
              }
              {
                TaskDataState  && (TaskDataState.taskId===taskId) && (TaskDataState.type==="TaskDesignAndValidForm") && <TaskDesignAndValidForm ref={childRef} TaskItemData={TaskDataState} setTaskData={setTaskData} />
              }
              <ArithmeticComponent t={t} key="ArithmeticComponent" TaskItemData={TaskDataState} setTaskData={setTaskData} AssignData={AssignDataState} />
              <Multiplication t={t} key="Multiplication" TaskItemData={TaskDataState} setTaskData={setTaskData} AssignData={AssignDataState} />
              <SimpleTextInput t={t} key="SimpleTextInput" TaskItemData={TaskDataState} setTaskData={setTaskData} AssignData={AssignDataState} />
              {
                TaskDataState && TaskDataState.circuitBoardInfo && (TaskDataState.taskId===taskId) && <CircuitBoardComponent ref={childRef} key={"CircuitBoardComponent"} TaskItemData={TaskDataState} setTaskData={setTaskData} />
              }
              <DecimalConvertComponent t={t} key="DecimalConvertComponent" TaskItemData={TaskDataState} setTaskData={setTaskData} AssignData={AssignDataState} />
              <MultiTruthTable t={t} key="MultiTruthTable" TaskItemData={TaskDataState} setTaskData={updateTaskData} AssignData={AssignDataState} />
              <MultiFillBlankForm t={t} key="MultiFillBlankForm" TaskItemData={TaskDataState} setTaskData={updateTaskData} AssignData={AssignDataState}/>
              <MultiMultipleChoice t={t} key="MultiMultipleChoice" TaskItemData={TaskDataState} setTaskData={updateTaskData} AssignData={AssignDataState}/>
              {TaskDataState.taskId===taskId && <div className={classNames({active:buttonActive},"submit-line")}>
                <button className="submit" onClick={()=>{saveAndValidAnswer(TaskDataState,setVisible,setResultState,setResVersion,resVersionState,getValidState,set1_3Animate,t)}}>{t('exercise.Submit')}</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="task-detail-modal"
        visible={visible}
        content={ resultState?
          (<div className="img-container"><img className="success" src={SucceedImg} alt={SucceedImg} /><span>{t('exercise.perfectAns')}</span></div>) :
          (<div className="img-container"><img src={FailedImg} alt={FailedImg} /><p>{t('exercise.FailedTips')}</p></div>)
        }
        showCloseButton
        closeOnMaskClick
        closeOnAction
        onAction={(action,ind)=> {
          console.log(action,ind);
          if (action.key==='confirm') {
            // 下一题要找到最接近的题号，可能会比自己小，我的想法是把自己以前的题全部裁剪下来插入到后置位，依次遍历
            let getIndex = 0
            taskStatusListState.map((it,ind)=>{
              if (it.taskId===taskId) {getIndex = ind}
              return true
            })
            let copyTaskStatusListState = JSON.parse(JSON.stringify(taskStatusListState))
            if (getIndex>0) {
              const newCpsplice = copyTaskStatusListState.splice(0,getIndex)
              copyTaskStatusListState = copyTaskStatusListState.concat(copyTaskStatusListState,newCpsplice)
            }
            copyTaskStatusListState.some(cit=>{
              if (cit.taskStatus!=='succeed') {
                navigateWithparams('/task/detail/'+(courseId+'-'+cit.taskId))
                return true
              }
              return false
            })
          }
          if (action.key === 'cancel'|| action.key === 'back') {
            navigateWithparams('/task/questions/'+courseId,{replace:true})
          }
        }}
        onClose={() => {
          setVisible(false)
        }}
        actions={resultState?
          (
            finishLessonState?
            
            [
              {
                key: 'back',
                text: t('exercise.backtoContent'),
                className: 'back'
              }
            ]
            :
            [
              {
                key: 'cancel',
                text: t('exercise.quit'),
                className: 'cancel'
              },
              {
                key: 'confirm',
                text: t('exercise.continueChallenge'),
                className: 'confirm'
              }
            ]
          ):([
            {
              key: 'cancel',
              text: t('exercise.quit'),
              className: 'cancel'
            },
            {
              key: 'restart',
              text: t('exercise.reChallenge'),
              className: 'confirm'
            },
          ])
        }
      />
    </>
  )
}
// http://jsfiddle.net/hkcoyq86/11/ 括号处理
export default withNamespaces()(TaskDetail);