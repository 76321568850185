import CircuitBoard from './CircuitBoard';
import { useParams, useNavigate } from "react-router-dom";

import Gate from './gate';
import React, { useState, useEffect } from "react";


export const Container = (props) => {
	// //console.log(props.TaskItemData)
  //console.log('container',props);
	const {gateList,circuitBoardInfo,operations,canDropList,draggingArea,functionArea,arrList} = {...props.TaskItemData}
	const [lastInsertGate, setLastInsertGate] = useState(null) // 设置上一次插入的gate，用于过滤相同操作
  const {courseId2taskId} = useParams()
	// 线路管理
	const [operationsState, originSetOperationsState] = useState(operations)
  const setOperationsState = (newState, needUpdateTask) => {
		if ((JSON.stringify(newState)===JSON.stringify(lastInsertGate) && !needUpdateTask) || (newState.timeSlot===-1 || (newState.qubits && newState.qubits[0]===-1))) return
		// console.log(newState,lastInsertGate);
    originSetOperationsState(newState)
		setLastInsertGate(newState)
		
    if(needUpdateTask) {
			const newTask = {...props.TaskItemData,operations:[...newState]}
			if (newTask.IsCircuitErr) { newTask.IsCircuitErr = false }
			props.setTaskData(newTask)
		}
  }
	// TODO: 切换页面时需要对页面进行更新
  useEffect(() => {
    let isSubscribe = true
		console.log("operationsState"+JSON.stringify(operationsState));
		console.log("operations"+JSON.stringify([...props.TaskItemData.operations]));
    if (isSubscribe && (JSON.stringify(operationsState)!==JSON.stringify(props.TaskItemData.operations))) {
      originSetOperationsState([...props.TaskItemData.operations])
			console.warn("route change and reset operation")
    }
    return () => {
      isSubscribe = false
    }
  }, [courseId2taskId])
	// 快照管理，在同一次hover事件中不断回滚到最初状态供set使用
	const [operationStateSnapShot, setOperationStateSnapShot] = useState([])
	// 拖拽状态管理
	const [isDragging, originSetIsDragging] = useState(undefined)
	const setIsDragging = (state) => {
		// console.log(state);
		// if (state !==isDragging) {
			originSetIsDragging(state)
		// }
	}
	// 插入临时占位
	function manualSetOperationState(newState) {
		// console.log(newState);
		let tempoperationStateSnapShot = JSON.parse(JSON.stringify(operationStateSnapShot))
		for (let i = 0; i < tempoperationStateSnapShot.length; i++) {
			if (!tempoperationStateSnapShot[i]) continue
			if (tempoperationStateSnapShot[i].draggingSource && newState === 'self') {
				tempoperationStateSnapShot[i].hidden = false
			}
			else if (tempoperationStateSnapShot[i].draggingSource && 
				(newState && (newState.timeSlot!==tempoperationStateSnapShot[i].timeSlot || newState.qubits[0]!==tempoperationStateSnapShot[i].qubits[0]))
				) {
				tempoperationStateSnapShot[i].hidden = true
			} else if (tempoperationStateSnapShot[i].draggingSource && !newState) {
				tempoperationStateSnapShot[i].hidden = true
			}
		}
		// //console.log([...tempoperationStateSnapShot,newState]);
		if (newState && newState !== 'self') {
			newState.draggingSource = undefined
			setOperationsState([...tempoperationStateSnapShot,newState])
		} else {
			setOperationsState([...tempoperationStateSnapShot].concat([]))
		}
	}
	// 将临时占位设置为当前放置的门
	function updateTempOperation(type) {
		console.warn("update");
		const typeMap = {H:'C1',X:'C1',Y:'C1',Z:'C1',I:'C1',CNOT:'C2',ZCON:'C2'}
		let newOp = undefined
		let newOpInd = -1
		operationsState.map((op,ind) => {
			if (op.gate.gtag === 'tempNode') {
				newOp = {...op}
				newOpInd = ind
			}
			return true
		})
		// //console.log(newOp);
		// 拖拽回原位
		if (!newOp) {
			const tempOpState = [...operationsState]
			for(let i= 0; i < tempOpState.length; i++) {
				if (tempOpState[i].draggingSource) {
					tempOpState[i].draggingSource = undefined
				}
			}
			setOperationsState(tempOpState,true)
			return
		}
		if (type==='ZCON' || type === 'CNOT') {
      // 对于控制门 小头为控制位，当小头前有x时cnot后续输出11
			newOp.gate.gname = type
			newOp.gate.gtag = typeMap[type]
			const tempOpState = [...operationsState]
      if (newOp.qubits[0]!==-1 && newOp.timeSlot!==-1) {
				// 对于双比特门，在2比特线路上直接创建即可
				if(circuitBoardInfo.bitsNum === 2) {
					if (newOp.qubits[0]===1) {newOp.qubits[1]=2}
					else if (newOp.qubits[0]===2) {newOp.qubits[1]=1}
					document.querySelector('#mask').style.display='none';
				} else if (circuitBoardInfo.bitsNum >= 3) {
					document.querySelector('#mask').style.display='block';
					// 上下两行插入供选择的临时节点
					// 中间的，检查上下是否有门，没有的话就创建临时节点
					// 记录上下可插入的qubits
					const SELFTIMESLOT = newOp.timeSlot
					const SELFQUBITS = newOp.qubits[0]
					let existQubits = []
					let canInsertQubits = []
					console.log(tempOpState);
					for (let i=0; i < tempOpState.length; i++) {
						console.log(JSON.parse(JSON.stringify(tempOpState[i])));
						if (tempOpState[i].timeSlot === SELFTIMESLOT && (!existQubits.includes(tempOpState[i].qubits[0]) && !tempOpState[i].draggingSource)) {
							existQubits.push(tempOpState[i].qubits[0])
						}
						// 如果为多比特门或者跨比特门还需要注意将控制位和之间的位置加到existQubits中
						console.log(tempOpState[i],tempOpState[i].gate.gtag,tempOpState[i].qubits.length);
						if (tempOpState[i].gate.gtag==='C2' && tempOpState[i].qubits.length > 1 && tempOpState[i].timeSlot === SELFTIMESLOT) {
							console.log();
							if (Math.abs(tempOpState[i].qubits[0]-tempOpState[i].qubits[1]) >= 1) {
								console.log(tempOpState[i],i);
								let max = tempOpState[i].qubits[0] > tempOpState[i].qubits[1] ? tempOpState[i].qubits[0]:tempOpState[i].qubits[1]
								let min = tempOpState[i].qubits[1] > tempOpState[i].qubits[0] ? tempOpState[i].qubits[0]:tempOpState[i].qubits[1]
								console.log(min,max);
								for (let j = min; j <= max; j++) {
									console.log(tempOpState[i],tempOpState[i].draggingSource);
									if (!existQubits.includes(j) && !tempOpState[i].draggingSource) {
										existQubits.push(j)
									}
								}
							}
						}
					}
					console.log(JSON.stringify(existQubits));
					// 从插入位置上下计算找到相连的一串不在existQubits中的内容
					let cpExistQubits = existQubits.concat([])
					cpExistQubits.pop() // 最后一项是本身不要了
					console.log(SELFQUBITS);
					for (let i = SELFQUBITS-2; i >= 0; i--) {
						console.log(i);
						if (cpExistQubits.includes(i+1)) {break;}
						canInsertQubits.push(i)
					}
					console.log(JSON.stringify(canInsertQubits));
					for (let i = SELFQUBITS; i < circuitBoardInfo.bitsNum; i++) {
						// alert(i)
						if (cpExistQubits.includes(i+1)) {break;}
						canInsertQubits.push(i)
					}
					console.log(canInsertQubits);
					canInsertQubits.map(it=>{
						tempOpState.push({timeSlot:newOp.timeSlot,qubits:[it+1],gate:{gname:'contForSel',gtag:'contForSel'},parent:{parentX:newOp.timeSlot,parentY:newOp.qubits[0]}})
						return true
					})
				}
				// 超出三个比特的情况。我们需要考虑插入临时节点供选择
				tempOpState[newOpInd] = newOp
			}
			const filterTempOpState = tempOpState.filter(tempOp=>{
				if (tempOp&&!tempOp.draggingSource && tempOp.qubits[0]!==-1) {return true}
        else {return false}
			})
			setOperationsState(filterTempOpState,true)
		} else {
			newOp.gate.gname = type
			newOp.gate.gtag = typeMap[type]
			const tempOpState = [...operationsState]
			// 超出三个比特的情况。我们需要考虑插入临时节点供选择
			tempOpState[newOpInd] = newOp
			const filterTempOpState = tempOpState.filter(tempOp=>{
				if (tempOp&&!tempOp.draggingSource && tempOp.qubits[0]!==-1) return true
        else { return false}
			})
			setOperationsState(filterTempOpState,true)
		}
	}
  // 更新双比特门的第二个参数同时删除线路上多余的其他门
  function updateSubCont(parent,subQubits) {
    // //console.log(parent);
		console.warn("updateSubCont")
    const copyOperations = [...operationsState]
    for (let i =copyOperations.length-1;i >=0 ; i--) {
      if (copyOperations[i].timeSlot === parent.parentX && copyOperations[i].qubits[0] === parent.parentY) {
        copyOperations[i].qubits[1] = subQubits
      }
    }
		const filterOperations = copyOperations.filter(op => {
			console.log(op);
			if (op.gate.gtag === 'contForSel') {return false}
			else {return true}
		})
		console.log(filterOperations);
    document.querySelector('#mask').style.display='none';
    setOperationsState(filterOperations,true)
  }
	return (<div>
		<div style={{ overflow: 'hidden', clear: 'both' }} className='gateToolBars'>
			<div className='gateContainer'>
				{
					gateList.map(git => {
						return <Gate 
              setIsDragging={setIsDragging} 
              updateTempOperation={updateTempOperation} 
              gateInfo={git} key={git.gname} 
              operationsState={operationsState} 
              setOperationStateSnapShot={setOperationStateSnapShot} 
              setTaskData={props.setTaskData}
              TaskItemData={props.TaskItemData}
							canDrag={git.canDrag}
							PageRenderAttr={props.PageRenderAttr}
              />
					})
				}
			</div>
		</div>
		<div >
			<CircuitBoard 
				key={"CircuitBoard-content"+courseId2taskId}
				circuitBoardInfo={circuitBoardInfo} 
				operationsState={operationsState} 
				manualSetOperationState={manualSetOperationState} 
				setOperationStateSnapShot={setOperationStateSnapShot}
				updateTempOperation={updateTempOperation}
				setIsDragging={setIsDragging}
        updateSubCont={updateSubCont}
				isDragging={isDragging}
				draggingArea={draggingArea}
				functionArea={functionArea}
				arrList={arrList}
				canDropList={canDropList}
        TaskItemData={props.TaskItemData}
        setTaskData={props.setTaskData}
				PageRenderAttr={props.PageRenderAttr}
			>
			</CircuitBoard>
		</div>
	</div>)
};
