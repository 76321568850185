import * as React from "react";
import { Outlet } from "react-router-dom";
import "./App.less"
import classNames from "classnames";
import { useLocation,useNavigate } from "react-router-dom"
import { ReactComponent as StuRegister } from "./img/stuRegister.svg"
import { ReactComponent as GroupManager } from "./img/groupManager.svg"
import { t } from "i18next";
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US'
import { withNamespaces } from 'react-i18next';

function Edu({t}) {
  console.log("Edu render");
  const location = useLocation()
  const navigate = useNavigate();
  // 修改Pagination中的page和jump_to
  zhCN.Pagination.jump_to = ""
  zhCN.Pagination.page = ""
  console.log(location.pathname,zhCN);
  
  return (
    <ConfigProvider locale={t('lang')==='cn'?zhCN:enUS}>
      <div className="Edu-block">
        { !location.pathname.includes("teamDetail") &&
          <div className="left-bar">
            <div className={classNames({"active":location.pathname.includes("stuRegister")},"register item")} onClick={()=>{navigate("/eduManager/stuRegister")}}>
              <StuRegister ></StuRegister>
              {t('stuRegister.module')}
            </div>
            <div className={classNames({"active":location.pathname.includes("groupManager")},"groupManager-left item")} onClick={()=>{navigate("/eduManager/groupManager")}}>
              <GroupManager ></GroupManager>
              {t('groupManager.module')}
            </div>
          </div>
        }
        <div className="mainer">
          <Outlet></Outlet>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default withNamespaces()(Edu);