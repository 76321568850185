import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import './TeamDetail.less'
import i18n from '../../i18n';
import { Button, Table, Space, Modal, message, Select, Popover, Transfer, Tag } from 'antd';

import 'antd/dist/antd.css'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import imgSrc from './img/noData.png'
import jschardet from 'jschardet'
import * as iconv from 'iconv-lite';
import {csv} from "csvtojson"
import { withNamespaces } from 'react-i18next';
import {fetcher} from '../../utils/fetcher'
import { ReactComponent as TeamMore } from "./img/teamMore.svg"
import { ReactComponent as NoTeam } from "./img/noTeam.svg"
import { ReactComponent as TeamDetailSVG } from "./img/teamDetail.svg"
import { ReactComponent as RecordIcon } from "./img/record.svg"
import { ReactComponent as CloseIcon } from "./img/close.svg"
import { ReactComponent as Backer } from "./img/backer.svg"

import { splitLocationSearch } from '../../utils/index'
import Record from "../task/Record";
import { t } from "i18next";
function TeamDetail(params) {
  // 获取小组信息
  const location = useLocation()
  const navigate = useNavigate()
  const pageSearchObj = splitLocationSearch(location.search)
  const [pageInfo, setPageInfo] = useState({})
  const institutionLicense = localStorage.getItem('institutionLicense')
  const [isMemberModalVisible, setMemberModalVisible] = useState(false)
  const [isSubtrMemberModalVisible, setSubtrMemberModalVisible] = useState(false)
  const [subtrMemberList,setSubtrMemberList] = useState([]) // 初始化移除小组成员列表，注意这两部分在每次窗口初始化时需要恢复
  const [subStrList,setSubStrList] = useState([]) // 记录被移除的成员，注意这两部分在每次窗口初始化时需要恢复
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [teamRecords, setTeamRecords] = useState([])
  const [delModalVisble,setDelModalVisible] = useState(false)
  const [showHelper, setShowHelper] = useState(false)
  const [decomposeModalVisbleId, setDecomposeModalVisibleId] = useState(-1) // 解散小组
  const [morePopoverVisible, setMorePopoverVisible] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([]) // 穿梭框
  const filterOption = (inputValue, option) => {
    console.log(option.description);
    if (option.description && option.title) {
      return (option.description.indexOf(inputValue) > -1) || (option.title.indexOf(inputValue) > -1)
    }
    return false
    // return option.description.indexOf(inputValue) > -1
  };

  const handleSelChange = (sourceSelectedKeys,newTargetKeys) => {
    console.log(sourceSelectedKeys,newTargetKeys);
    setTargetKeys(targetKeys.concat(sourceSelectedKeys));
    setShowHelper(false)
    setSelectedKeys([])
  };

  const handleChange = (sourceSelectedKeys,newTargetKeys) => {
    console.log(sourceSelectedKeys,newTargetKeys);
    setTargetKeys(sourceSelectedKeys);
    setShowHelper(false)
    setSelectedKeys([])
  };

  const handleSearch = (dir, value) => {
    console.log('search:', dir, value);
  }

  const handleDelMemberList = (ind) => {
    subStrList.push(subtrMemberList[ind].id)
    subtrMemberList.splice(ind,1)
    setSubtrMemberList(subtrMemberList.concat([]))
    setSubStrList(subStrList.concat([]))
  }

  const confirmMember = () => {
    
    if (targetKeys.length === 0) {
      setShowHelper(true)
      return
    }; // 没有学生不要发请求 
    // 多个学生插入小组
    fetcher('/user/team/teacher/addStudent','POST',JSON.stringify({ studentIdList: targetKeys, teamId: pageSearchObj.id})).then(res => {
      return res.json()
    }).then(r => {
      console.log(r.status);
      setTargetKeys([]);
      if (r.status === 200) {
        setMemberModalVisible(false)
        initPage()
        message.success(t('teamDetail.addSuccess'))
      } else {
        message.error(t('teamDetail.addFailed'))
      }
    })
  }
  // 初始化页面函数
  const initPage = () => {
    fetcher(`/user/team/getInfo?teamUsername=${pageSearchObj.username}&institutionLicense=${institutionLicense}`,'GET').then(res => {
      return res.json()
    }).then(r => {
      console.log(r);
      setPageInfo({
        teamName: r.item.title,
        teamUsername: pageSearchObj.username,
        teacherName: r.item.course.teacher.name,
        schoolName: r.item.institution.name,
        hardwareType: r.item.hardwareType,
        stuList: r.item.studentDTOList
      })
    })
    fetcher(`/data/exercise/teacher/getRecordsByTeamId?teamId=`+pageSearchObj.id,'GET').then(res => {
      return res.json()
    }).then(r => {
      // 读取小组学习记录后插入，注意不要与正常流程的学习记录混淆了，且此处的学习记录只有展示功能。
      console.log(r);
      const filterData = r.items.map(it => {
        return {
          courseId:`${it.questionId.split('_')[0]}`,
          recordId:`${pageSearchObj.id}--${it.questionId}`,
          taskInputData:it.input,
          taskStatus:it.status,
          taskId: it.questionId,
          userId:pageSearchObj.id
        }
      })
      setTeamRecords(filterData)
    })
  }
  
  const handleCancel = () => {
    setMemberModalVisible(false)
    setTargetKeys([]);
  }

  // 获取添加成员列表
  const visibleAndGetMemberInfo = () => {
    // 获取学生列表+学生列表中被选的
    setMemberModalVisible(true)
    // 得到未分组的学生信息
    fetcher(`/user/student/getAllInfo?showOnlyUnregistered=true`,'GET').then(res => {
      return res.json()
    }).then(re => {
      if (re.status === 200) {
        console.log(re);
        let filterRe = re.items.map(rit => {
          return {
            key:rit.id,
            title: rit.name,
            description: rit.className,
            code: rit.code,
            chosen:""
          }
        })
        setMockData(filterRe)
      }
    })

  }

  // 获取删除成员列表
  const visibleAndReadyRemoveMember = () => {
    setSubtrMemberModalVisible(true)
    setSubStrList([])
    setSubtrMemberList(pageInfo.stuList.concat([]))
  }

  // 删除组内学生
  const handleDelStuData = () => {
    if (subStrList.length > 0) {
      fetcher(`/user/team/teacher/removeStudent`,'POST',JSON.stringify({studentIdList:subStrList,teamId:pageSearchObj.id})).then(res => {
        if (res.status === 200) {
          message.success(t('teamDetail.removeMember.removeMemberSucc'))
          setDelModalVisible(false)
          setSubtrMemberModalVisible(false)
          initPage()
        } else {
          message.error("delete error")
        }
      })
    }
  };

  // 解散小组
  const handleDecomposeTeamData = () => {
    fetcher(`/user/team/teacher/delete?teamId=${decomposeModalVisbleId}`,'DELETE').then(res => {
      return res.json()
    }).then(re => {
      if (re.status === 200) {
        message.success(t('teamDetail.delTeam.delSuccess'))
        navigate(-1)
      } else {
        message.error(t('teamDetail.delTeam.delFailed'))
      }
    })
  }

  useEffect(() => {
    initPage()
  }, []);

  const content = (
    <div className="memberPopper">
      <Button onClick={()=>{visibleAndGetMemberInfo();setMorePopoverVisible(false);}}>{t('teamDetail.addMemberTips')}</Button>
      <Button onClick={()=>{visibleAndReadyRemoveMember();setMorePopoverVisible(false);}}>{t('teamDetail.moveMemberTips')}</Button>
      <Button onClick={()=>{setDecomposeModalVisibleId(pageSearchObj.id);setMorePopoverVisible(false);}}>{t('teamDetail.deComposeTeam')}</Button>
    </div>
  );
  // fetcher('/user/team/getInfo')
  return <div className="teamDetail">
    <div className="teamHeader">
      <div className="back">
        <span onClick={()=>{navigate(-1)}}><Backer className="backer" /> {t('global.back')}</span>
      </div>
      <div className="teamName">
        <p>{pageInfo.teamName}</p>
        <div>
          <Popover placement="bottomLeft" content={content} trigger="click" visible={morePopoverVisible} onVisibleChange={(v)=>{setMorePopoverVisible(v)}}>
            <TeamMore className="teamMore" />
          </Popover>
        </div>
      </div>
    </div>
    <div className="teamMain">
      <div className="teamDetailAndMember">
        <p className="sTit">
          <TeamDetailSVG />
          {t('teamDetail.detail.tit')}
        </p>
        <div className="detailAndMemberContainer">
          <div className="detailContent">
            <p>
              <label>{t('teamDetail.detail.lisence')}：</label><span>{pageInfo.teamUsername}</span>
            </p>
            <p>
              <label>{t('teamDetail.detail.teacher')}：</label><span>{pageInfo.teacherName}</span>
            </p>
            <p>
              <label>{t('teamDetail.detail.school')}：</label><span>{pageInfo.schoolName}</span>
            </p>
            <p>
              <label>{t('teamDetail.detail.adaptEq')}：</label><span>{t(`equips.${pageInfo.hardwareType}`)}</span>
            </p>
          </div>
          <div className="teamMember">
            <div>
              <label>{t('teamDetail.detail.member')}：</label>{pageInfo.stuList && pageInfo.stuList.length === 0 && <span>{t('lang')==='en'?'-':'无'}</span>}
            </div>
            <div className="teamList">
              {
                pageInfo.stuList && pageInfo.stuList.map(stuItem => {
                  return (
                    <div className="teamItem">
                      <div className="stuName">{stuItem.name}</div>
                      <p className="className">{stuItem.className}</p>
                      <p className="stuCode">{stuItem.code}</p>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="recordWrapper">
        <p className="sTit"><RecordIcon />{t('exercise.recordInTeam')}</p>
        {/* 只要有学习记录，就加载记录模块，这个可以没有学生，因为学生可能被删除 */}
        {teamRecords && teamRecords.length > 0 ?  <Record teamRecords={teamRecords} isTeacherRecord={true}></Record> :
          (<div className="noDataContent">
          <NoTeam className="noTeam" />
          <div className="noData">{t('lang')==='cn'?'暂无数据':'No Data'}</div>
        </div>)
        }
      </div>
    </div>
    <Modal
        closable={false}
        key="addMember" className="memberModal" title={t('teamDetail.addMember.tit')} visible={isMemberModalVisible} onCancel={()=>{handleCancel()}}
      footer={[<Button key="cancelbtn2" onClick={()=>{handleCancel()}}>{t('global.cancel')}</Button>,<Button key="submit1" type="primary" onClick={()=>{confirmMember()}}>
      {t('global.confirm')}
    </Button>]}
    >
      <div className="tips">
        <p>{t('teamDetail.addMember.chooseMember')}：</p>
      </div>
      <Transfer
        dataSource={mockData}
        showSearch
        filterDropdownVisible={false}
        oneWay
        selectAllLabels={[
          ({ selectedCount, totalCount }) => (
            <span>
              {t('teamDetail.addMember.stuList')}
              <span className="totalMemberInTeam">{t('lang')==='cn'?`(${totalCount}人)`:`(${totalCount} in total)`}</span>
            </span>
          ), ({ selectedCount, totalCount }) => (
            <span>
              {t('teamDetail.addMember.choosedStu')}
              <span className="totalMemberInTeam">{t('lang')==='cn'?`(${totalCount}人)`:`(${totalCount} in total)`}</span>
            </span>
          )
        ]}
        showSelectAll={false}
        selectedKeys={selectedKeys}
        locale={{searchPlaceholder:t('teamDetail.addMember.searchBy')}}
        filterOption={filterOption}
        targetKeys={targetKeys}
        onSelectChange={handleSelChange}
        onChange={handleChange}
        onSearch={handleSearch}
        render={(item) => {
          return (<div>
            <div className="item-tit">{item.title}</div>
            <div className="item-desc">
              <span className="item-code">{item.code}</span>
              <span>{item.description}</span>
            </div>
          </div>)
        }}
      />
      {showHelper && <div className="formHelper">{t('groupManager.createTeam.selectATeamMember')}</div>}
    </Modal>

    <Modal
        closable={false}
        key="substMember" className="memberModal subsModal" title={t('teamDetail.removeMember.tit')} visible={isSubtrMemberModalVisible} onCancel={()=>{setSubtrMemberModalVisible(false)}}
      footer={[<Button key="cancelbtn2" onClick={()=>{setSubtrMemberModalVisible(false)}}>{t('global.cancel')}</Button>,<Button key="submit1" type="primary" onClick={
        () => {
          if (subStrList.length > 0) {setDelModalVisible(true)} 
          else {
            console.log(subStrList.length);
            setSubtrMemberModalVisible(false)
          }
        }}
      >
      {t('global.confirm')}
    </Button>]}
    >
      <div className="tips">
        <p>
          {t('teamDetail.removeMember.currentMember')}：
          <span>{t('lang')==='cn'? `共 ${subtrMemberList.length} 人`:`${subtrMemberList.length} in total`}</span>
        </p>
      </div>
      <div className="stuMemberList">
        {
          subtrMemberList.map((item,subtInd) => {
            return (<div className="stuMemberItem">
              <div className="item-tit">{item.name}</div>
              <div className="item-desc">
                <span className="item-code">{item.code}</span>
                <span>{item.className}</span>
              </div>
              <CloseIcon onClick={()=>{handleDelMemberList(subtInd)}} className="close" />
            </div>)
          })
        }
        {subtrMemberList.length === 0 && <div className="stuMemberItem memberNodata">{t('teamDetail.noData')}</div>}
      </div>
    </Modal>
    <Modal
        closable={false}
        key="delStuModal" className="eduModal delModal" title={t('teamDetail.removeMember.tit')} visible={delModalVisble} onCancel={()=>{setDelModalVisible(false)}}
        footer={[<Button key="cancelbtn1" onClick={()=>{setDelModalVisible(false)}}>{t('global.cancel')}</Button>,<Button key="submit2" type="primary" onClick={handleDelStuData}>
        {t('global.confirm')}
      </Button>]}
      >
        <div className="tips">
          <ExclamationCircleOutlined /> {t('teamDetail.delStuInfo.content')}
        </div>
    </Modal>
    <Modal
        closable={false}
        key="delTeamModal" className="eduModal delModal" title={t('teamDetail.delTeam.del')} visible={decomposeModalVisbleId!==-1} onCancel={()=>{setDecomposeModalVisibleId(-1)}}
      footer={[<Button key="cancelbtn1" onClick={()=>{setDecomposeModalVisibleId(-1)}}>{t('global.cancel')}</Button>,<Button key="submit2" type="primary" onClick={()=>{handleDecomposeTeamData(decomposeModalVisbleId)}}>
      {t('teamDetail.delTeam.delBtn')}</Button>]}
    >
      <div className="tips">
        <ExclamationCircleOutlined /> {t('teamDetail.delTeam.delTips')}
      </div>
    </Modal>
  </div>
}
export default withNamespaces()(TeamDetail);
