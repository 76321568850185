// 针对的是12-1与12-2 16-2三道填空题
import classNames from "classnames";
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

function SimpleTextInput(props) {
  if (props.TaskItemData.type !== 'SimpleTextInput') return <></>
  console.log(props);
  const t = props.t
  const simpleTextList = props.TaskItemData['simpleText']
  const taskId = props.TaskItemData['taskId']
  const TaskItemData = props.TaskItemData
  return (
    <div className="SimpleTextInputComponent">
      {
        props.TaskItemData['taskId'] === '12_1' && 
        (
          <>
            <div className="subTips">{t(simpleTextList[0])}</div>
            <p className="tips">
            {t(simpleTextList[1])}
            </p>
            <div className="inputContainer">
              <div className="avg">
                <span>{t(simpleTextList[2])} = </span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][0]===false)})}
                onChange={(e)=>updateData(0,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][0]} />
              </div>
              <div className="reverseList">
                <span>{t(simpleTextList[3])} = </span>
                <span className="bra">【</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][1]===false)})}
                onChange={(e)=>updateData(1,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][1]} />
                <span>,</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][2]===false)})}
                onChange={(e)=>updateData(2,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][2]} />
                <span>,</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][3]===false)})}
                onChange={(e)=>updateData(3,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][3]} />
                <span>,</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][4]===false)})}
                onChange={(e)=>updateData(4,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][4]} />
                <span>,</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][5]===false)})}
                onChange={(e)=>updateData(5,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][5]} />
                <span>,</span>
                <input type="tel" 
                className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][6]===false)})}
                onChange={(e)=>updateData(6,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][6]} />
                <span className="ket">】</span>
              </div>
            </div>
          </>
        )
      }
      {
        props.TaskItemData['taskId'] === '12_2' && 
        (
          <>
            <div className="subTips">{t(simpleTextList[0])}
              <div className="inputContainer matrixRight">
                <div className="reverseList">
                  <div className="matrix">
                    <div className="inputBox">
                      <input type="tel" disabled value={0} />
                    </div>
                    <div className="inputBox">
                      <input type="tel" disabled value={0} />
                    </div>
                    <div className="inputBox">
                      <input type="tel" disabled value={0} />
                    </div>
                    <div className="inputBox">
                      <input type="tel" disabled value={1} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="tips">
            {t(simpleTextList[1])}
            </p>
            <div className="inputContainer matrixRight">
              <div className="avg">
                <span>{t(simpleTextList[2])} = </span>
                <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][0]===false)})}
                onChange={(e)=>updateData(0,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][0]} />
              </div>
              <div className="reverseList">
                <span>{t(simpleTextList[3])} = </span>
                <div className="matrix">
                  <div className="inputBox">
                    <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][1]===false)})}
                onChange={(e)=>updateData(1,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][1]} />
                  </div>
                  <div className="inputBox">
                    <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][2]===false)})}
                onChange={(e)=>updateData(2,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][2]} />
                  </div>
                  <div className="inputBox">
                    <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][3]===false)})}
                onChange={(e)=>updateData(3,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][3]} />
                  </div>
                  <div className="inputBox">
                    <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][4]===false)})}
                onChange={(e)=>updateData(4,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][4]} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        props.TaskItemData['taskId'] === '16_2' && 
        (
          <>
            <div className="smalltopic">
              <div className="stem">{t(simpleTextList[0])}</div>
              <div className="topic">
                {t(simpleTextList[3])}
                <div className="inputer">
                  <p>P= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][0]===false)})}
                onChange={(e)=>updateData(0,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][0]} /></p>
                  <p>Q= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][1]===false)})}
                onChange={(e)=>updateData(1,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][1]} /></p>
                </div>
              </div>
            </div>
            <div className="smalltopic">
              <div className="stem">{t(simpleTextList[1])}</div>
              <div className="topic">
                {t(simpleTextList[3])}
                <div className="inputer">
                  <p>P= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][2]===false)})}
                onChange={(e)=>updateData(2,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][2]} /></p>
                  <p>Q= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][3]===false)})}
                onChange={(e)=>updateData(3,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][3]} /></p>
                </div>
              </div>
            </div>
            <div className="smalltopic">
              <div className="stem">{t(simpleTextList[2])}</div>
              <div className="topic">
                {t(simpleTextList[3])}
                <div className="inputer">
                  <p>P= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][4]===false)})}
                onChange={(e)=>updateData(4,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][4]} /></p>
                  <p>Q= <input type="tel" className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][5]===false)})}
                onChange={(e)=>updateData(5,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][5]} /></p>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  )
}
// 调用父级set更新全局函数
function updateData(index,props,taskId,event) {
  console.log(props);
  const TaskItemData = Object.assign(props.TaskItemData,{})
  console.log(JSON.stringify(TaskItemData.taskBodyItemInput));
  TaskItemData['taskBodyItemInput'][index] = event.target.value.trim()
  if (TaskItemData['task-body-answer-status']) {
    TaskItemData['task-body-answer-status'][index] = true
  }
  console.log(TaskItemData['task-body-answer-status']);
  props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
}
export default withNamespaces()(SimpleTextInput);
