import callEquipmentByMessage from './bridge'
import {db} from '../data/db'
import { Toast,Modal } from 'antd-mobile'
import {FindTopicChangeIrreversibleInfo} from './index'
import irreversibleRecordInfo from './irreversibleRecord'
import {fetcher} from './fetcher'
/**
 * jsonStr内容
 * @param {*} accountId 
 * @param {*} role 
 * @param {*} accessToken 
 * @param {*} username 
 * @returns 
 */
async function initHandler (jsonStr) {
  let irreversibleRecord = irreversibleRecordInfo.irreversibleChangeRecord
  let accountId = -1
  let username = "" // 用于教师获取小组信息
  let institutionLicense = "" // 用于教师获取小组信息
  let role = "guest"
  let accessToken = -1
  let hardwareType = "gemini"
  // Modal.alert({content:"initHandler---start"})
  if (jsonStr) {
    const info = JSON.parse(jsonStr)
    console.log(info,"initHandler---info");
    accountId = info.accountId
    username = info.username
    // institutionLicense = info.institutionLicense
    role = info.role
    accessToken = info.accessToken
    hardwareType = info.hardwareType || "gemini"
  }
  // 获取所有云端数据更新到当前db
  // 初始化所有参数
  const hash = window.location.hash
  sessionStorage.clear()
  localStorage.clear()
  localStorage.setItem('userId','guest')
  console.log("clear data start");
  await db['taskTable'].clear()
  // Modal.alert({content:"initHandler---db clear"})

  console.log("clear data end");
  const temp = (
    <>
      <div className="NativeBridge">NativeBridge</div>
    </>
  )
  localStorage.setItem('userId',accountId)
  localStorage.setItem('role',role)
  localStorage.setItem('accessToken',accessToken)
  localStorage.setItem('hardwareType',hardwareType)
  localStorage.setItem('username',username)
  localStorage.setItem('institutionLicense',institutionLicense)
  if (role==="guest") {
    // Toast.show({
    //   content: '游客访问'+accountId,
    //   position: 'top',
    //   maskStyle:{
    //     fontSize:'140px'
    //   },
    //   maskClassName: 'bigToast'
    // })
    return temp
  } else {
    // Toast.show({
    //   content: '欢迎：'+accountId,
    //   position: 'top',
    //   maskStyle:{
    //     fontSize:'140px'
    //   },
    //   maskClassName: 'bigToast'
    // })
  }
  const currentUserId = localStorage.getItem('userId')
  // 教师端和学生端使用url不同
  let url = role === "teacher" ? "/data/exercise/teacher/getRecordsByTeacher" : ("/data/exercise/user/getRecords?hardwareType="+hardwareType)
  fetcher(url,"GET").then(final=>{
    return final.json();
  }).then(res => {
    console.log("update data end");
    sessionStorage.setItem("getrecordInfo","eeeeeeeeend")
    if (res.status!==200) {
      sessionStorage.setItem("getrecordresfailed","eeeeeeeeend")
      callEquipmentByMessage(
        `{"type":"getRecordErr","msg":"${res.msg}"}`
      )
      // Modal.alert({content:"数据同步失败1"})
      Toast.show({
        content: '数据同步失败1'+JSON.stringify(res.msg),
        position: 'top',
        maskStyle:{
          fontSize:'140px'
        },
        maskClassName: 'bigToast'
      })
      return
    }
    console.log(res);
    // 将获取的数据转化为当前组件所需格式
    const updateData = (res.items).map(finalItem => {
      // 读取version中的irreversibleChangeRecord ， 判断上次上传与这个修改的先后顺序，如果出现在其后则将对应的记录过滤掉
      const infoItem = FindTopicChangeIrreversibleInfo(irreversibleRecord,finalItem.questionId)
      console.log(infoItem);
      const latestModificationTime = new Date(finalItem.latestModificationTime).getTime()
      if (infoItem.questionInIrreversibleListIndex !== -1 && latestModificationTime <= infoItem.irrTimeStamp) {
        return null
      }
      return {
        recordId: `${currentUserId}--${finalItem.questionId}`,
        userId:currentUserId,
        taskId:`${finalItem.questionId}`,
        courseId:`${finalItem.questionId.split('_')[0]}`,
        taskStatus:finalItem.status.toLowerCase(),
        taskInputData:JSON.parse(finalItem.input)
      }
    })
    console.log(updateData);
    let tableData = JSON.stringify(updateData)
    localStorage.setItem("tableData",tableData)
    try {
      updateData.forEach((element,index) => {
        sessionStorage.setItem("inputindex"+index,JSON.stringify(element))
        
      });
      // 将不存在的元素过滤掉
      const filterDatas = updateData.filter(dataItem=> {
        console.log(dataItem);
        return dataItem
      })
      console.log("filter data--------s");
      console.log(JSON.parse(JSON.stringify(filterDatas)));
      console.log("filter data--------e");
      db['taskTable'].bulkPut(filterDatas).then(succ => {
        console.log(succ);
        window.getDBData()
        console.log("db bulkput");
        callEquipmentByMessage(
          '{"type":"getRecordSuccess","msg":"success"}'
        )
      }).catch(e => {
        console.log("error");
      })
      sessionStorage.setItem("updateData","eeeeeeeeend")
      return {code : 0}
    } catch (error) {
      console.log(error);
      // Modal.alert({content:"数据同步失败2"})
      Toast.show({
        content: '数据同步失败2'+JSON.stringify(error),
        position: 'top',
        maskStyle:{
          fontSize:'140px'
        },
        maskClassName: 'bigToast'
      })
      sessionStorage.setItem("catcherr","eeeeeeeeend")
      callEquipmentByMessage(
        '{"type":"getRecordErr","msg":'+JSON.stringify(error)+'}'
      )
    }
  }).catch(e=>{
    sessionStorage.setItem('errorinloaddata',e.toString())
  })
}
export {initHandler}