import React, { useState } from "react";
import classNames from "classnames";
import { Checkbox } from 'antd-mobile'
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

function buildTips(TaskItemData,t) {
    if (TaskItemData.tips) {
      return <div className="tips" dangerouslySetInnerHTML={{__html:t(TaskItemData.tips)}}></div>
    }
}

function updateIsInitState(cidx, isInitState, setIsInitState) {
    let copyIsInitState = JSON.parse(JSON.stringify(isInitState))
    copyIsInitState[cidx] = false
    setIsInitState(copyIsInitState)
}

function handleSelect(multiple, selectedIdx, selectedVal, props) {
    // console.log("multiple = " + multiple + ", selectedIdx = " + selectedVal)
    const newItemData = Object.assign(props.TaskItemData,{})
    const oldIdx = newItemData.taskBodyItemInput.indexOf(selectedVal)
    newItemData['task-body-answer-status'][selectedIdx] = true
    console.log(JSON.stringify(newItemData));
    if (oldIdx===-1) {
        // choose a not selected idx
        if (multiple) {
            console.log("multiple");
            newItemData.taskBodyItemInput.push(selectedVal)
        } else {
            console.log("multiple else");

            newItemData.taskBodyItemInput = [selectedVal]
        }
    } else {
        // choose a selected idx
        newItemData.taskBodyItemInput.splice(oldIdx, 1); 
    }
    console.log(newItemData);
    props.setTaskData(JSON.parse(JSON.stringify(newItemData)))
}

function buildChoices(props, isInitState, setIsInitState,t) {
    const TaskItemData = props.TaskItemData
    
    console.log("input status = " + TaskItemData['task-body-answer-status'])
    // console.log("Individual TaskItemData = " + JSON.stringify(TaskItemData.choices))
    if (!TaskItemData['task-body-answer-status'] || TaskItemData['task-body-answer-status'].length===0) {
      TaskItemData['task-body-answer-status'] = Array(TaskItemData.choices.length).fill(false)
    }
    if (TaskItemData.choices && TaskItemData.choices.length > 0) {
      return <div className="choice-wrapper">
          {
            TaskItemData.choices.map((choice, cidx) => {
                console.log(cidx);
                const incorrect = TaskItemData.taskBodyItemInput.indexOf(choice) !==-1 && props.correctAnswer.indexOf(choice)===-1
                if (TaskItemData.uiFormat==="card") {
                    return <div className={classNames({"inline-choice": TaskItemData.choiceInline}, "choice-wrap")} key={"choice-"+cidx} id={"choice-"+cidx}>
                        <div className={classNames({'selected': TaskItemData.taskBodyItemInput.indexOf(choice) !==-1,
                        "error": !TaskItemData['task-body-answer-status'][cidx]},
                            "choice card-choice")}
                            onClick={()=>{handleSelect(TaskItemData.multiple, cidx, choice, props)}}>{t(choice)}</div>
                    </div>
                } else if (TaskItemData.uiFormat==="text") {
                    return <div className={classNames({"inline-choice": TaskItemData.choiceInline}, "choice-wrap")} key={"choice-"+cidx} id={"choice-"+cidx}>
                        <Checkbox className={classNames({"error": !TaskItemData['task-body-answer-status'][cidx]}, "choice txt-choice")} 
                        checked={TaskItemData.taskBodyItemInput.indexOf(choice) !==-1} 
                        onChange={()=>{handleSelect(TaskItemData.multiple, cidx, choice, props)}}>{t(choice)}</Checkbox>
                    </div>
                }
                return true
            })
          }
      </div>
    }
}

function MultipleChoice(props) {
    const [isInitState, setIsInitState] = useState(new Array(props.TaskItemData.choices.length).fill(true))
    
    if (props.TaskItemData.type !== 'MultipleChoice') return <div style={{display:'none'}}></div>
    return <div className={classNames({'inline-wrap': props.TaskItemData.questionInline}, "multiple-choice-wrap")}>
        {buildTips(props.TaskItemData, props.t)}
        {buildChoices(props, isInitState, setIsInitState, props.t)}
    </div>
  }

  // export default MultipleChoice
  export default withNamespaces()(MultipleChoice);