import * as React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom"
import classNames from "classnames";

import "./user.less";
function User() {
  const location = useLocation()
  console.log(location.pathname);
  return (
    <div className={classNames({"login-page":location.pathname.includes("/user/login"),"register-page":location.pathname.includes("/user/register")},"user padding-wrap")} >
      <Outlet></Outlet>
    </div>
  );
}




export  {User};