import callEquipmentByMessage from './bridge'
import {db} from '../data/db'
import {getUrlToken} from "./index"
import { message } from 'antd'
import {fetcher} from './fetcher'

/**
 * 
 * @param {boolean} continueLogin pro中每次答题后需要上传数据
 * @param t i18n相关
 * @returns 
 */
function uploadDataHandler(continueLogin, t) {
  // 遍历所有题目比较上传时间与修改时间，并且将修改时间晚于上传时间的题目重新提交上云
  const hash = window.location.hash
  const currentUserId = localStorage.getItem('userId')
  const currentRole = localStorage.getItem('role')
  const accessToken = localStorage.getItem('accessToken')
  // 非安卓和游客不需要上传数据
  if (currentUserId === 'guest' || currentRole === 'guest' || accessToken === "-1" || currentUserId === "-1") return false
  const accountId = getUrlToken('accountId',hash)
  // const accessToken = getUrlToken('accessToken',hash)
  const questionlist = db['taskTable'].where({userId:currentUserId}).toArray().then(
    res => {
      const composeData = res.map(resite => {
        return {
          courseId: Number(resite.courseId),
          questionId: resite.taskId,
          status: resite.taskStatus,
          input: JSON.stringify(resite.taskInputData)
        }
      })
      console.log(composeData);
      const filterRes = composeData.filter(it => {
        return it.status !== "saved"
      })
      let body = JSON.stringify({items:filterRes})
      
      let hardwareType = localStorage.getItem("hardwareType") || "gemini"
      let fetcherUrl = currentRole === 'teacher' ? "/data/exercise/teacher/updateRecords" : "/data/exercise/user/updateRecords"
      fetcher(fetcherUrl,"POST",body).then(final=>{
        console.log(final);
        return final.json()
      }).then(res => {
        console.log(res);
        if (res.status!==200) {
          callEquipmentByMessage(
            '{"type":"uploadRecordErr","msg":"'+res.msg+'"}'
          )
          message.error(t('stuRegister.netWorkErr'))
        } else {
          // 如果是pro中的设备，不必重新登录更新信息
          if (hardwareType === 'triangulum-pro' && continueLogin) return
          callEquipmentByMessage(
            '{"type":"uploadRecordSuccess","msg":"upload record success"}'
          )
          // 对于web来说，不需要退出登录时上传信息
          // window.initHandler('{"accountId":"-1","username":"-1","accessToken":"-1","role":"guest"}')
        }
      }).catch(e => {
        console.log(e.name,e.message);
        
        message.error(t('stuRegister.netWorkErr'))
        callEquipmentByMessage(
          '{"type":"uploadRecordErr","msg":"'+e.name+e.message+'"}'
        )
      })
    }
  )
  console.log(questionlist);
  
}
export {uploadDataHandler}