const _ORIGIN_URL = "http://edu.spinq.cn" 
const _ENV_PORT = "4010" // uat为4000, prod为5000

export function fetcher (url,type,body) {
  let controller = new AbortController(); // 请求终止控制
  let signal = controller.signal;
  let timeout = 20000
  // let postUrl = `${_ORIGIN_URL}:${_ENV_PORT}${url}`
  let postUrl = process.env.NODE_ENV === 'development' 
  ? `${url}` 
  : `/api${url}`;
  let accessToken = localStorage.getItem('accessToken')
  let postBody = {
    method:type,
    credentials: 'include', 
    headers: {
      'Content-Type': 'application/json',
      mode: 'cors', // no-cors, *ecors, same-origin
      'token': accessToken,
      Accept: "*/*",
      'pragma':'no-cache',
      'cache-control': 'no-cache',
      lang: localStorage.getItem('lang') || 'zh',
    },
    signal
  }
  if (body) {
    postBody.body = body
  }
  return new Promise((resolve, reject) => {
    try {
      sessionStorage.setItem("postUrl",postUrl+"-"+JSON.stringify(postBody))
      fetch(postUrl, postBody).then(resolve).catch(reject);
    } catch (error) {
      sessionStorage.setItem("errorinfetcher",error.toString())
    }
  });
}