/**
 * 
 * @param {number} x 坐标系x,单位为1 
 * @param {number} y 坐标系y,单位为1 
 * @returns 一个以像素为坐标的最终值
 */
 export function getPosition(x, y, GATESIZE, TAGWIDTH) {
  return { left: GATESIZE + (x - 1) * (GATESIZE * 2) + TAGWIDTH, top: GATESIZE* 2 + (y - 1) * (GATESIZE * 2) }
}
/**
 * 
 * @param {number} leftPixel 距离父级容器的左边缘的距离 
 * @param {number} topPixel 距离父级容器的顶部边缘的距离 
 * @returns 以坐标系为标准，单位为1
 */
 export function getCoordinate(leftPixel, topPixel, GATESIZE, TAGWIDTH, CIRCUITMAXX, CIRCUITMAXY) {
  // 是否可以直接读取每个Gatesize单位长，
  const minXInPixel = TAGWIDTH + GATESIZE / 2
  const minYInPixel = GATESIZE / 2 + GATESIZE
  // 左上角第一个坐标的x = [80（左侧tag宽）+ GATESIZE/2（半个GATESIZE）,80（左侧tag宽）+ GATESIZE/2（半个GATESIZE）+GATESIZE]
  // 左上角第一个坐标的y = [GATESIZE/2（半个GATESIZE）,GATESIZE/2（半个GATESIZE）+GATESIZE]
  // 但实际上我们只要控制好当前最小坐标到下一个的最小坐标即可，这个范围返回都为同一个x
  const xPixelList = new Array(CIRCUITMAXX).fill(undefined).map((it, ind) => {
    return minXInPixel + ind * GATESIZE * 2
  })
  const yPixelList = new Array(CIRCUITMAXY).fill(undefined).map((it, ind) => {
    return minYInPixel + ind * GATESIZE * 2
  })
  ////console.log(xPixelList,yPixelList);
  let xCoordinate = -1
  let yCoordinate = -1
  xPixelList.some((xpixelitem, xpixelindex) => {
    if (leftPixel >= xpixelitem && leftPixel < xPixelList[xpixelindex + 1]) {
      xCoordinate = xpixelindex + 1
      return true
    } else {
      return false
    }
  })
  yPixelList.some((ypixelitem, ypixelindex) => {
    if (topPixel >= ypixelitem && topPixel < yPixelList[ypixelindex + 1]) {
      yCoordinate = ypixelindex + 1
      return true
    } else {
      return false
    }
  })
  return { xCoordinate, yCoordinate }
}