// 工具函数
import {some} from 'lodash'
export function hasDuplicates(array) {
  return some(array, function(elt, index) { 
      return array.indexOf(elt) !== index; 
  });
}
// 获取url hash中的 search参数
export function getUrlToken(name, hash) {
  const str = `?${hash.split('?')[1]}`
  const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
  const r = str.substr(1).match(reg);
  if (r != null) return  decodeURIComponent(r[2]); return null;
}

// 用于初始化数据集，检查题型是否发生了不可逆的修改，对比上一次提交的时间和修改题干的时间，返回布尔告知是否要过滤掉
export function FindTopicChangeIrreversibleInfo(irreversibleList,questionId) {
  let questionInIrreversibleListIndex = -1
  let irrTimeStamp = -1
  for (let irrListInde = 0; irrListInde < irreversibleList.length; irrListInde++) {
    const irreversibleItem = irreversibleList[irrListInde];
    const ids = irreversibleItem.ids
    irrTimeStamp = irreversibleItem.finalChangetimeStamp
    if (ids.includes(questionId)) {
      questionInIrreversibleListIndex = irrListInde
    }
  }
  return { questionInIrreversibleListIndex, irrTimeStamp }
}

// 获取连接search中的参数并以key-value输出
export function splitLocationSearch(search) {
  if (!search) return null
  else {
    const kvstr = search.split('?')[1]
    const kvUnit = kvstr.split('&')
    console.log(kvUnit);
    let obj = {}
    kvUnit.map(unit => {
      const k = unit.split('=')[0]
      const v = unit.split('=')[1]
      obj[k] = v
      return true
    })
    return obj
  }
}

// 判断是否为正整数，正浮点数
export function isDigit(str) {
  let reg = /^\d+(\.\d+)?$/g
  let flag = reg.test(str)
  console.log(flag);
  return flag
}

// 中文环境时将数字1-9转为中文一至九，英文原样返回
export function transformNumberToChinese(lang, number) {
  if (lang === 'cn') {
    let mapper = ["一","二","三","四","五","六","七","八","九","十"];
    return mapper[Number(number) - 1]
  } else {
    return number
  }
}
