import React from "react";
import {Course} from './pages/course/App'
import CourseList from './pages/course/CourseList'
import CourseDetail from './pages/course/CourseDetail'
import {Task} from './pages/task/App'
import TaskList from './pages/task/TaskList'
import QuestionList from './pages/task/QuestionList'
import TaskDetail from './pages/task/TaskDetail'
import Record from './pages/task/Record'
import {NativeBridge} from './pages/bridge/NativeBridge'
import Home from './Home'
import {Pact} from './pages/pact/App'
import Edu from './pages/eduManager/App'
import Term from './pages/pact/Term'
import PrivacyPolicy  from "./pages/pact/PrivacyPolicy";
import StuRegister from './pages/eduManager/StuRegister'
import GroupManager from './pages/eduManager/groupManager'
import TeamDetail from './pages/eduManager/TeamDetail'
import {User} from './pages/user/App'
import Login from './pages/user/Login'
import Register from './pages/user/Register'
import ResetPassword from './pages/user/ResetPassword'
import UserSetting from './pages/user/UserSetting'
import { Navigate } from 'react-router-dom';

const routes = [
  {
    path: '/',
    element: <Home/>,
    children: [
      {
        path: 'course/*',
        label: '课程',
        element: <Course/>,
        children: [
          {
            path: 'list',
            label: '课程列表',
            element: <CourseList/>
          },
          {
            path: 'detail/:id',
            label: '课程详情',
            element: <CourseDetail/>
          }
        ]
      },
      // TODO: task
      {
        path: 'task',
        label: '作业',
        element: <Task/>,
        children: [
          {
            path: 'list',
            label: '课程列表',
            element: <TaskList/>
          },
          {
            path: 'questions/:courseId',
            label: '作业列表',
            element: <QuestionList/>
          },
          {
            path: 'detail/:courseId2taskId',
            label: '作业详情',
            element: <TaskDetail/>
          },
          {
            path: 'record',
            label: '学习记录',
            element: <Record/>
          },
          {
            path: 'nativeBridge',
            label: '数据上传',
            element: <NativeBridge/>
          }
        ]
      },
      {
        path: 'pact',
        label: '协议',
        element: <Pact/>,
        children: [
          {
            path: 'term',
            label: '用户协议',
            element: <Term/>
          },
          {
            path: 'PrivacyPolicy',
            label: '用户协议',
            element: <PrivacyPolicy/>
          }
        ]
      },
      {
        path: 'eduManager',
        label: '教学管理',
        element: <Edu/>,
        children: [
          {
            path: 'stuRegister',
            label: '学生名册',
            element: <StuRegister/>
          },
          {
            path: 'groupManager',
            label: '班组管理',
            element: <GroupManager/>
          },
          {
            path: 'teamDetail',
            label: '小组详情',
            element: <TeamDetail/>
          }
        ]
      },
      {
        path: 'bridgeRegister',
        label: '原生桥接',
        element: <NativeBridge/>
      },
      {
        path: 'user/*',
        label: '用户登录注册',
        element: <User/>,
        children: [
          {
            path: 'login',
            label: '登录',
            element: <Login/>
          },
          {
            path: 'register',
            label: '注册',
            element: <Register/>
          },
          {
            path: 'resetPassword',
            label: '重置密码',
            element: <ResetPassword/>
          },
          {
            path: 'userSetting',
            label: '账号设置',
            element: <UserSetting/>
          }
        ]
      }
    ]
  }
]

export default routes