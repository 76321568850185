import * as React from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";

function Task() {
  const location = useLocation()
  console.log("task render");
  return (
    <div className={classNames({"task-list-page":location.pathname.includes("/task/list"),"question-page":location.pathname.includes("/question")},"task-block")}>
      <Outlet></Outlet>
    </div>
  );
}

export  {Task};