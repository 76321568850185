import classNames from "classnames";
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

function Multiplication(props) {
  if (props.TaskItemData.type !== 'Multiplication') return <></>
  const t = props.t
  const selfBodyInput = props.TaskItemData['taskBodyItemInput']
  const taskId = props.TaskItemData['taskId']
  return  <div className="MutiplicationComponent">
    { taskId === '11_1' && 
      <div className="vectorMultiplication">
        <div className="leftInput">
          <span>|1&gt;<div className="symbol">⨂</div>|1&gt;</span>
        </div>
        <div className="symbol">=</div>
        <div className="midInput">
          <div className="matrix matrix1">
            <div className="inputBox">
              {_inputer(0,props,taskId)}
            </div>
            <div className="inputBox">
              {_inputer(1,props,taskId)}
            </div>
          </div>
          <div className="symbol">⨂</div>
          <div className="matrix matrix2">
            <div className="inputBox">
              {_inputer(2,props,taskId)}
            </div>
            <div className="inputBox">
              {_inputer(3,props,taskId)}
            </div>
          </div>
        </div>
        <div className="symbol">=</div>
        <div className="matrix matrix3">
          <div className="inputBox">
            {_inputer(4,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(5,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(6,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(7,props,taskId)}
          </div>
        </div>
        <div className="symbol">=</div>
        <div className="result">
          <div className="inputBox">
            {_inputer(8,props,taskId)}
          </div>
        </div>
      </div>
    }
    { taskId === '11_2' && 
      (<div className="task11_2_container"><div className="vectorMultiplication">
        <div className="leftInput">
          <span>|0&gt;<div className="symbol">⨂</div>|1&gt;<div className="symbol">⨂</div>|0&gt;</span>
        </div>
        <div className="symbol">=</div>
        <div className="midInput">
          <div className="matrix matrix1">
            <div className="inputBox">
              {_inputer(0,props,taskId)}
            </div>
            <div className="inputBox">
              {_inputer(1,props,taskId)}
            </div>
          </div>
          <div className="symbol">⨂</div>
          <div className="matrix matrix2">
            <div className="inputBox">
              {_inputer(2,props,taskId)}
            </div>
            <div className="inputBox">
              {_inputer(3,props,taskId)}
            </div>
          </div>
          <div className="symbol">⨂</div>
          <div className="matrix matrix2">
            <div className="inputBox">
              {_inputer(4,props,taskId)}
            </div>
            <div className="inputBox">
              {_inputer(5,props,taskId)}
            </div>
          </div>
        </div>
      </div>
      <div className="vectorMultiplication">
        <div className="symbol">=</div>
        <div className="matrix matrix4">
          <div className="inputBox">
            {_inputer(6,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(7,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(8,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(9,props,taskId)}
          </div>
        </div>
        <div className="symbol">⨂</div>
        <div className="matrix matrix2">
          <div className="inputBox">
            {_inputer(10,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(11,props,taskId)}
          </div>
        </div>
        <div className="symbol">=</div>
        <div className="matrix matrix8">
          <div className="inputBox">
            {_inputer(12,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(13,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(14,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(15,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(16,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(17,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(18,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(19,props,taskId)}
          </div>
        </div>
      </div>
      <div className="vectorMultiplication">
        <div className="symbol">=</div>
        <div className="result">
          <div className="inputBox">
            {_inputer(20,props,taskId)}
          </div>
        </div>
      </div>
      </div>
      )
    }
    {
      taskId === '11_3' && 
      (<div className="task11_3_container">
        <div className="vectorMultiplication">
          <div className="matrix matrix1 lefthand">
            <div className="inputBox">
              <input disabled value={0} />
              <input disabled value={1} />
            </div>
            <div className="inputBox">
              <input disabled value={1} />
              <input disabled value={0} />
            </div>
          </div>
          <div className="symbol">⨂</div>
          <div className="matrix matrix1 lefthand">
            <div className="inputBox">
              <input disabled value={1} />
              <input disabled value={1} />
            </div>
            <div className="inputBox">
              <input disabled value={1} />
              <input disabled value={-1} />
            </div>
          </div>
          <div className="symbol">=</div>
          <div className="midInput specialMidInput">
            <div className="matrixContainer">
              <div className="matrix">
                {/* 张量积系数 */}
                {_inputer(0,props,taskId,"coefficient coefficient1")}
                <div className="inputBox">
                  {_inputer(1,props,taskId)}
                  {_inputer(2,props,taskId)}
                </div>
                
                <div className="inputBox">
                  {_inputer(3,props,taskId)}
                  {_inputer(4,props,taskId)}
                </div>
              </div>
              <div className="matrix">
                {/* 张量积系数 */}
                {_inputer(5,props,taskId,"coefficient coefficient3")}
                <div className="inputBox aaaa">
                  {_inputer(6,props,taskId)}
                  {_inputer(7,props,taskId)}
                </div>
                
                <div className="inputBox">
                  {_inputer(8,props,taskId)}
                  {_inputer(9,props,taskId)}
                </div>
              </div>
            </div>
            <div className="matrixContainer">
              <div className="matrix">
                {/* 张量积系数 */}
                {_inputer(10,props,taskId,"coefficient coefficient2")}
                <div className="inputBox">
                  {_inputer(11,props,taskId)}
                  {_inputer(12,props,taskId)}
                </div>
                
                <div className="inputBox">
                  {_inputer(13,props,taskId)}
                  {_inputer(14,props,taskId)}
                </div>
              </div>
              <div className="matrix">
                {/* 张量积系数 */}
                {_inputer(15,props,taskId,"coefficient coefficient4")}
                <div className="inputBox">
                  {_inputer(16,props,taskId)}
                  {_inputer(17,props,taskId)}
                </div>
                
                <div className="inputBox">
                  {_inputer(18,props,taskId)}
                  {_inputer(19,props,taskId)}
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="vectorMultiplication vectorMultiplication2">
        <div className="symbol">=</div>
        <div className="matrix matrix4">
          <div className="inputBox">
            {_inputer(20,props,taskId)}
            {_inputer(21,props,taskId)}
            {_inputer(22,props,taskId)}
            {_inputer(23,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(24,props,taskId)}
            {_inputer(25,props,taskId)}
            {_inputer(26,props,taskId)}
            {_inputer(27,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(28,props,taskId)}
            {_inputer(29,props,taskId)}
            {_inputer(30,props,taskId)}
            {_inputer(31,props,taskId)}
          </div>
          <div className="inputBox">
            {_inputer(32,props,taskId)}
            {_inputer(33,props,taskId)}
            {_inputer(34,props,taskId)}
            {_inputer(35,props,taskId)}
          </div>
        </div>
      </div>
      </div>
      )
    }
  </div>
}

function _inputer(index,props,taskId,className) {
  const TaskItemData = props.TaskItemData
  return (<>
    <input className={classNames({"error":(TaskItemData['task-body-answer-status'] && TaskItemData['task-body-answer-status'][index]===false)},(className||''),`${index}-input`)}
        type="tel"  onChange={(e)=>updateData(index,props,taskId,e)} value={TaskItemData['taskBodyItemInput'][index]} />
  </>)
}
// 调用父级set更新全局函数
function updateData(index,props,taskId,event) {
  console.log(props);
  const TaskItemData = Object.assign(props.TaskItemData,{})
  console.log(JSON.stringify(TaskItemData.taskBodyItemInput));
  
  TaskItemData['taskBodyItemInput'][index] = event.target.value.trim()
  if (TaskItemData['task-body-answer-status']) {
    TaskItemData['task-body-answer-status'][index] = true
  }
  props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
}
export default withNamespaces()(Multiplication);