import * as React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom"
import classNames from "classnames";

import "./course.less";
function Course() {
  const location = useLocation()
  console.log(location.pathname);
  return (
    <div className={classNames({"course-detail-page":location.pathname.includes("/course/detail")},"course padding-wrap")} >
      <Outlet></Outlet>
    </div>
  );
}




export  {Course};