import { withNamespaces } from 'react-i18next';
import "./loading.less"
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { ReactComponent as LoadingIcon } from "./img/loading.svg"

function Loading(props){
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location);
  const t = props.t
  return <>
    <div className="edu-loading">
      <div className='edu-loading-wrap'>
        <LoadingIcon/>
      </div>
    </div>
  </>
}
// export {TaskList}
export default withNamespaces()(Loading);