import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import './Term.less'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

function UserAgreement(params) {
  let navigate = useNavigate();

  const t = params.t
  const lang = localStorage.getItem('globalLang') || 'cn'
  const pacttempCN = (<div className="pact">
  <div className="bigTitle">
    量子教育平台用户使用协议
  </div>
  <p>最后更新日期：2021年12月</p>
  <br />
  <p>欢迎您使用量旋科技量子教育平台（以下简称“本平台”）。</p>

  <div className="subTitle mt50">
    一、协议适用范围
  </div>
  <p className="textIndent2">
  量旋科技量子教育平台用户使用协议（以下简称“本协议”）适用于所有使用本平台的用户或使用者。量旋公司在此特别提醒用户认真阅读及充分理解本协议内容。您使用本平台，即表示您同意本协议；如果您不同意，请勿使用本平台。在法律允许范围内，量旋公司保留自行决定不定期变更本协议内容的权利，您有责任定期查看本协议的变更。如您在变更信息发布后继续使用本平台，即表示您同意且接受该变更。
  </p>
  <div className="subTitle mt50">
    二、知识产权声明
  </div>
  <p className="textIndent2">
  本平台包含的所有内容，包括但不限于文字、图片、音乐、视频、动画、商标、图案、图表、可视界面及计算机编码（“内容”），相关知识产权为量旋公司所有，或通过量旋公司获得合法授权而使用。除另有明确书面规定，未经量旋公司事先书面同意，您不得以任何方式为任何商业目的而使用本平台内容。     
  </p>
  <p className="textIndent2">
  本平台提供的关于量旋公司产品和服务的信息，您可进行转载，但不得用于商业目的，且不得对上述信息进行修改，及对上述信息及载有信息的文件进行进一步保证和陈述，且需要保留量旋公司合法权益的声明等。
  </p>
  <div className="subTitle mt50">
    三、使用规范
  </div>
  <p className="textIndent2">
  您不得为了任何非法或本协议中禁止的目的使用本网站；不得利用本平台及其内容侵犯其他组织或个人的合法权益。
  </p>
  <p className="textIndent2">
  您不可使用包括“深层链接”、“网页搜罗”、“网页机器人”、“网页采集工具”或其他自动设备、程序、算法或方法，或者任何类似或同等的手动流程来访问、获取、拷贝或监控本网站的任何组成部分或任何内容，结构或呈现形式，而通过本网站并未有意提供的任何手段获取或试图获取任何资料、文稿或信息。量旋公司对禁止任何此类活动的保留权利。您不可试图通过任何非法方式，未经授权地访问本网站的任何内容或使用本平台的任何服务。您不可向本网站发起任何形式的网络攻击。
  </p>
  <p className="textIndent2">
  您必须遵守任何和所有适用的国内和国际出口管制法律和限制的规定，包括有关目的地、最终用户和最终用途的任何限制。为了遵守任何该等出口管制法律和限制之目的，我们始终保留暂停、限制或终止您访问本平台或其任何部分的权利。
  </p>
  <p className="textIndent2">
  量旋公司始终保留相关的信息披露权利，以便为了遵守任何适用的法律、法规、法律程序或政府要求而披露必需的信息。当量旋公司认为适用的法律要求或允许此类披露时，量旋公司也可能会披露您的信息，包括出于欺诈保护目的与其他公司和组织交换信息。
  </p>
  <p className="textIndent2">
  您需确认并同意，量旋公司有权保留您通过本网站或通过本平台提供的任何服务与量旋公司之间进行的任何传输或通信，并可应法律要求必须或者量旋公司有足够的理由相信，才能合理地在以下情况下披露这些数据： (1) 遵守法律程序；(2) 执行本使用协议；(3) 对此类数据侵犯他人权利的主张作出回应；或者 (4) 保护量旋公司、其员工、本平台的用户或访客以及公众的权利、财产或个人安全。
  </p>
  <p className="textIndent2">
  如果量旋公司认为您违反了本协议或其他可能与本平台的使用相关的协议或准则，则可在不事先通知的情况下自行决定终止您对本网站的访问权限，并/或阻止您未来访问本网站的限制。您的任何违反本协议的行为将会构成非法及不正当商业行为，将会对量旋公司造成仅靠金钱赔偿难以弥补的损害，在这种情形下，量旋公司可以申请采取其认为必要或适当的禁令或等效的补救措施。这些补救措施是 量旋公司根据现行普通法或衡平法享有的任何补救措施的其他补充，在量旋公司享有的任何其他救济之外，量旋公司还将有权向您追讨相关款项，并且您须同意支付全部合理的律师费和诉讼费用。您还需同意，对于因违反本协议而导致您对本网站的访问权限被终止的情况，量旋公司对您或任何第三方均不承担任何责任。
  </p>
  
  <p className="subTitle mt50">
    四、不可抗力及第三方原因
  </p>
  <p className="textIndent2">
  量旋公司依照法律规定履行相关义务，但对于下述原因导致的协议履行障碍、履行瑕疵、履行延后或履行内容变更等情形，量旋公司并不承担违约责任：
  </p>
  <p className="textIndent2">
    (1)因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
  </p>
  <p className="textIndent2">
    (2)因电力供应故障、通讯网络故障等公共服务因素或任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足您要求的情形；
  </p>
  <p className="textIndent2">
    (3)在量旋公司已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
  </p>
  <p className="subTitle mt50">
    五、隐私
  </p>
  <p className="textIndent2">
    我们尊重并致力于保护您的隐私。有关我们保护您的个人信息的具体措施，请参见我们的<button className="href" onClick={()=>{navigate('/pact/PrivacyPolicy')}}>隐私政策</button>。
  </p>
  <p className="textIndent2">
    量旋公司的隐私声明适用于本网站的使用。使用本网站，即表示您确认并同意量旋公司可以根据此隐私声明使用此类信息。
  </p>
  <p className="subTitle mt50">
    六、免责声明
  </p>
  <p className="textIndent2">量旋公司不对间接、附带、特殊或任何形式的惩罚性赔偿承担任何责任，也不对任何利润、收入、数据、数据使用的损失承担任何责任。除非量旋公司在销售合同中另行书面同意，在适用法允许的范围内，量旋公司不对本网站的“资料”承担任何责任，不论该责任因何原因引起或者基于何种侵权理论。</p>
  <p className="subTitle mt50">
    八、适用法律与争议解决
  </p>
  <p className="textIndent2">您需充分理解与同意，您访问或使用本平台相关的所有事项，应根据中华人民共和国法律解释、理解和管辖。并且您也同意，中国广东省深圳市有管辖权的法院具有相关的管辖权。</p>
</div>)
  const pacttempEN = (<div className="pact">
  <div className="bigTitle">
    SpinQ CASTOR User Agreement
  </div>
  <p>Last updated: December 2021</p>
  <br/>
  <p>Welcome to SpinQ CASTOR, a quantum education platform (hereinafter referred to as “CASTOR”).</p>

  <div className="subTitle mt50">
    I. Scope
  </div>
  <p className="textIndent2">
  This User Agreement applies to SpinQ CASTOR (the "CASTOR"). SpinQ hereby reminds you to carefully read and understand this User Agreement. By using the CASTOR, you agree to this User Agreement; if you do not agree, do not use the CASTOR. To the extent permitted by law, SpinQ reserves the right, at its sole discretion, to change this User Agreement from time to time. It is your responsibility to check this User Agreement periodically for changes. Your continued use of the CASTOR following the posting of changes will mean that you accept and agree to the changes.
  </p>
  <div className="subTitle mt50">
    II.Intellectual Property Notice
  </div>
  <p className="textIndent2">
  All content contained on the CASTOR, including but not limited to text, graphics, music, videos, animations, trademarks, logos, tables, visual interfaces and computer code (collectively, "Content"), is owned by SpinQ or used by SpinQ with valid authorization. Except as expressly provided in writing in this User Agreement, no Content may be used in any way for any commercial purposes without SpinQ's prior written consent.
You may use information on SpinQ products and services made available by SpinQ for downloading from the CASTOR, provided that you use such information only for non-commercial purpose, make no modifications to any such information, make no additional representations or warranties relating to such information and documents containing such information, and retain SpinQ's legal rights notice.
  </p>
  <div className="subTitle mt50">
    III.Terms of Use
  </div>
  <p className="textIndent2">
  You may not use the CASTOR for any purpose that is unlawful or prohibited by this User Agreement, and may not use the CASTOR or any Content to infringe the rights of other entities or individuals.
  </p>
  <p className="textIndent2">
  You may not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the CASTOR or any Content, or the structure or presentation of the CASTOR, to obtain or attempt to obtain any materials, documents or information by any means not purposely made available through the CASTOR. SpinQ reserves the right to bar any such activity. You may not attempt to gain unauthorized access to any portion or feature of the CASTOR or to any of the services offered on or through the CASTOR by any illegitimate means. You may not launch any form of cyber attacks on the CASTOR.    </p>
  <p className="textIndent2">
  You must abide by any and all applicable Chinese and foreign export control laws and restrictions, including any restrictions on the destination, end users and end use. SpinQ reserves the right to suspend, restrict or terminate your access to all or any portion of the CASTOR for the purposes of abiding by such export control laws and restrictions.    </p>
  <p className="textIndent2">
  SpinQ reserves the right at all times to disclose any information that is necessary to comply with any applicable law, regulation, legal process or governmental request. SpinQ may also disclose your information when SpinQ determines that applicable law requires or permits such disclosure, including exchanging information with other companies and organizations for fraud protection purposes.    </p>
  <p className="textIndent2">
  You agree that SpinQ may, at its sole discretion and without prior notice, terminate your access to the CASTOR and/or block your future access to the CASTOR if SpinQ determines that you have violated this User Agreement or other agreements or guidelines which may be associated with your use of the CASTOR. You also agree that any of your violations of this User Agreement will constitute an unlawful and unfair business practice, and will cause irreparable harm to SpinQ, for which monetary damages would be inadequate, and you consent to SpinQ obtaining any injunctive or equitable relief that SpinQ deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies SpinQ may have at law or in equity. SpinQ will be entitled to recover from you, and you agree to pay, all reasonable attorneys' fees and costs of such action, in addition to any other relief granted to SpinQ. You agree that SpinQ will not be liable to you or to any third party for termination of your access to the CASTOR as a result of any violation of this User Agreement.    <p className="textIndent2">
  </p>
  <p className="subTitle mt50">
    IV.Force Majeure and Fault of Third Parties
  </p>
  <p className="textIndent2">
    SpinQ will perform obligations by law, but SpinQ will not be liable for breach in case of hindered performance, flaws in performance, delay in performance, or changes in obligations to be performed arising from:    
  </p>
  <p className="textIndent2">
  (1)Natural disasters, strikes, riots, wars, act of government, judicial or administrative orders, and other force majeure factors;
  </p>
  <p className="textIndent2">
  (2)Service interruption or disruption due to power supply failure, telecommunication network failure and other public service factors, or hacking, virus intrusion, blocking of illegal content and harassing information, government control and any other network, technology, communication lines, information security management measures, where your requirements cannot be met;
  </p>
  <p className="textIndent2">
  (3)Provided that SpinQ has exercised due care, typical or emergency equipment and system maintenance, equipment and system failures, network information and data security.
  </p>
  <p className="subTitle mt50">
    V.Privacy
  </p>
  <p className="textIndent2">
  SpinQ respects and is committed to your privacy. For information about how we protect your personal information, see the <button className="href" onClick={()=>{navigate('/pact/PrivacyPolicy')}}>Privacy Policy</button>.    
  </p>
  <p className="textIndent2">
  We (or third-party advertisers) may contact you for marketing purposes and send advertisements and promotion information to you under the Privacy Policy.
  </p>
  <p className="textIndent2">
  Please be noted that your connection to the CASTOR and data transmissions are never completely private or secure, and any message or information you send to the CASTOR may be intercepted by others. SpinQ cannot ensure that your connection to the CASTOR and data transmissions are private and secure.
  </p>
  <p className="subTitle mt50">
    VI.Limitation of Liability
  </p>
  <p className="textIndent2">
  Neither SpinQ nor any of its affiliates, subsidiaries, directors, agents, employees or other representatives shall be liable for any direct, indirect, special, incidental, consequential, punitive, and/or exemplary damages including without limitation, loss of profits or revenues, loss of data, and/or loss of business, in connection with the CASTOR or the use of or inability to use the CASTOR or reliance on the contents contained herein, even if SpinQ is advised of the possibility of such damages. Unless otherwise agreed in writing by SpinQ in any sales contract, to the extent permitted by the applicable law, SpinQ shall not be liable for the materials of the CASTOR, regardless of the reason of the liability or the tort theory it based on.
  </p>
  <p className="textIndent2">
    We (or third-party advertisers) may contact you for marketing purposes and send advertisements and promotion information to you under the Privacy Policy.
  </p>
  <p className="textIndent2">
    Please be noted that your connection to the Site and data transmissions are never completely private or secure, and any message or information you send to the Site may be intercepted by others. SpinQ cannot ensure that your connection to the Site and data transmissions are private and secure.    </p>
  <p className="subTitle mt50">
    VII.Applicable Law and Dispute Resolution
  </p>
  <p className="textIndent2">
  You agree that your access and all related activities on or through the CASTOR shall be governed by, construed, and interpreted in accordance with the laws of the People's Republic of China. You agree that any dispute between the parties arising out of or in connection with this User Agreement or your access and all related activities on or through the CASTOR shall be governed by a court with jurisdiction in Shenzhen, Guangdong Province of the People's Republic of China.
  </p>
  </p>
  </div>)
  console.log(lang === 'cn');
  useEffect(()=>{
    // alert("scroll")
    document.querySelector(".Pact-block").scrollTo(0,0)
  })
  return (
  <div className="term">
    <div className="nav-tit">
      <button className="backTo iconfont icon-icon-left-arr" onClick={()=>{
          callEquipmentByMessage('{"type":"routeChange","path":"/home"}',()=>{})
          }}><span>{t('global.back')}</span>
      </button>
    </div>
    {
      lang === 'cn'? pacttempCN : pacttempEN
    }
  </div>
  )
}
export default withNamespaces()(UserAgreement);
// export {UserAgreement}
