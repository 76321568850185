import React,{useState,useEffect} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./course.less"
import detailData from '../../data/CourseDetailData.json'
import { Button } from 'antd-mobile'
import imgSrc from './img/finishStar.png'
import callEquipmentByMessage from '../../utils/bridge'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { splitLocationSearch } from '../../utils/index'

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import classNames from "classnames";
let source = require('_/img/ppt/animation/PPT15-superconduct-max.mp4')
SwiperCore.use([Keyboard, Mousewheel]);
console.log(source);
function RenderCourseDetailImg(id,navigate,t) {
  let source = t('lang')==='cn'? require('_/img/ppt/animation/PPT15-superconduct-max.mp4'):require('_/img/enppt/animation/PPT15-superconduct-max.mp4')
  console.log(source);
  const NumberId = Number(id)
  const pptSourceList = t('lang')==='cn'?detailData.courseNameList[NumberId-1].pptSourceList:(detailData.courseNameList[NumberId-1].pptSourceListEN||detailData.courseNameList[NumberId-1].pptSourceList);
  console.log(pptSourceList);
  let courseDetailList = []
  for (let i = 0; i<pptSourceList.length; i++) {
    courseDetailList[i] = <SwiperSlide><div id={NumberId+'-'+i} key={NumberId+'-'+i} className="img-block">
      {
        pptSourceList[i] && pptSourceList[i].relativetask &&<div className={classNames({option:pptSourceList[i].relativetask.style==='option'},"linkToTask")} key={"link-button-"+i} onClick={()=>{navigate('/task/detail/'+pptSourceList[i].relativetask.link,false)}}>
          <div className="button-content">
            <div className="icon-wrapper">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" display="block" width="100%" height="100%" viewBox="0 0 38 37">
                <path fillRule="evenodd"
                  d="M30.954,36.459 L19.345,30.145 L7.801,36.577 L10.219,23.585 L0.534,14.594 L13.637,12.879 L19.195,0.889 L24.876,12.821 L37.996,14.402 L28.404,23.492 L30.954,36.459 Z"/>
              </svg>
            </div>
            {/* 选做题可能是不一样的文案，几道题一起命名 */}
            <div className="tips">{pptSourceList[i].relativetask.txt?t(pptSourceList[i].relativetask.txt):t('CourseList.exerciseInd',{ind:pptSourceList[i].relativetask.exercise})}</div>
          </div>
        </div>
      }
      
      <img src={pptSourceList[i].img} key={'/courseimgid-'+i} alt={'/courseimgid-'+i} />
      { pptSourceList[i].animations && 
        pptSourceList[i].animations.map(img=>{
          return <img className={`${img.replace('https://appsrc-cdn.edu.spinq.cn/eduWebImage/ppt/animation/','').replace('.gif','')} animation`} src={img} />
        }) 
      }
      { pptSourceList[i].videos && 
        pptSourceList[i].videos.map(video=>{
          return <video muted autoPlay={true} controls loop={true} width="800" height="400" className={`${video.replace('https://appsrc-cdn.edu.spinq.cn/eduWebImage/ppt/animation/','').replace('.mp4','')} animation`} >
            <source  src={source.default} type="video/mp4"></source>
          </video>
        }) 
      }
      </div></SwiperSlide>
  }
  const finishTemp = (<SwiperSlide><div className="finished">
    <div className="star">
      <img src={imgSrc} alt={imgSrc}/>
      <p>{t('CourseDetail.complete1')}</p>
      <p>{t('CourseDetail.complete2')}</p>
      <Button block color='primary' size='large' onClick={()=>{navigate(`/task/detail/${id}-${id}_1`,false)}}>{t('CourseDetail.startQuiz')}</Button>
    </div></div></SwiperSlide>)
    courseDetailList.push(finishTemp)
  return courseDetailList
}
function CourseDetail({t}) {
  callEquipmentByMessage('{"type":"tabChange","style":"black"}',()=>{})
  
  const {id} = useParams()
  const location = useLocation()

  // const currentRemUnit = Number(document.documentElement.style.fontSize.slice(0,-2));
  const [selfPageCounterList, setSelfPageCounterList] = useState([undefined])
  const [active, setactive] = useState(1)
  useEffect(() => {
    let isSubscribe = true
    
    // window.addEventListener("scroll",handleScroll)
    if (isSubscribe) {
      document.getElementById("course-detail").scrollTo(0,0)
      const pageSearchObj = splitLocationSearch(location.search) || {page:1}
      if (active!==Number(pageSearchObj.page||1)) {
        setactive(Number(pageSearchObj.page||1))
        // return
      }
      setLoadingTemp(<div id="loading-box"><div className="loading-content"><div className="inner-content">{t('CourseDetail.loading')}...<img src="./img/loading-swift.svg" alt="loading" className="rotateImg" /></div></div></div>)
      setTimeout(() => {
        const headNav = document.getElementById("fixBox")
        const classTitle = document.getElementById("nav-tit")
        const pptNavigate = document.getElementById("course-navigator")
        if (!headNav||!classTitle||!pptNavigate) return // 定时器可能导致离开页面之后获取不到dom
        setLoadingTemp(<div id="course-block" key="course-block">
          {/* change后location search参数应该随之变化 */}
          <Swiper className="mySwiper" initialSlide={Number(pageSearchObj.page-1||0)} keyboard={true} direction={"vertical"} mousewheel={true} onSlideChange={(e)=>{navigate(`/course/detail/${id}?page=`+(e.activeIndex+1),true);setactive(e.activeIndex+1)}}>
            {RenderCourseDetailImg(id,navigate,t)}
          </Swiper>
        </div>)
        console.log("headNav offset height = " + headNav.offsetHeight)
        console.log("classTitle offset height = " + classTitle.offsetHeight)
        console.log("pptNavigate offset height = " + pptNavigate.offsetHeight)
        const pageHeight = window.screen.height - headNav.offsetHeight - classTitle.offsetHeight - pptNavigate.offsetHeight
        const newPageHeightList = new Array(totalPageNum).fill(undefined).map((it,ind) => {
          return ind*(pageHeight + 20)
        })
        setSelfPageCounterList(newPageHeightList)
        // const imgBlockList = document.getElementsByClassName("img-block")
        // for (let i = 0; i < imgBlockList.length; i++) {
        //   imgBlockList[i].style.height = pageHeight + "px"
        // }
      }, 500);
    }
    return () => {
      isSubscribe = false
    }
  }, [id])
  let OriginNavigate = useNavigate();
  const navigate = (to,replace) => { OriginNavigate(to,{replace})}
  const COURSECOUNT = 16;
  const NumberId = Number(id)
  const pptSourceList = t('lang')==='cn'?detailData.courseNameList[NumberId-1].pptSourceList:(detailData.courseNameList[NumberId-1].pptSourceListEN||detailData.courseNameList[NumberId-1].pptSourceList)
  const totalPageNum = pptSourceList.length+1;
  const [loadingTemp, setLoadingTemp] = useState(<div id="loading-box"><div className="loading-content"><div className="inner-content">{t('CourseDetail.loading')}...<img src="./img/loading-swift.svg" alt="loading" className="rotateImg" /></div></div></div>)
  
  const leftPartTemp = detailData.courseNameList[NumberId-1] && `<div class="pre"> ${id > 1? `<div class="preWithArr"> ${t('CourseList.courseInd',{ind:NumberId-1})} ${t(detailData.courseNameList[NumberId-2].name)} <i class="iconfont icon-arrow"></i> </div>`:''} </div>`
  const rightPartTemp = detailData.courseNameList[NumberId] && `<div class="pre"> ${id < detailData.courseNameList.length? `<div class="afterWithArr ${COURSECOUNT<NumberId ?'readyToUpdate':''}">  ${t('CourseList.courseInd',{ind:NumberId+1})}  ${detailData.courseNameList[NumberId] && t(detailData.courseNameList[NumberId].name)}  <i class="iconfont icon-arrow"></i> </div>`:''} </div>`
  console.log(NumberId+1,detailData.courseNameList,id,detailData.courseNameList.length);
  const prev = id > 1 ? NumberId-1 : 0
  const next = id < detailData.courseNameList.length ? NumberId+1 : 0
  const currentClassName = ` ${t('CourseList.courseInd',{ind:NumberId})}  `+t(detailData.courseNameList[NumberId-1].name)
  console.log(next);
  return (
    <><div className="nav-tit" id="nav-tit">
      <div className="backTo iconfont icon-icon-left-arr" onClick={()=>{navigate(-1,true)}}><span>{t('global.back')}</span></div>
      {currentClassName}
    </div>
        <div className="course-detail" id="course-detail">
          {loadingTemp}
          <div id="course-navigator">
            <div className="pre" onClick={()=>{navigate(prev > 0 && prev <= (COURSECOUNT-1) && '/course/detail/'+prev,true)}} dangerouslySetInnerHTML={{__html:leftPartTemp}}></div>
            <div className="page-counter">{active} / {totalPageNum}</div>
            {
              next > 16 ? (<div className="next"></div>) : (<div className="next" onClick={()=>{ navigate(next <= COURSECOUNT && next > 0 &&'/course/detail/'+next,true)}} dangerouslySetInnerHTML={{__html:rightPartTemp}}></div>)
            }
          </div>
        </div>
      </>
  )
}
export default withNamespaces()(CourseDetail);