import classNames from "classnames";
function updateArithmeticTable(e,props,ind) {
  const val = e.target.value.trim()
  let newTask = props.TaskItemData
  let selfBodyInput = props.TaskItemData['taskBodyItemInput']
  selfBodyInput[ind] = val
  newTask['taskBodyItemInput'] = selfBodyInput
  if (newTask['task-body-answer-status']) {
    newTask['task-body-answer-status'][ind] = true
  }
  props.setTaskData(JSON.parse(JSON.stringify(newTask)))
}
function ArithmeticComponent(props) {
  const t = props.t
  if (props.TaskItemData.type !== 'Arithmetic') return <></>
  const selfBodyInput = props.TaskItemData['taskBodyItemInput']
  return <div className="ArithmeticComponent">
    <div className="ArithmeticLine">
      <div className="ArithmeticItem">
        <div className="line">
          1101110
        </div>
        <div className="line">
          1101
          <div className="symb">+</div>
        </div>
        <div className="result">
          <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][0]===false)})} value={selfBodyInput[0]} onChange={(e)=>{
                  updateArithmeticTable(e,props,0)
                }} />
        </div>
      </div>
      <div className="ArithmeticItem">
        <div className="line">
          1101110
        </div>
        <div className="line">
          1101
          <div className="symb">-</div>
        </div>
        <div className="result">
          <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][1]===false)})} value={selfBodyInput[1]} onChange={(e)=>{
                  updateArithmeticTable(e,props,1)
                }} />
        </div>
      </div>
    </div>
    <div className="ArithmeticLine">
      <div className="ArithmeticItem">
        <div className="line">
          1101
        </div>
        <div className="line">
          101
          <div className="symb multiplication">+</div>
        </div>
        <div className="result">
            <div className="line ignoreProcess">{t('taskData.header.omitTips')}</div>
        </div> 
        <div className="result">
          <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][2]===false)})} value={selfBodyInput[2]} onChange={(e)=>{
                  updateArithmeticTable(e,props,2)
                }} />
        </div>
      </div>
      <div className="ArithmeticItem diversionItem">
        <div className="result top">
          <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][3]===false)})} value={selfBodyInput[3]} onChange={(e)=>{
                  updateArithmeticTable(e,props,3)
                }} />
        </div>
        <div className="line left diversion">
          1101110
          <div className="symb">1101</div>
        </div>
        <div className="result">
          <div className="line ignoreProcess">{t('taskData.header.omitTips')}</div>
        </div> 
        <div className="result">
          <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][4]===false)})} value={selfBodyInput[4]} onChange={(e)=>{
                  updateArithmeticTable(e,props,4)
                }} />
        </div>
      </div>
    </div>
  </div>
}
export default ArithmeticComponent