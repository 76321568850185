// db.js
import Dexie from 'dexie';

export const db = new Dexie('spinq-gemini-database');
db.version(5.1).stores({
  /**
   * 主键为recordId 为userId--taskId
   * userid,用于标记当前用户
   * taskId为某一作业的id
   * taskInputData记录用户存储的作业信息
   * taskStatus 任务完成正确与否
   * IsCircuitErr 完成错误也要区分是填空部分错误还是线路错误
   */
  taskTable: 'recordId, userId, courseId, taskId, taskInputData, taskStatus, IsCircuitErr', // Primary key and indexed props
  courseTable: 'courseRecordId, userId, courseId, completeStatus, starCnName, starEnName, courseTitle, courseInfo'
});


window.getDBData = () => {
  db['taskTable'].toArray().then(res => {
    console.log(res);
  })
}