import { create, all, Complex } from 'mathjs'
const config = { }
const math = create(all, config)
console.log(math.complex("-i"));

export class GateExecutor {
  /**
   * bitNum即为模拟执行线路的比特个数，当初始状态存在时states需要传，
   * 否则默认以bitNum来创建state 以0为开始
   * @param bitNum 
   * @param states 
   */
  private bitNum:number;
  private states:any[] = []; // states中记录了每个态矢量的系数，可以为复数形式
  public statesStr:string[] = []; // 记录了当前states对应的态矢量
  /**
   * @param val 待转换数值
   * @param length 待转换长度
   * @return 返回值左位是高位
   */
  public intToBinaryString(val:number,length:number) {
    let arr:number[] = []
    
    for (let i = length - 1; i >= 0; i--) {
      arr.push(((val >> i) & 1) ? 1 : 0)
    }
    console.log(arr);
    return arr.join("")
  }

  public executeGateI(qubitOffset) {
    // 单位矩阵啥也不干
    return true;
  }

  public executeGateH(qubitOffset) {
    const n_states = this.states.length; // 状态个数
    const end = 1 << qubitOffset;
    const step = end << 1;
    for (let i = 0; i < n_states; i += step) {
      for (let j = 0; j < end; j++ ) {
        const m = i | j;  // 找到需要交换的位置
        const n = m | end; // 找到目标位置
        console.log(i,j,m,n);
        const tmp_state0 = this.states[m];
        const tmp_state1 = this.states[n];
        this.states[m] = math.divide(math.add(tmp_state0,tmp_state1),math.sqrt(2))
        console.log(tmp_state0,tmp_state1);
        this.states[n] = math.divide(math.subtract(tmp_state0,tmp_state1),math.sqrt(2))
      }
    }
  }

  public executeGateX(qubitOffset) {
    const n_states = this.states.length; // 状态个数
    const end = 1 << qubitOffset;  // TODO: 找交换的方式需要理解
    const step = end << 1; 
    console.log(n_states,end,step);
    
    for (let i = 0; i < n_states; i += step) {
      for (let j = 0; j < end; j++ ) {
        const m = i | j;  // 找到需要交换的位置
        const n = m | end; // 找到目标位置
        console.log(i,j,m,n);
        const tmp_state = this.states[m];
        this.states[m] = this.states[n]; // 交换两个态的系数
        this.states[n] = tmp_state;
      }
    }
  }

  public executeGateY(qubitOffset) {
    const n_states = this.states.length; // 状态个数
    const end = 1 << qubitOffset;
    const step = end << 1;
    for (let i = 0; i < n_states; i += step) {
      for (let j = 0; j < end; j++ ) {
        const m = i | j;  // 找到需要交换的位置
        const n = m | end; // 找到目标位置
        console.log(i,j,m,n);
        const tmp_state = this.states[m];
        this.states[m] = math.multiply(this.states[n],math.complex("-i")) ;
        this.states[n] = math.multiply(tmp_state,math.complex("i")) ;
      }
    }
  }

  public executeGateZ(qubitOffset) {
    const n_states = this.states.length; // 状态个数
    const end = 1 << qubitOffset;
    const step = end << 1;
    for (let i = 0; i < n_states; i += step) {
      for (let j = 0; j < end; j++ ) {
        const m = i | j;  // 找到需要交换的位置
        const n = m | end; // 找到目标位置
        this.states[n] = math.multiply(this.states[n],-1) ;
      }
    }
  }

  public executeGateCNOT(qubitControlOffset,qubitTargetOffset) {
    const min_qubit_offset = qubitControlOffset < qubitTargetOffset
                           ? qubitControlOffset : qubitTargetOffset;
    const max_qubit_offset = qubitControlOffset > qubitTargetOffset
                           ? qubitControlOffset : qubitTargetOffset;
    const end1 = this.states.length;
    const step1 = 1 << (max_qubit_offset + 1);
    const end2 = 1 << max_qubit_offset;
    const step2 = 1 << (min_qubit_offset + 1);
    const end3 = 1 << min_qubit_offset;
    for (let i = 0; i < end1; i+= step1) {
      for (let j = 0; j < end2; j+= step2) {
        for (let k = 0; k < end3; ++k) {
          let i0 = i + j + k;
          let i1 = i0 + (1 << qubitControlOffset)
          let i2 = i1 + (1 << qubitTargetOffset)
          const tmp = this.states[i1];
          this.states[i1] = this.states[i2];
          this.states[i2] = tmp;
        }
      }
    }
  }

  public executeGateZCON(qubitControlOffset,qubitTargetOffset) {
    const min_qubit_offset = qubitControlOffset < qubitTargetOffset
                           ? qubitControlOffset : qubitTargetOffset;
    const max_qubit_offset = qubitControlOffset > qubitTargetOffset
                           ? qubitControlOffset : qubitTargetOffset;
    const end1 = this.states.length;
    const step1 = 1 << (max_qubit_offset + 1);
    const end2 = 1 << max_qubit_offset;
    const step2 = 1 << (min_qubit_offset + 1);
    const end3 = 1 << min_qubit_offset;
    for (let i = 0; i < end1; i+= step1) {
      for (let j = 0; j < end2; j+= step2) {
        for (let k = 0; k < end3; ++k) {
          let i0 = i + j + k;
          let i1 = i0 + (1 << qubitControlOffset)
          let i2 = i1 + (1 << qubitTargetOffset)
          this.states[i2] = math.multiply(this.states[i2],-1)
        }
      }
    }
  }

  // 密度矩阵在模拟器中计算整个系统必然为末态的右矢乘左矢
  public computedDensityMatrix() {
    
  }

  private execute(Operations) {
    console.log(Operations);
    for (let i = 0; i < Operations.length; i++) {
      // 实际上应该是一个距离最大比特的偏差而不是，当前所在比特详情可见cpp文件中line1520 offsets
      let qubitOffset = this.bitNum - 1 - Operations[i].qubitIndex; 
      let controlQubitOffset = this.bitNum - 1 - Operations[i].controlQubit;
      let angle = Operations[i].angle;
      console.log(qubitOffset,controlQubitOffset,angle);
      
      switch (Operations[i].type) {
        case 'I':
          this.executeGateI(qubitOffset)
          break;
        case 'H':
          this.executeGateH(qubitOffset)
          break;
        case 'X':
          this.executeGateX(qubitOffset)
          break;
        case 'Y':
          this.executeGateY(qubitOffset)
          break;
        case 'Z':
          this.executeGateZ(qubitOffset)
          break;
        case 'CNOT':
          this.executeGateCNOT(controlQubitOffset,qubitOffset)
          break;
        case 'ZCON':
          this.executeGateZCON(controlQubitOffset,qubitOffset)
          break;
        default:
          break;
      }
    }
    console.log(this.states);
    let probility = this.states.map(state => {
      return math.pow(state,2)
    })
    let result = this.states.map(state => {
      let multiplyNum = state > 0 ? 1 : -1
      return math.multiply(math.pow(state,2),multiplyNum)
    })
    // model处理为概率，
    return {model:probility,result}
  }

  constructor(bitNum,states?) {
    if (bitNum <= 0) {
      console.error("invalid bitNum")
      return
    }
    this.bitNum = bitNum
    if (states) {
      this.states = states
    } else {
      this.states = new Array(Math.pow(2,bitNum)).fill(0)
      this.states[0] = 1
    }
    for (let i = 0; i < Math.pow(2,bitNum); i++) {
      let str = this.intToBinaryString(i,bitNum);
      this.statesStr.push(str)
    }
    console.log(this.statesStr);
  }
}