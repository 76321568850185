export default function callEquipmentByMessage(msg,callback) {
  // 相同消息不再发送
  if (JSON.parse(msg).type === 'tabChange' && window.lastMsg === msg) return
  window.lastMsg = msg
  // 发送给windows，通道应该只打开一次后保持复用

  if (window.qt && !window.qtui && window.qt.webChannelTransport) {
    new window.QWebChannel(window.qt.webChannelTransport, function (channel) {
      window.qtui = channel.objects.qtui;
    })
  } else if (window.qt && window.qtui && window.qt.webChannelTransport) {
    window.qtui.recieveJsMessage(msg,function (returnVal){ callback && callback(returnVal)});
  }

  // TODO: 消息发送给Android
  if (window.Android) {
    // 对于安卓我在CircuitBoardComponent中taskCallback暴露到了window下给安卓调用。不美观但是暂时想不到办法呢
    window.Android.postMessage(msg)
    // TODO:获取模拟结果并执行回调
  }

  if (!window.qt && !window.Android && JSON.parse(msg).type === 'task') {
    console.log(msg);
    callback &&callback(`{"result":[0.0000,0.0000,1.0000,0.0000],"model":[0.0000,0.0000,1.0000,0.0000],"resType":"selfType"}`)
  }
}