import { forwardRef, useState, useImperativeHandle, } from 'react';
import { DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom'
import {DeleteOutline} from  'antd-mobile-icons'
// import { ItemTypes } from './ItemTypes';
import Gate from './gate';
import classNames from 'classnames';
import {getPosition,getCoordinate} from '../../../../utils/gateUtil'



function selSubCont(position,props,parent,git) {
  if (git.gate.gname !== 'contForSel') return
  props.updateSubCont(parent,position.y)
}

const CircuitBoard = forwardRef(function CircuitBoard({ greedy, isOver, isOverCurrent, connectDropTarget, children, props }, ref) {
  //console.log(props);
  const {GATESIZE,TAGWIDTH,CIRCUITMAXX,CIRCUITMAXY} = props.PageRenderAttr

  const [hasDropped, setHasDropped] = useState(false);
  const [hasDroppedOnChild, setHasDroppedOnChild] = useState(false);
  // const bitsNum = props.circuitBoardInfo.bitsNum
  // const operations = props.operationsState
  //// //console.log(bitsNum)
  useImperativeHandle(ref, () => ({
    onDrop: (onChild) => {
      //console.log("setdrop");
      setHasDroppedOnChild(onChild);
      setHasDropped(true);
    }
  }), []);
  let widthAndHeight
  if (props.draggingArea) {
    const minXYInPixel = getPosition(props.draggingArea.minX,props.draggingArea.minY,GATESIZE,TAGWIDTH)
    const maxXYOBJ = getPosition(props.draggingArea.maxX-1,props.draggingArea.maxY-1,GATESIZE,TAGWIDTH)
    ////console.log(minXYInPixel,maxXYOBJ);
    const maxXYInPixel = {left:maxXYOBJ.left+GATESIZE*2,top:maxXYOBJ.top+GATESIZE*2}
    widthAndHeight = {width: maxXYInPixel.left - minXYInPixel.left + GATESIZE*4/3,height:maxXYInPixel.top - minXYInPixel.top + GATESIZE*4/3,left:minXYInPixel.left - GATESIZE *2/3,top:minXYInPixel.top - GATESIZE *2/3}
  }
  let functionWidthAndHeight
  if (props.functionArea) {
    const funcminXYInPixel = getPosition(props.functionArea.minX,props.functionArea.minY,GATESIZE,TAGWIDTH)
    const funcmaxXYOBJ = getPosition(props.functionArea.maxX-1,props.functionArea.maxY-1,GATESIZE,TAGWIDTH)
    // console.log(funcminXYInPixel,funcmaxXYOBJ);
    const funcmaxXYInPixel = {left:funcmaxXYOBJ.left+GATESIZE*2,top:funcmaxXYOBJ.top+GATESIZE*2}
    functionWidthAndHeight = {width: funcmaxXYInPixel.left - funcminXYInPixel.left + GATESIZE*4/3,height:funcmaxXYInPixel.top - funcminXYInPixel.top + GATESIZE*4/3,left:funcminXYInPixel.left - GATESIZE *2/3,top:funcminXYInPixel.top - GATESIZE *2/3}
  }
  let arrListStyle
  if (props.arrList) {
    arrListStyle = props.arrList.map(arit => {
      return { position: getPosition(arit.x,arit.y,GATESIZE,TAGWIDTH),tag:arit.tag}
    })
  }

  return connectDropTarget(<div className='circuitBoardContainer' id="circuitBoardContainer" key="circuitBoardContainer">
    <div className='tagContainer'>
      {props.circuitBoardInfo.tagList.map((bit, bitindex) => {
        return (
          <div className='tagContent' key={bitindex + 'line'}>
            {/* 这一侧应该是一个定位 */}
            <div className='tag'>
              {/* {["3_2","3_3","7_1"].includes(props.TaskItemData.taskId) && props.circuitBoardInfo.tagList[bitindex]}
              {!["3_2","3_3"].includes(props.TaskItemData.taskId) && <i className='tagDirac'>|0&gt;</i>} */}
              {props.circuitBoardInfo.tagList[bitindex]}
            </div>
          </div>
        )
      })}
    </div>
    <div className='lineContainer'>
      {props.circuitBoardInfo.tagList.map((bit, bitindex) => {
        return (
          <div className='lineContent' key={bitindex + 'line'}>
            {/* 这一侧应该是一个定位 */}
            <div className='line'>
                {new Array(CIRCUITMAXX-1).fill(undefined).map((it,dotind)=>{
                    return <i className='circuitDot' style={{left:GATESIZE+dotind*2*GATESIZE+'px'}} key={dotind+'-'+bitindex}></i>
                })}
            </div>
          </div>
        )
      })}
    </div>
    {/* 可拖拽区域绘制 */}
    {
    widthAndHeight && 
    <div className={classNames({showFxInDraggingArea:props.TaskItemData.showFxInDraggingArea},'draggingArea','draggingBox')} style={widthAndHeight}></div>
    }
    {/* 函数定义区域绘制 */}
    {
      functionWidthAndHeight &&
      <div className='draggingArea functionArea' style={functionWidthAndHeight}></div>
    }
    {/* 阶段变量定义 */}
    <div>
    {
      arrListStyle &&
      arrListStyle.map((styleItem,key) =>{
        return (<div className='arrContainer' key={key+'arrStyle'} style={{...styleItem.position,position:"absolute",width:GATESIZE+"px",height:GATESIZE+"px"}}>
          <div className='arrContent'>
            <i className='iconfont icon-sheng'></i>
            <div className='arrTagContainer' dangerouslySetInnerHTML={{__html:styleItem.tag}}></div>
          </div>
        </div>)
      })
    }
    </div>
    {
      props.operationsState && props.operationsState.map(git => {
        // console.log(git);
        if ( !git || !git.qubits ||git.timeSlot === -1 || git.qubits[0] === -1) return <></>
        const DrawLineHeight = Math.abs(git.qubits[1] - git.qubits[0]) * GATESIZE * 2 - GATESIZE / 2
        return (
          <div key={'gate' + git.timeSlot + '-' + git.qubits[0]} style={{ opacity: git.hidden ? 0 : 1 }}>
            <div onClick={()=>{selSubCont({ x: git.timeSlot, y: git.qubits[0] },props,git.parent,git)}} key={git.timeSlot + '-' + git.qubits[0]} className='gateWrap' style={{...getPosition(git.timeSlot, git.qubits[0],GATESIZE,TAGWIDTH),zIndex:(git.gate.gname==='contForSel')?101:2}}>
              <Gate 
                TaskItemData={props.TaskItemData}
                setTaskData={props.setTaskData} 
                updateTempOperation={props.updateTempOperation} 
                setIsDragging={props.setIsDragging} 
                onBoard={true} 
                position={{ x: git.timeSlot, y: git.qubits[0] }} 
                setOperationStateSnapShot={props.setOperationStateSnapShot} 
                operationsState={props.operationsState} 
                gateInfo={git.gate} key={git.gate.gname + 'onBoard' + git.timeSlot + '-' + git.qubits[0]}
                canDrag={git.canDrag}
                PageRenderAttr={props.PageRenderAttr}
              />
            </div>
            {git.gate.gtag === 'C2' && git.qubits[1] ?
              <>
                <div key={git.timeSlot + '-' + git.qubits[1]} className='gateWrap' style={getPosition(git.timeSlot, git.qubits[1],GATESIZE,TAGWIDTH)}>
                  <div className='verticalLine' style={{ height: DrawLineHeight + 'px', marginTop: git.qubits[1] > git.qubits[0] ? -DrawLineHeight : 0 }}></div>
                  <div className='contPlaceHolder'></div>
                </div>
              </>
              : <></>}
          </div>)
      })
    }
    {/* { props.isDragging==='deleted'?<div className='deletingFlag'><DeleteOutline style={{marginRight:'20px'}} />移除</div>:<></> } */}
  </div>);
});
// arg1: canDropItemType
export default DropTarget(['H', 'X', 'Y', 'Z', 'I', 'CNOT', 'ZCON'], {
  hover(props, monitor, component) {
    const {GATESIZE,TAGWIDTH,CIRCUITMAXX,CIRCUITMAXY} = props.PageRenderAttr

    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can use componentDidUpdate() to handle enter/leave.
    // You can access the coordinates if you need them
    // get parents rect
    const ParentDOM = document.querySelector('#circuitBoardContainer')
    //// //console.log(ParentDOM.getClientRects()[0]);
    const ParentCoordinate = ParentDOM.getClientRects()[0]
    const clientOffset = monitor.getClientOffset()
    console.log(monitor,ParentCoordinate,clientOffset);
    let gateSizeAfterScreenComputed = document.querySelector('.gate').getBoundingClientRect().width // 需要根据当前屏幕尺寸去获取最新的门标准单位宽
    console.log(gateSizeAfterScreenComputed);
    const coordinate = getCoordinate(clientOffset.x - ParentCoordinate.x, clientOffset.y - ParentCoordinate.y, gateSizeAfterScreenComputed, TAGWIDTH, CIRCUITMAXX, CIRCUITMAXY)
    console.log(`${coordinate.xCoordinate}`);
    // 在双比特连线之间的位置禁用后续步骤，TODO: 移动后drop不应该记录原本的位置
    const C2ConnectBits = []
    props.operationsState.map(it => {
      if (it.timeSlot === coordinate.xCoordinate && Math.abs(it.qubits[0]-it.qubits[1])>1 &&!it.draggingSource) {
        console.log(it);
        let gt = it.qubits[0] > it.qubits[1] ? it.qubits[0] : it.qubits[1]
        let lt = it.qubits[0] < it.qubits[1] ? it.qubits[0] : it.qubits[1]
        for (let i = lt+1; i < gt; i++) {
          C2ConnectBits.push(`${coordinate.xCoordinate}-${i}`)
        }
      }
      return true
    })
    // TODO: 不应该包括原来的元素
    if (C2ConnectBits.includes(`${coordinate.xCoordinate}-${coordinate.yCoordinate}`)) {
      console.log("forbidden");
      props.manualSetOperationState('self')
      return
    }
    // 不在可操作区域禁用后续步骤
    if (props.canDropList &&!props.canDropList.includes(`${coordinate.xCoordinate}-${coordinate.yCoordinate}`) && coordinate.xCoordinate!==-1 && coordinate.yCoordinate!==-1) {
        console.log("forbidden");
        props.manualSetOperationState('self')
        return
    }
    

    // 对于双比特门要判断控制为来限制drop。主要用于观察上下两个比特是否占位或者是否在线路边缘
    if (monitor.getItemType()==='ZCON'||monitor.getItemType()==='CNOT') {
        let forbidFlag = false
        let validAbove = false
        let validUnder = false
        ////console.log(coordinate);
        const NodeX = coordinate.xCoordinate
        const aboveNodeY = coordinate.yCoordinate - 1
        const underNodeY = coordinate.yCoordinate + 1
        if (coordinate.yCoordinate === 1) {
            validAbove = true
        }
        if (coordinate.yCoordinate === props.circuitBoardInfo.bitsNum) {
            validUnder = true
        }
        props.operationsState.map(op => {
          if (!validAbove) {
            // 判断如果在上面的位置存在已有门设置为true
            if ((aboveNodeY === op.qubits[0] || aboveNodeY === op.qubits[1]) && NodeX === op.timeSlot && !op.draggingSource) {
              validAbove = true
            }
          }
          if (!validUnder) {
            if ((underNodeY === op.qubits[0] || underNodeY === op.qubits[1]) && NodeX === op.timeSlot && !op.draggingSource) {
              validUnder = true
            }
          }
          return true
        })
        if (validAbove && validUnder) {forbidFlag = true}
        if (forbidFlag) {
          props.manualSetOperationState('self')
          return
        }
    }
    
    // 如果当前Hover位置存在逻辑门元素且不为临时节点或者被拖拽的节点，那就将原本的节点显示
    let canNotDropFlag = false
    let isDraggingSource = false
    props.operationsState.map(it => {
      if (it.timeSlot === coordinate.xCoordinate && (it.qubits[0] === coordinate.yCoordinate || it.qubits[1] === coordinate.yCoordinate) && it.gate.gtag !== 'tempNode') {
        canNotDropFlag = true
        //console.log(it);
        if (it.draggingSource) {
          isDraggingSource = true
        }
      }
      return true
    })
    // 如果为-1要显示删除按钮
    if (coordinate.xCoordinate===-1||coordinate.yCoordinate===-1) {
      props.setIsDragging('deleted')
      //console.log("setIsDragging");

    }

    else {
      //console.log("setIsDragging");

      props.setIsDragging('dragging')
    }
    ////console.log(isDraggingSource);
    if (!canNotDropFlag) {
      const newOperation = { timeSlot: coordinate.xCoordinate, gate: { gname: 'tempNode', gtag: 'tempNode' }, qubits: [coordinate.yCoordinate] }
      props.manualSetOperationState(newOperation)
    } else {
      // if (!isDraggingSource) {
      //   console.log("!isDraggingSource");
      //   props.manualSetOperationState(null)
      // } else {
        //console.log('self');
        props.manualSetOperationState('self')
      // }
    }
    // You can check whether we're over a nested drop target
    // const isOnlyThisOne = monitor.isOver({ shallow: true })

    // You will receive hover() even for items for which canDrop() is false
    // const canDrop = monitor.canDrop()
  },
  drop(props, monitor, component) {
    if (!component) {
      console.warn('out')
      return;
    }
    const hasDroppedOnChild = monitor.didDrop();
    if (hasDroppedOnChild && !props.greedy) {
      return;
    };

    component.onDrop(hasDroppedOnChild);
    // 移动过程必然出现临时节点，需要做的是在drop时将临时节点替换为drop元素

  },
  canDrop(props,monitor) {
    return false
    
  }
}, (connect, monitor, props) => ({
  props,
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(CircuitBoard);
