import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import classNames from 'classnames'
import CourseDetailData from '../../data/CourseDetailData.json'
import TaskDetailData from '../../data/TaskDetailData.json'
import "./App.css";
import './QuestionList.less'
import './star.less'
import {db} from '../../data/db'
import callEquipmentByMessage from '../../utils/bridge'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import ProNav from '../components/proNav'

function QuestionList({t}) {
  callEquipmentByMessage('{"type":"tabChange","style":"transparent"}')
  let navigate = useNavigate()
  const {courseId} = useParams()
  let course = initCourse()

  const [shiningStates, setShiningStatus] = useState([])
  const [backgroundStates, setBackgroundStatus] = useState({shining: false, active: false})
  const [newStates, setNewStatus] = useState([])  
 
  useEffect(()=>{
    async function fetchData() {
      // init star map status
      console.log("fetch");
      setShiningStatus([])
      // init new course
      course = await initCourse()
      updateShinng()
    }
    fetchData();
  },[courseId])

  function allSucceed(qStatusList) {
    for (let i = 0; i < qStatusList.length; i++) {
      if (qStatusList[i] !== TaskDetailData.taskStatus.succeed) {
        return false
      }
    }
    return true
  }

  function initCourse() {
    for (let i = 0; i < CourseDetailData.courseNameList.length; i++) {
      if (CourseDetailData.courseNameList[i]["id"]===Number(courseId)) {
        return CourseDetailData.courseNameList[i]
      }
    }
  }
  
  async function updateShinng () {
    console.log("update");
    const currentUserId = localStorage.getItem('userId') || 'guest'
    // Read latest status from DB
    const questionlist = await db['taskTable'].where({userId:currentUserId,courseId:courseId.toString()}).toArray();
    // console.log("questionlist = " + JSON.stringify(questionlist))
    const copyNewstatusList = new Array(course.requiredQnum).fill(TaskDetailData.taskStatus.undo)
    questionlist.map((q,qidx)=> {
      const taskId = q.taskId.split('_')[1]-1
      let searchLen = Number(courseId)===16?2:copyNewstatusList.length
      if (taskId < searchLen && (q.taskStatus === TaskDetailData.taskStatus.succeed || q.taskStatus === TaskDetailData.taskStatus.failed)) {
        if (Number(courseId)===16 && q.taskStatus!=='undo') {
          copyNewstatusList[0] = q.taskStatus
        } else {
          copyNewstatusList[taskId] = q.taskStatus
        }
      }
      return true
    })
    setNewStatus(copyNewstatusList)
    const allFinishNow = allSucceed(copyNewstatusList);
    console.log("copyNewstatusList = " + JSON.stringify(copyNewstatusList))
    
    // Compare new status with old status to refresh shining status
    let copyShiningStates = new Array(course.requiredQnum).fill(false)
    let copyBackgroundStates = {shining: false, active: false}

    const prevStatusKey = "prevStatus-" + currentUserId + "-" + courseId
    if (localStorage.getItem(prevStatusKey)) {
      // Read old status from local storage and compare
      let oldStatusList = localStorage.getItem(prevStatusKey).split("-")
      console.log("oldStatusList = " + JSON.stringify(oldStatusList))
      if (allSucceed(oldStatusList) && allFinishNow) {
        copyShiningStates = oldStatusList.map(it => {return true})
        copyBackgroundStates = {shining: false, active: true}
      } else {
        if (allFinishNow) {
          copyBackgroundStates = {shining: true, active: false}
        }
        for (let i = 0; i < copyNewstatusList.length; i++) {
          if (copyNewstatusList[i]===TaskDetailData.taskStatus.succeed) {
            copyShiningStates[i] = true
          } else {
            copyShiningStates[i] = false
          } 
        }
      }
    } else {
      for (let i = 0; i < copyNewstatusList.length; i++) {
        copyShiningStates[i] = copyNewstatusList[i]===TaskDetailData.taskStatus.succeed
      }
      if (allFinishNow) {
        copyBackgroundStates = {shining: false, active: true}
      }
    }
    console.log(shiningStates,copyShiningStates);
    setShiningStatus(copyShiningStates)
    setBackgroundStatus(copyBackgroundStates)
    localStorage.setItem(prevStatusKey, copyNewstatusList.join("-"))
    // if (JSON.stringify(shiningStates)!== JSON.stringify(copyShiningStates)) {
    //   console.log("setshinningstate")
    //   // save new status to local storage
    // }
  }

  async function gotoQuestion() {
    const currentUserId = localStorage.getItem('userId') || 'guest'
    let finishMap = new Array(course.requiredQnum + course.optionalQnum).fill(false)
    const questionlist = await db['taskTable'].where({userId:currentUserId,courseId:courseId.toString()}).toArray();
    questionlist.map((q,qidx)=> {
      const taskId = q.taskId.split('_')[1]-1
      if (taskId < finishMap.length && (q.taskStatus === TaskDetailData.taskStatus.succeed || q.taskStatus === TaskDetailData.taskStatus.failed)) {
        finishMap[taskId] = true
      }
      return true
    })
    for(let i = 0; i < finishMap.length; i++) {
      if (!finishMap[i]) {
        navigate('/task/detail/'+ courseId + '-' + courseId + '_' + (i+1))
        return
      }
    }
    navigate('/task/detail/'+ courseId + '-' + courseId + '_1')
  }

  function gotoNextCourse() {
    setShiningStatus([])
    setBackgroundStatus({shining: false, active: false})
    if (Number(courseId) === 16) {
      navigate('/task/questions/1')
    } else {
      const idx = Number(courseId)+1
      navigate('/task/questions/'+ idx)
    }
  }

  function gotoPrevCourse() {
    setShiningStatus([])
    setBackgroundStatus({shining: false, active: false})
    if (Number(courseId) === 1) {
      navigate('/task/questions/16')
    } else {
      const idx = Number(courseId)-1
      navigate('/task/questions/'+ idx)
    }
  }

  return (
    <>
      {/* <ProNav t={t}></ProNav> */}
      <div className="nav-tit question-nav" id="nav-tit">
        <div className="returnButton-wrapper">
          <div className="returnButton iconfont icon-icon-left-arr" onClick={()=>{navigate('/task/list')}}><span style={{marginLeft:"10px"}}>{t('global.back')}</span></div>
          <div className="title">{t('CourseList.courseInd',{ind: courseId})}&nbsp;{t(course.name)}</div>
        </div>
      </div>
      <div className="questionlist-wrapper" onClick={()=>{gotoQuestion()}}>
        <div className="star-map-wrapper">
          <div className={classNames("star-figure", {"active": backgroundStates.active, "shining": backgroundStates.shining})} id={"star-figure-"+courseId}></div>
          <div className="star-line" id={"star-line-"+courseId}>
            {<div className={classNames("shine-window", {"active": backgroundStates.active, "shining": backgroundStates.shining})} id={"shine-window-"+courseId}>
              <div className="shine"></div>
            </div>}
          </div>
          <div className={classNames("success-txt", {"active": backgroundStates.active, "shining": backgroundStates.shining})} id={"success-txt-course-"+courseId}>{t('CourseList.ChallengeSuccess')}</div>
          <>
            {
              shiningStates.map((qStatus,sind)=>{
                return <div key={sind+'-star'} className={classNames("star","course-"+courseId+"-star",{"shining": shiningStates[sind]})} id={"course-"+courseId+"-star-"+(sind+1)}></div>
              })
            }
          </>
        </div>
        <div className="star-label"  id={"star-label-"+courseId}>
          <span className={"cn-name"}>{course.starCnName.split("").join(" ")}</span>
          <span className={"en-name"}>{course.starEnName}</span>
        </div>
        <div className="tips">{t('CourseList.starMapTips')}</div>
      </div>
      

      <div className="sideButton left iconfont icon-arrow-left-bold" onClick={()=>{gotoPrevCourse()}}></div>
      <div className="sideButton right iconfont icon-arrow-right-bold" onClick={()=>{gotoNextCourse()}}></div>
    </>
  );
  
}
export default withNamespaces()(QuestionList);