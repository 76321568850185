import CircuitBoardComponent from "./circuitBoard/CircuitBoardComponent.js";
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import { t } from "i18next";

function DesignAndComputeCircuit(props,ref) {
  const childRef = useRef();
  const circuitData = props.TaskItemData['taskDataList']
  function setDesignCircuit(state) {
    console.log(state);
    const cpTaskItemData = JSON.parse(JSON.stringify(props.TaskItemData))
    cpTaskItemData['taskDataList'][0] = state
    props.setTaskData(cpTaskItemData)
  }
  function updateData(ind,e) {
    console.log(e.target.value);
    const TaskItemData = Object.assign(props.TaskItemData,{})
    TaskItemData['taskBodyItemInput'][ind] = e.target.value.trim()
    if (TaskItemData['task-body-answer-status']) {
      TaskItemData['task-body-answer-status'][ind] = true
    }
    props.setTaskData(JSON.parse(JSON.stringify(TaskItemData)))
  }
  useImperativeHandle(ref, () => ({
    validate:(callback) => {
      // 校验线路与填空正确后调用回调
      // 16-1先过滤掉所有输入非3位的答案，或者非法答案直接给错误
      const inputX = props.TaskItemData.taskBodyItemInput[0]
      if (props.TaskItemData.taskId==="16_1" && (inputX.length!==3 || (!(parseInt(inputX,2)<=7)))) {
        console.log(inputX.length,(!(parseInt(inputX,2)<=7)));
        callback(true)
        return
      }
      childRef.current.validate((isError)=>{
        console.log(isError);
        if (isError) {
          // 该线路异常
          callback(isError)
        } else {
          callback(isError)
        }
      });
    }
  }))
  console.log(CircuitBoardComponent);
  if (props.TaskItemData.type !== 'DesignAndComputeCircuit') return <></>
  console.log(props);
  // const table = props.TaskItemData['taskDataList'].map((Taskit,ind)=>{
    return (<div className="DesignAndComputeCircuit" key={'DesignAndComputeCircuit'}>
      <div className="left-part">
        {
          circuitData.map((cit,cind)=>{
            return <CircuitBoardComponent ref={childRef} key={"CircuitBoardComponent"+cind} TaskItemData={cit} BodyInputFor16={props.TaskItemData.taskBodyItemInput} setTaskData={setDesignCircuit} />
          })
        }
      </div>
      <div className="right-part">
        <div className="fill-blank">
          <div className="blank-list">
            {
              new Array(props.TaskItemData['taskBodyItemInput'].length).fill(null).map((it,ind) => {
                return (<div className="res-item" key={ind+"res"}>
                <div className="item-left">
                  <span className='tag'>
                    {
                      props.TaskItemData.taskId==="16_1" ?
                      <><span>{props.t('taskData.course16.exercise1.searchTips')}</span></>:
                      <><span>|φ</span><span className='minfont'>{ind}</span> &gt; =</>
                    }
                  </span>
                </div>
                <div className="item-right">
                  <input type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&props.TaskItemData['task-body-answer-status'][ind]===false)})} value={props.TaskItemData['taskBodyItemInput'][ind]} onChange={(e)=>updateData(ind,e)} />
                </div>
              </div>)
              })
            }
          </div>
        </div>
      </div>
    </div>)
  // })
  // return table
}
export default forwardRef(DesignAndComputeCircuit)