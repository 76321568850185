import React from "react";


function NativeBridge(params) {
  
  
  // TODO: 更新数据，本页用于接收参数，仅为了隔离业务，native的参数必须在登录后打开本页注册，但实际函数均捆绑在根组件下暴露到window
  return (
    <>
    <div id="NativeBridge">
    NativeBridge
    </div>
    </>
  )
}
export {NativeBridge}