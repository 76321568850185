import src1 from '../pages/task/img/QuestionList/starFigure/1-shine.png'
import src2 from '../pages/task/img/QuestionList/starFigure/2-shine.png'
import src3 from '../pages/task/img/QuestionList/starFigure/3-shine.png'
import src4 from '../pages/task/img/QuestionList/starFigure/4-shine.png'
import src5 from '../pages/task/img/QuestionList/starFigure/5-shine.png'
import src6 from '../pages/task/img/QuestionList/starFigure/6-shine.png'
import src7 from '../pages/task/img/QuestionList/starFigure/7-shine.png'
import src8 from '../pages/task/img/QuestionList/starFigure/8-shine.png'
import src9 from '../pages/task/img/QuestionList/starFigure/9-shine.png'
import src10 from '../pages/task/img/QuestionList/starFigure/10-shine.png'
import src11 from '../pages/task/img/QuestionList/starFigure/11-shine.png'
import src12 from '../pages/task/img/QuestionList/starFigure/12-shine.png'
import src13 from '../pages/task/img/QuestionList/starFigure/13-shine.png'
import src14 from '../pages/task/img/QuestionList/starFigure/14-shine.png'
import src15 from '../pages/task/img/QuestionList/starFigure/15-shine.png'
import src16 from '../pages/task/img/QuestionList/starFigure/16-shine.png'
import backgroundImage from '../pages/task/img/TaskList/background.jpg'
(function () {
  function preLoadGalxyImg() {
    console.log(1);
    let images = [
      backgroundImage,
      src1,
      src2,
      src3,
      src4,
      src5,
      src6,
      src7,
      src8,
      src9,
      src10,
      src11,
      src12,
      src13,
      src14,
      src15,
      src16
    ];

    let image = [];
    let index = 0;

    for (const key in images) {
      // 计数器
      index += 1;
      // 创建image对象
      image[index] = new Image();
      // 把图片地址赋值
      if (images.hasOwnProperty(key)) {
        console.log(images[key]);
        const img = images[key];
        image[index].src = img;
      }
      image[index].onload = () => {
        console.log('loaddddd');
      }
    }
  }
  setTimeout(() => {
    window.requestIdleCallback(preLoadGalxyImg)
  }, 2000);
})()