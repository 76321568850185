import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import './Term.less'
import { BankcardOutline } from "antd-mobile-icons";
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

function Term(params) {
  const navigate = useNavigate();
  const t = params.t
  const lang = localStorage.getItem('globalLang') || 'cn'
  const termCN = (
    <div className="pact">
        <div className="bigTitle">
          隐私政策
        </div>
        <p>最后更新日期：2021年12月</p>
        <br />
        <p className="textIndent2">量旋科技及其全国关联的分部或办事处（下文简称“量旋科技”、“我们”和“我们的”）深知隐私对您的重要性，并会尊重您的隐私。请在向量旋科技提交个人信息之前，阅读、了解本《隐私政策》（下文简称“本政策”）。本政策适用于显示本隐私政策、或链接至本隐私政策的量旋科技的网站和产品、服务。特别的，本网站由深圳量旋科技有限公司运营，量旋科技是该产品或服务所收集的个人信息的个人信息处理者。</p>
        <p className="textIndent2">本政策阐述了量旋科技如何处理您的个人信息，但本政策可能并不涉及所有可能的数据处理情境。有关收集产品或服务特定数据的信息可能由量旋科技在特定产品或服务发布的专门隐私通知或补充声明中阐述，或者在收集数据时提供的通知中发布。</p>

        <div className="subTitle mt50">
          1. 如何收集和使用您的个人信息
        </div>
        <p className="subTitle textIndent2">
              1.1. 如何收集您的个人信息
        </p>
        <p className="textIndent2">
          个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。您在使用量旋科技产品和服务或者与我们互动时，我们可能会收集您的个人信息。根据您使用的产品和服务以及您与我们互动的不同，具体收集的数据类型也将有所不同。您并非必须向量旋科技提供个人信息，但在某些情况下，如果您选择不提供，量旋科技可能无法为您提供相关产品或服务，也无法回应或解决您所遇到的问题。量旋科技仅会出于本声明所述目的收集和使用您的个人信息，我们可能收集的个人信息包括：
        </p>
        <p className="textIndent2">
              1.1.1. 您向量旋科技提供的信息
        </p>
        <p className="textIndent2">
              个人联系信息。当您联系我们，给我们提供意见、建议或询问，参与我们的线上、线下活动，订阅营销资料等时，您可以留下您的联系信息，包括您的姓名、公司名称、职位、邮箱、电话等。
        </p>
        <p className="textIndent2">
              1.1.2.在您与我们互动或使用我们的产品和服务过程中获取的信息
        </p>
        <p className="textIndent2">
              （1）设备及网络信息。我们会收集您在使用量旋科技产品和服务时设备的相关信息，例如设备类型、设备名称或型号、设备识别符、操作系统类型及版本、浏览器类型和版本、屏幕分辨率、语言设置、IP地址、网络服务提供商等，以及因产品而异的其他技术信息。
        </p>
        <p className="textIndent2">
              （2）互动记录信息。此类信息将会在您使用我们的网站、产品或服务，以及与我们互动时由您直接提交给我们。例如您提供给我们的信息请求，包括您输入的查询信息或您为了获得客服支持而提供的问题或信息等。您在线或通过电话、邮件、参加线上线下活动的沟通记录等。 
        </p>
        <p className="textIndent2">
              （3）日志信息。当您使用量旋科技的产品和服务或者查看由量旋科技提供的内容时，量旋科技会自动收集并存储必要的日志信息，如服务的每次访问时间、访问的页面路径、客户端 IP地址、事件信息（如错误、崩溃、重启、升级）等。
        </p>
        <p className="textIndent2">
              1.1.3.第三方来源信息及公开信息
        </p>
        <p className="textIndent2">
              在法律允许的情况下，我们还会从公用和商用第三方或公开来源获取有关数据，例如，我们通过从其他公司购买统计数据来支持我们的服务。 IP地址、事件信息（如错误、崩溃、重启、升级）等。
        </p>
        <p className="textIndent2">
              量旋科技还可能收集和使用非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，量旋科技会收集汇总的统计数据，例如网站访问量。量旋科技收集此数据的目的在于了解用户如何使用自己的网站、产品和服务。借此，量旋科技可以改善自己的服务，更好地满足客户需求。量旋科技可能会自行决定出于其他目的收集、使用、处理、转移或披露非识别性数据。我们会尽力隔离您的个人信息和非识别性数据，并单独使用这两种数据。如果个人信息掺杂了非识别性数据，依旧会被视作个人信息处理。   
        </p>
        <p className="subTitle textIndent2 mt50">
              1.2.如何使用您的个人信息
        </p>
        <p className="textIndent2">
          我们可能将您的个人信息用于以下目的：
        </p>
        <p className="textIndent2">
          （1）响应您的互动要求。当您访问我们的网站，参加线上或线下活动，要求进行变更或者要求提供您请求的信息（例如产品或服务的营销资料、白皮书、订阅电子杂志），以及就任何问题或产品信息与我们联系时（例如提供技术支持，向我们提供意见、建议、询问等），我们会收集您的如下信息：
        </p>
        <table className="policyTable cookieTable mt50" border="0" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th>数据项</th>
              <th>用途</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>联系信息</td>
              <td>您提供的姓名、邮箱、电话、地址、公司名称、职位等</td>
              <td>用于与您沟通，如向您发送您订阅的电子杂志，回复您对我们的问询、意见或建议，或向您寄送活动礼品等</td>
            </tr>
          </tbody>
        </table>
        <p className="textIndent2 mt50">
          （2）为您推荐相关内容、产品或服务。在您同意的情况下，向您发送有关您可能感兴趣的产品和服务的信息；邀请您参与市场调查或满意度调查；或在您访问网站、APP等线上平台时为您提供个性化的内容及服务。我们会收集您的如下信息：
        </p>
        <table className="policyTable cookieTable mt50" border="0" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th>数据项</th>
              <th>用途</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>联系信息</td>
              <td>您提供的姓名、邮箱、电话、公司名称、职位、所属行业等</td>
              <td>用于与您沟通，如向您发送您订阅的电子杂志，回复您对我们的问询、意见或建议，或向您寄送活动礼品等</td>
            </tr>
            <tr>
              <td>设备及网络信息</td>
              <td>设备类型、设备名称或型号、设备识别符、操作系统类型及版本、浏览器类型和版本、屏幕分辨率、语言设置、IP地址、网络服务提供商等</td>
              <td>用于汇总分析网站、APP等线上平台的访问效果及统计数据，以便持续优化您的使用体验</td>
            </tr>
          </tbody>
        </table>
        <p className="textIndent2 mt50">
          （3）其他情况：
        </p>
        <p className="subList textIndent2">
          在您选择向我们发送错误详情的情况下排查错误。
        </p>
        <p className="subList textIndent2">
          保护我们产品、服务和客户或用户的安全，执行与改善我们的防损和反欺诈计划。
        </p>
        <p className="subList textIndent2">
          遵从和执行适用的法律要求，相关的行业标准或我们的政策。
        </p>
        <p className="textIndent2">
          基于以上几个目的，我们可能向您采集以下相关信息：
        </p>
        <table className="policyTable cookieTable mt50" border="0" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th></th>
              <th>数据项</th>
              <th>用途</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>日志信息</td>
              <td>服务的请求时间、请求协议类型、客户端IP地址、请求成功的状态、请求的资源路径、客户端设备信息（如设备类型、操作系统类型及版本）等</td>
              <td>服务的请求时间、请求协议类型、客户端IP地址、请求成功的状态、请求的资源路径、客户端设备信息（如设备类型、操作系统类型及版本）等</td>
            </tr>
          </tbody>
        </table>
        <div className="subTitle mt50">
          2.如何使用 Cookie 和同类技术
        </div>
        <p className="subTitle textIndent2">
              2.1. Cookie
        </p>
        <p className="textIndent2">
    为确保网站正常运转，我们有时会在计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie 的内容只能由创建它的服务器检索或读取。每个 Cookie 对您的网络浏览器或移动应用程序都是唯一的。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储用户偏好并有助我们改善产品或服务。
    您还可以点击查看Cookie政策，了解更多详细内容。    </p>
        <p className="subTitle textIndent2 mt50">
          2.2.网站信标和像素标签
        </p>
        <p className="textIndent2">
          除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，量旋科技向您发送的电子邮件可能含有链接至量旋科技的产品内容的点击 URL。如果您点击该链接，量旋科技则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从量旋科技的寄信名单中退订。
    您使用我们的网站意味着您同意按照如上所述使用Cookie，网站信标和像素标签。
        </p>
        <div className="subTitle mt50">
          3.如何披露您的个人信息
        </div>
        <p className="textIndent2">
          通常量旋科技不会对外披露您的个人信息，但以下场景除外：
        </p>
        <p className="textIndent2">
          （1）在某些服务由量旋科技的授权合作伙伴提供的情况下，量旋科技会如本政策描述与该合作伙伴共享您的个人信息。例如，委托合作伙伴代表量旋科技处理热线、发送电子邮件、提供技术支持等，我们会要求合作伙伴仅能将您的个人信息用于代表量旋科技向您提供服务；
        </p>
        <p className="textIndent2">
          （2）在适用的法律要求或响应法律程序的情况下，量旋科技也可能会向相关的执法机关或者其他政府机关披露您的个人信息。在某些管辖区，如果量旋科技牵涉到重组、合并或破产和清理诉讼，那么您的个人信息还可能会被披露给交易方。
        </p>
        <p className="textIndent2">
          （3）量旋科技还会在存在合理需求的情况下披露您的数据，例如出于执行合同以及我们认为为阻止身体损害或财产损失或调查可能的或实际的非法行为有必要披露且披露是适当的。
        </p>
        
        <p className="subTitle mt50">
          4.您在个人信息处理活动中的权利
        </p>
        <p className="textIndent2">
          您应确保提交的所有个人信息都准确无误。量旋科技会尽力维护个人信息的准确和完整，并及时更新这些数据。    
        </p>
        <p className="textIndent2">
          当适用的法律要求的情况下，您可能（1）有权查阅、复制我们持有的关于您的特定的个人信息；（2）要求我们更新或更正您的不准确的个人信息；（3）拒绝或限制我们使用您的个人信息；（4）要求我们删除您的个人信息。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。
        </p>
        <p className="textIndent2">
          当适用的法律要求的情况下，当量旋科技基于您的同意处理您的个人信息时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人信息的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人信息。
        </p>
        <p className="subTitle mt50">
          5.如何保护和留存您的个人信息
        </p>
        <p className="textIndent2">
          量旋科技重视个人信息的安全。我们采用适当的物理、管理和技术保障措施来保护您的个人信息不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；我们会尽力保护您的个人信息，但是请注意任何安全措施都无法做到无懈可击。
        </p>
        <p className="textIndent2">
          我们将会在达成本政策所述目的所需的期限内保留您的个人信息，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人信息的时间，包括提供产品和服务，管控并提升产品与服务性能与质量，保证系统、产品和服务的安全，应对可能的用户查询或投诉，问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。只要您的账户是为您提供服务必须，我们都将保留您的注册信息。您也可以选择注销您的账号，在您注销账号后，我们会停止基于该账号提供产品和服务，并在无特殊法律要求的情况下，删除您相应的个人信息或对其进行匿名化处理。
        </p>
        <p className="subTitle mt50">
          6.第三方提供商及其服务
        </p>
        <p className="textIndent2">
          为确保流畅的浏览体验，您可能会收到来自量旋科技及其合作伙伴外部的第三方（下文简称“第三方”）提供的内容或网络链接。量旋科技对此类第三方无控制权。您可选择是否访问第三方提供的链接、内容、产品和服务。
        </p>
        <p className="textIndent2">
          量旋科技无法控制第三方的隐私和数据保护政策，此类第三方不受到本政策的约束。在向第三方提交个人信息之前，请参见这些第三方的隐私保护政策。
        </p>
        <p className="subTitle mt50">
          7.本政策如何更新
        </p>
        <p className="textIndent2">量旋科技保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知，例如，在我们的网站上发布通知或者向您发布单独的通知。</p>
        <p className="subTitle mt50">
          8.如何联系量旋科技
        </p>
        <p className="textIndent2">如果您有任何隐私投诉或问题要联系量旋科技，请拨打公司联系电话，或发送邮件至指定邮箱地址。一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
      </div>
  )
  const termEN = (
    <div className="pact">
      <div className="bigTitle">
        Privacy Policy
      </div>
      <p>Last updated: December 2021</p>
      <br/>
      <p>SpinQ Technology Co., Ltd. and its affiliates (collectively, "SpinQ", "we", "us", and "our") respect your privacy. Please read the following to learn more about our Privacy Policy ("this Policy"). This Policy applies to SpinQ websites, products, and services that display or provide links to this Policy.</p>
      <p>This Policy describes how SpinQ processes your personal data, but it may not address all possible data processing scenarios. SpinQ may inform you of product- or service-specific data collection through supplementary policies or notices provided before collection.</p>

      <div className="subTitle mt50">
        1.How We Collect & Use Your Personal Data
      </div>
      <p className="textIndent2">
        Personal data means any data that, either on its own or jointly with other data, can be used to identify a natural person. You directly provide us with such data when you use our websites, products, or services, or interact with us by, for example, creating a SpinQ account or contacting us for support. We may also obtain data by recording how you interact with our websites, products, or services. For example, we may use technologies like cookies or receive use data from software running on your device. As permitted by law, we may also obtain data from public and commercial third-party sources, for example, purchasing statistics from other companies to support our services. The personal data we collect includes name, gender, enterprise name, job position, postal and email addresses, phone number, photos, and certificate information, etc., depending on how you interact with SpinQ, for example, the website you visit or the products and services that you use. We also collect the information you provide to us and the content of messages you send us, such as the query information you provide, or the questions or information you provide for customer service support.
      </p>
      <p className="textIndent2">
        Before using SpinQ’s products or services, you may need to provide personal data.  In some cases you may be able to opt not to disclose your personal data to SpinQ. However, not providing SpinQ with certain data may mean that we cannot provide you with certain products or services or respond to an issue that you have raised.    </p>
      <p className="textIndent2">
            We may use your personal data for the following purposes:
      </p>
      <p className="subList">
        Fulfilling your transaction or service requests, including fulfilling orders; delivering, activating, or verifying products or services; providing training and certification; managing and processing training and certification exams; participating in onsite or virtual activities; fulfilling your requests for changes or providing you with the requested information (such as marketing materials for products and services, and white papers); and providing technical support.
      </p>
      <p className="subList">
        Contacting you with your consent; sending you information about products and services that may interest you; inviting you to participate in SpinQ activities (including promotional activities), market surveys, or satisfaction surveys; or sending you marketing information. If you do not want to receive these types of information, you can opt out at any time.
      </p>
      <p className="subList">
        Sending you important notices, such as installation of and updates to operating system or application.
      </p>
      <p className="subList">
        Providing you with customized user experience and content.
      </p>
      <p className="subList">
        Qualifying and managing suppliers and business partners, and communicating or working with suppliers and business partners.
      </p>
      <p className="subList">
        Improving our products and services through internal audits, data analysis, and research.
      </p>
      <p className="subList">
        Analyzing the efficiency of our business operations and evaluating market share.
      </p>
      <p className="subList">
        Troubleshooting when you send us error reports.
      </p>
      <p className="subList">
        Synchronizing, sharing, and storing the data you upload or download and the data needed for the uploading and downloading.
      </p>
      <p className="subList">
        Ensuring the security of our products, services and customers or users, executing and improving our loss prevention and anti-fraud programs.
      </p>
      <p className="subList">
        Complying with and enforcing applicable legal requirements, industry standards and our policies.
      </p>
      <p className="textIndent2">
          SpinQ may also collect and use non-personally identifiable information (Non-PII). Non-PII is information that cannot be used to identify a particular individual. For example, SpinQ will collect statistical data, such as the numbers of visits to its website. We collect this data to understand how users use our websites, products, and services so that we can improve our services and better satisfy your needs. SpinQ may collect, use, process, transfer, or disclose non-PII for other purposes at its own discretion.
      </p>
      <p className="textIndent2">
        We will endeavor to isolate your personal data from non-PII and ensure that the two types of data are used separately. If personal data is combined with non-PII, it will still be treated as personal data during processing.
      </p>
      <p className="textIndent2">
        SpinQ will process your personal data following the requirements of applicable laws on an appropriate legal basis, including:
      </p>
      <p className="subList">
        Processing your personal data to fulfill the contract when responding to a transaction or service request;
      </p>
      <p className="subList">
        Processing your personal data with your consent;
      </p>
      <p className="subList">
        Processing based on the legitimate interests of SpinQ or a third party when we use your personal data to contact you, conduct marketing or market surveys, improve our products and services, execute and improve our loss prevention and anti-fraud programs, and other purposes. Legitimate interests include enabling us to more effectively manage and operate our business and provide our products and services; protecting the security of our businesses, systems, products, services, and customers; internal management; complying with internal policies and processes; and other legitimate interests described in this policy;
      </p>
      <p className="subList">
        Processing your personal data as necessary to comply with and fulfill legal obligations.
      </p>
      <div className="subTitle mt50">
        2.Cookies & Similar Technologies
      </div>
      <p className="subTitle">
        2.1.Cookie
      </p>
      <p className="textIndent2">
            To ensure our website works correctly, we may at times place a small piece of data known as a cookie on your computer or mobile device. A cookie is a text file stored by a web server on a computer or mobile device. The content of a cookie can be retrieved or read only by the server that creates the cookie. The text in a cookie often consists of identifiers, site names, and some numbers and characters. Cookies are unique to the browsers or mobile applications you use, and enable websites to store user preferences and help us improve products or services.
  You can also click to view the cookie policy  for more details.
      </p>
      <p className="subTitle mt50">
        2.2.Web Beacons and Pixel Tags
      </p>
      <p className="textIndent2">
            In addition to cookies, we may also use other similar technologies on our websites such as web beacons and pixel tags. For example, when you receive an email from SpinQ, it may contain a click-through URL that links to a SpinQ web page. If you click the link, SpinQ will track your visit to help us learn about your preferences for products and services and improve our customer service. A web beacon is a transparent graphic image embedded in a website or in an email. We use pixel tags in emails to find out whether an email has been opened. You can unsubscribe from the SpinQ mailing list at any time if you do not want to be tracked in this manner.
      </p>
      <div className="subTitle mt50">
        3.How We Disclose Personal Data
      </div>
      <p className="textIndent2">
        SpinQ shares your personal data with other partners, as described in this Policy, when services are provided by partners authorized by SpinQ. For example, when you make an online purchase from SpinQ, we must share your personal data with the logistics provider to arrange shipment or a partner to provide services. In addition, as a global company, we may share personal data with SpinQ affiliates and subsidiaries.    </p>
      <p className="textIndent2">
        To comply with applicable laws or respond to valid legal procedures, SpinQ may also disclose your personal data to law enforcement or other government agencies. If SpinQ is involved in a restructuring, merger & acquisition, or a bankruptcy or liquidation lawsuit in a given jurisdiction, your personal data may be disclosed in connection with the transaction. SpinQ may also disclose your data when appropriate, for example, to execute Terms and Conditions, when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, or when it is in connection with an investigation of suspected or actual illegal activity.    </p>
      <p className="subTitle mt50">
        4.How to Access & Control Your Personal Data
      </p>
      <p className="textIndent2">
        It is your responsibility to ensure that all personal data submitted to SpinQ is correct. SpinQ is dedicated to maintaining the accuracy and completeness of personal data and keeping the data up-to-date.
      </p>
      <p className="textIndent2">
        To the extent required by applicable law, you may (i) have the right to access certain personal data we maintain about you, (ii) request that we update or correct inaccuracies in that data, (iii) object or restrict to our use of your personal data, and (iv) ask us to delete your personal data from our database. To exercise these rights, please click here to give your feedback online. Your written request may be required for security. We may decline the request if we have reasonable grounds to believe that the request is a fraudulent, unfeasible or may jeopardize the privacy of others.    </p>
      <p className="textIndent2">
        If allowed by applicable laws, you have the right to withdraw your consent at any time when SpinQ processes your personal data based on your consent. However, withdrawal does not affect the legitimacy and effectiveness of how we process your personal data based on your consent before the withdrawal is made; nor does it affect any data processing based on another justification other than your consent.    </p>

      <p className="subTitle mt50">
        5.How We Protect and Retain Your Personal Data
      </p>
      <p className=" textIndent2">
        The security of your personal data is important to us. We use appropriate physical, management, and technical measures to protect your personal data from unauthorized access, disclosure, use, modification, damage, or loss. For example, we use cryptographic technologies for data confidentiality, protection mechanisms to prevent attacks, and access control mechanisms to permit only authorized access to your personal data. We also provide training on security and privacy protection for employees to raise their awareness of personal data protection. SpinQ is committed to protecting your personal data; however, please note that no security measure is perfect.
      </p>
      <p className=" textIndent2">
        We will retain your personal information for no longer than is necessary for the purposes stated in this Policy, unless otherwise extending the retention period is required or permitted by law. 
      </p>
      <p className=" textIndent2">
        The data storage period may vary with scenario, product, and service. The standards SpinQ uses to determine the retention period are as follows: the time required to retain personal data to fulfill business purposes, including providing products and services; maintaining corresponding transaction and business records; controlling and improving the performance and quality of products and services; ensuring the security of systems, products, and services; handling possible user queries or complaints and locating problems; whether the user agrees to a longer retention period; and whether the laws, contracts, and other equivalencies have special requirements for data retention; etc. We will maintain your registration information as long as your account is necessary for service provision. You can choose to deregister your account. 
      </p>
      <p className="textIndent2">
        After you deregister your account, we will stop providing you with products and services through your account and delete your relevant personal data, provided that deletion is not otherwise stipulated by special legal requirements.
      </p>

      <div className="subTitle mt50">
        6.Third-Party Providers and Their Services
      </div>
      <p className="textIndent2">
  To ensure a positive user experience, you may receive content or web links from third parties other than SpinQ and its partners ("third parties"). SpinQ does not have the right to control such third parties, but you can choose whether to use the links, view the content and/or access the products or services provided by third parties. </p>
      <p className="textIndent2">
        SpinQ cannot control the privacy practices and data protection policies of third parties that are not subject to this Policy. When you submit personal information to such a third party, please read and refer to the privacy protection policy of the third party.
      </p>
      <div className="subTitle mt50">
        7.Updates to This Policy
      </div>
      <p className="textIndent2">
        SpinQ reserves the right to update or change this Policy at any time. We will release the latest Privacy Policy on this page for any changes. If major changes are made to the Privacy Policy, we may notify you through different channels, for example, posting a notice on our website or sending you direct notification.
      </p>
      <div className="subTitle mt50">
        8.How to Contact Us
      </div>
      <p className="textIndent2">
        If you have any privacy complaints or questions and want to contact SpinQ, please call the company's contact number or send an email to the email address. 
  Where your personal data is processed by SpinQ in accordance with this Privacy Policy, the SpinQ entity that responds to your request, contacts you, provides you with products or services, or has signed or is about to sign a contract with you is the controller of the related personal data. You can click here to obtain the contacts of SpinQ entities.
      </p>
      </div>
  )
  useEffect(()=>{
    document.querySelector(".Pact-block").scrollTo(0,0)
  })
  return (
    <>
    <div className="term">
    <div className="nav-tit">
      <button className="backTo iconfont icon-icon-left-arr" onClick={()=>{
          navigate(-1)
          }}><span>{t('global.back')}</span>
      </button>
    </div>
      {
        lang === 'cn'? termCN : termEN
      }
    </div>
    </>
  )
}
// export {Term}
export default withNamespaces()(Term);