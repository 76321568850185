import * as React from "react";
import { Outlet } from "react-router-dom";

function Pact() {
  console.log("Pact render");
  return (
    <div className="Pact-block">
      <Outlet></Outlet>
    </div>
  );
}

export  {Pact};