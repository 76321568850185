import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import "./course.less"
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import ProNav from '../components/proNav'
let sectionList = [
  { id: 1, name: 'charpter.c1',CourseList:[]},
  { id: 2, name: 'charpter.c2',CourseList:[]},
  { id: 3, name: 'charpter.c3' ,CourseList:[]},
  { id: 4, name: 'charpter.c4' ,CourseList:[]},
  { id: 5, name: 'charpter.c5',CourseList:[] } 
]

function gotoQTHOME() {
  callEquipmentByMessage('{"type":"routeChange","path":"/home"}',()=>{})
}
function CourseList(params) {
  const t = params.t
  console.log(courseNameList);
  courseNameList.map(cit => {
    // sectionList[cit.section].CourseList = []
    for (let i = 0; i < sectionList.length; i++) {
      if (sectionList[i].id === cit.section) {
        cit.courseName = `${t('CourseList.courseInd',{ind:cit.id})}  ${t(cit.name)}`
        if (sectionList[i].CourseList.indexOf(cit)===-1) {
          sectionList[i].CourseList.push(cit)
        }
      }
    }
    return true
  })
  callEquipmentByMessage('{"type":"tabChange","style":"default"}',()=>{})
  const navigate = useNavigate();
  const location = useLocation()
  console.log(location);
  
  const [sectionState, setsectionState] = useState(1)
  
  const [courseListtempStrState,setcourseListtempStrState] = useState()
  const sectionTempStr = sectionList.map((item, ind) => {
    if (ind === sectionList.length - 1) {
      return <div className={classNames({active:(ind+1)===sectionState},"section")} key={ind+'sectionTempStr'} onClick={()=>{navigate('/course/list?page='+(ind+1));setsectionState((ind+1))}}><div className="section-content final"><p>{t(item.name)}</p>{(ind+1)===sectionState && <RightOutline className="rightArr" />}</div></div>
    }
    return <div className={classNames({active:(ind+1)===sectionState},"section")} key={ind+'sectionTempStr'} onClick={()=>{navigate('/course/list?page='+(ind+1));setsectionState((ind+1))}}><div className="section-content"><p>{t(item.name)}</p>{(ind+1)===sectionState && <RightOutline className="rightArr" />}</div></div>
  })
  useEffect(() => {
    const pageSearchObj = splitLocationSearch(location.search) || {page:1}
    if (sectionState!==Number(pageSearchObj.page||1)) {
      setsectionState(Number(pageSearchObj.page||1))
      // return
    }
    console.log("efff");
    let renderCourseList = []
    
    sectionList.map(sec => {
      if (sec.id === sectionState) {
        renderCourseList = sec.CourseList
      }
      return void(0)
    })
    let tempList = renderCourseList.map((item, ind) => {
      return <div className="course-block" key={ind+'block'} onClick={()=>{navigate('/course/detail/'+item.id)}}>
          <div className="course-img">
            <img src={item.profile} alt={ind} />
          </div>
          <div className="course-content">
            <p className="course-tit">{t(item.courseName)}</p>
            <p className="course-desc" dangerouslySetInnerHTML={{__html:t(item.desc)}}></p>
          </div>
        </div>
    })
    if (tempList.length===0) {
      tempList = <div className="empty">{t('global.noData')}</div>
    }
    setcourseListtempStrState(tempList)
    
  },[navigate,sectionState])
  return (<><ProNav t={t}></ProNav>
  <div className="course-list">
  {/* 左侧章节列表 */}
  <div className="tab-for-class-section">
    {sectionTempStr}
  </div>
  {/* 右侧为对应章节的课程列表 */}
  <div className="section-list">
    {courseListtempStrState}
  </div>
</div></>)
}
export default withNamespaces()(CourseList);