import classNames from "classnames";

function updateDecimalTable(e,props,ind) {
  const val = e.target.value.trim()
  let newTask = props.TaskItemData
  let selfBodyInput = props.TaskItemData['taskBodyItemInput']
  selfBodyInput[ind] = val
  newTask['taskBodyItemInput'] = selfBodyInput
  if (newTask['task-body-answer-status']) {
    newTask['task-body-answer-status'][ind] = true
  }
  props.setTaskData(JSON.parse(JSON.stringify(newTask)))
}
// function scrollToView(e) {
//   console.log(e);
//   // alert(e.target.scrollIntoView)
//   e.target.scrollIntoView()
// }
// 第一题，十进制转二进制
function DecimalConvertComponent(props) {
  const t = props.t
  // const correctAnswer = props.TaskItemData['correctAnswer']
  // Q: 在重新渲染时取出正确答案与提交答案对比，记录哪些为错误答案。要求是在修改答案时不会重复触发这个标记，且修改答案时这个标记会被清洗

  if (props.TaskItemData.type !== 'Decimal') return <></>
  console.log(props);
  const selfBodyInput = props.TaskItemData['taskBodyItemInput']
  return<div className="DecimalComponent">
    <div className="table-wrap">
      <div className="table-header">
        <div className="table-header-item">{t('taskData.header.decimal')}</div>
        <div className="table-header-item">{t('taskData.header.binary')}</div>
      </div>
      <div className="table-body">
        <div className="table-line">
          <div className="table-side-item">0</div>
          <div className="table-body-item">0</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">1</div>
          <div className="table-body-item">1</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">2</div>
          <div className="table-body-item">10</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">3</div>
          <div className="table-body-item">11</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">4</div>
          <div className="table-body-item">100</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">5</div>
          <div className="table-body-item">101</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">6</div>
          <div className="table-body-item">110</div>
        </div>
        <div className="table-line">
          <div className="table-side-item">7</div>
          <div className="table-body-item">111</div>
        </div>
      </div>
    </div>
    <div className="table-wrap">
      <div className="table-header">
        <div className="table-header-item">{t('taskData.header.decimal')}</div>
        <div className="table-header-item">{t('taskData.header.binary')}</div>
      </div>
      <div className="table-body">
        <div className="table-line">
          <div className="table-side-item">8</div>
          <div className="table-body-item">1000</div>
        </div>
        {
          [9,10,11,12,13,14,15,16,99,100].map((it,ind) => {
            return <div className={classNames({"paddingbottom":it===16},"table-line")} key={ind+'table'}>
              <div className="table-side-item">{it}</div>
              <div className="table-body-item">
                <input autoComplete="off" type="tel" className={classNames({"error":(props.TaskItemData['task-body-answer-status'] &&!props.TaskItemData['task-body-answer-status'][ind])})} value={selfBodyInput[ind]} onChange={(e)=>{
                  updateDecimalTable(e,props,ind)
                }}/>
              </div>
              {it === 16 &&
                <div className="break-word-line" key={ind+'break-word'}>
                  <div className="break-word-block">...</div>
                  <div className="break-word-block">...</div>
                </div>
              }
            </div>
          })
        }
      </div>
    </div>
    
  </div>
  
}
export default DecimalConvertComponent