import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import { Button, Input, Space, message } from 'antd';
import { ReactComponent as Logo } from './img/account.svg';
import { CheckOutlined } from '@ant-design/icons';
import {fetcher} from '../../utils/fetcher'
import "./ResetPassword.less"
import SparkMD5 from "spark-md5";

function ResetPassword({t}) {
  const location = useLocation()
  const navigate = useNavigate()
  console.log(location.pathname);
  // 获取query参数，如果是注册成功
  const [stage, setStage] = useState(1)
  const [sendingStatus, setSendingStatus] = useState(0)

  const [passwordError, setPasswordError] = useState({
    inputEmailError: '',
    validEmailError: '',
    codeError: '',
    newPwdError: '',
    confirmPwdError: ''
  })

  // 更新密码
  const [passwordValue, setPasswordValue] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmPassword: ''
  })

  // 输入input时验证邮箱正确性
  const handleEmailChange = (e) => {
    switch (e.target.name) {
      case 'email':
        setPasswordValue({
          ...passwordValue,
          email: e.target.value
        })
        // 看邮箱是否匹配正则
        console.log(e.target.value);
        let emailReg = /^([a-zA-Z0-9]+[_|.|]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.|]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,5}$/;    
        if (!emailReg.test(e.target.value)) {
          console.log('邮箱格式不正确');
          setPasswordError({
            ...passwordError,
            inputEmailError:`${t('userSetting.reset.emailTypeErr1')}`
          })
        } else {
          console.log('邮箱格式正确');
          setPasswordError({
            ...passwordError,
            inputEmailError: ''
          })
        }
        break;
      case 'code':
        setPasswordValue({
          ...passwordValue,
          code: e.target.value
        })
        setPasswordError({
          ...passwordError,
          codeError: ''
        })
        break;
      case 'newPassword':
        setPasswordValue({
          ...passwordValue,
          newPassword: e.target.value
        })
        // 满足8-20位字符，且包含字母与数字的组合，不支持任意的特殊符号。
        let pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;
        if (!pwdReg.test(e.target.value)) {
          console.log('密码格式不正确');
          setPasswordError({
            ...passwordError,
            newPwdError: `${t('userSetting.reset.pwdTypeErr1')}`
          })
        } else {
          console.log('密码格式正确');
          setPasswordError({
            ...passwordError,
            newPwdError: ''
          })
        }
        break;
      case 'confirmPassword':
        setPasswordValue({
          ...passwordValue,
          confirmPassword: e.target.value
        })
        if (e.target.value !== passwordValue.newPassword) {
          console.log('两次密码输入不一致');
          setPasswordError({
            ...passwordError,
            confirmPwdError: `${t('userSetting.reset.pwdTypeErr2')}`
          })
        } else {
          console.log('两次密码输入一致');
          setPasswordError({
            ...passwordError,
            confirmPwdError: ''
          })
        }
        break;
      default:
        break;
    }
    
  }


  // 发送验证码，按钮倒计时
  const sendValidCode = () => {
    if (passwordValue.email.trim() === '') { return console.log('email is empty') }
    if (sendingStatus > 0) { return console.log('sending') }
    setSendingStatus(60)
    let fetcherUrl = `/user/teacher/sendCodeByEmail?email=${passwordValue.email}`
    fetcher(fetcherUrl,'POST').then(res => {
      console.log(res)
      return res.json()
    }).then(re => {
      console.log(re);
      // 这里有两种情况，发送成功，失败的话可能是已经注册过了，也可能是邮箱不合法
      if (re.status === 200) {
        // message.success('验证码发送成功')
      } else {
        message.error(re.msg)
      }
    })
    let interval = setInterval(() => {
      setSendingStatus(prevSendingStatus => {
        if (prevSendingStatus === 0) { 
          clearInterval(interval);
          return prevSendingStatus;
        }
        return prevSendingStatus - 1;
      });
    },1000)
    console.log('send valid code')
  }

  // 判断邮箱是否存在
  const checkEmailExist = () => {
    let fetcherUrl = `/user/teacher/existEmail?email=${passwordValue.email}`
    fetcher(fetcherUrl,'GET').then(res => {
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        if (!re.existed) {
          setPasswordError({
            ...passwordError,
            inputEmailError: `${t('userSetting.reset.emailTypeErr2')}`
          })
        } else {
          sendValidCode()
        }
      } else {
        message.error(re.msg)
      }
    })
  }

  // 验证邮箱与验证码
  const checkEmailAndCode = () => {
    // 先调用前端的验证看看email和验证码是否填写
    if (passwordValue.email.trim() === '') {
      return setPasswordError({
        ...passwordError,
        inputEmailError: `${t('userSetting.reset.emailTypeErr1')}`
      })
    }
    if (passwordValue.code.trim() === '') {
      return setPasswordError({
        ...passwordError,
        validEmailError: `${t('userSetting.reset.validCodeErr')}`
      })
    }
    
    let fetcherUrl = `/user/teacher/verifyEmail`
    fetcher(fetcherUrl,'POST',JSON.stringify({email: passwordValue.email, verificationCode: passwordValue.code})).then(res => {
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        setStage(2)
      } else {
        if (re.status === 1020) {
        } else {
          message.error(re.msg)
        }
        // 验证码有误
        setPasswordError({
          ...passwordError,
          codeError: `${t('userSetting.reset.validCodeErr')}`
        })
      }
    })
  }

  // 最终通过email修改密码
  const resetPassword = () => {
    if (passwordValue.newPassword.trim() === '' || passwordValue.confirmPassword.trim() === '') {
      // 密码格式不正确
      setPasswordError({
        ...passwordError,
        newPwdError: `${t('userSetting.reset.pwdTypeErr1')}`
      })
      return 
    }
    if (passwordError.newPassword !== passwordError.confirmPassword) {
      // 两次密码不一致
      setPasswordError({
        ...passwordError,
        confirmPwdError: `${t('userSetting.reset.pwdTypeErr2')}`
      })
      return 
    }
    let fetcherUrl = `/user/teacher/updatePasswordByEmail`
    fetcher(fetcherUrl,'POST',JSON.stringify({email: passwordValue.email, password: SparkMD5.hash(passwordValue.newPassword)})).then(res => {
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        setStage(3)
      } else {
        message.error(re.msg)
      }
    })
  }

  return (
    <div className="resetPassword">
      <div className="resetPasswordWrapper">
        <div className="resetPasswordHeader">
          <p>
            <span onClick={()=>{navigate('/user/login')}}>&lt;  {t('userSetting.back')}</span>
          </p>
        </div>
        <div className="emailCheck">
          <div className="stepsList">
            <div className="stepItem">
              <div className={`stepcircle line ${stage > 1?"active":""}`}>
                {stage > 1?<CheckOutlined />:1}
              </div>
              <p>{t('userSetting.reset.emailValid')}</p>
            </div>
            <div className="stepItem">
            <div className={`stepcircle line ${stage > 2?"active":""}`}>
                {stage > 2?<CheckOutlined />:2}
              </div>
              <p>{t('userSetting.reset.resetPassword')}</p>
            </div>
            <div className="stepItem">
            <div className={`stepcircle  ${stage >= 3?"active":""}`}>
                {stage >= 3?<CheckOutlined />:3}
              </div>
              <p>{t('userSetting.reset.finish')}</p>
            </div>
          </div>
          {
            stage <= 1 ? (<div className="InputWrapper emailInputWrapper">
            <div className="label">{t('userSetting.reset.email')}</div>
            <div className="inputItem">
              <Input name="email" key="emailInput" type="text" onChange={handleEmailChange} placeholder={t('userSetting.reset.enteremail')}  />
              <p className="inputErr">{passwordError.inputEmailError}</p>
            </div>
            <div className="label">{t('userSetting.reset.validCode')}</div>
            <div className="inputItem">
              <Input name="code" key="codeInput" type="text" onChange={handleEmailChange}  placeholder={t('userSetting.reset.enterValidCode')} />
              {sendingStatus===0 ? (<Button onClick={checkEmailExist}>{t('userRegister.getValidCode')}</Button>) : (<Button >{sendingStatus}s</Button>)}
              <p className="inputErr">{passwordError.codeError}</p>
            </div>
            <Button className="nextPage" onClick={checkEmailAndCode}>{t('userSetting.reset.next')}</Button>
          </div>) : stage === 2 ? 
          (<div className="InputWrapper passwordInputWrapper">
            <div className="label">{t('userSetting.reset.newPwd')}</div>
            <div className="inputItem">
              <Input type="password" key="pwdcodeInput" name="newPassword" onChange={handleEmailChange} placeholder={t('userSetting.reset.enterNewPwd')} />
              <p className="inputErr">{passwordError.newPwdError}</p>
            </div>
            <div className="label">{t('userSetting.reset.confirmPwd')}</div>
            <div className="inputItem">
              <Input type="password"  key="confirmpwdInput" name="confirmPassword" onChange={handleEmailChange} placeholder={t('userSetting.reset.enterConfirmPwd')} />
              <p className="inputErr">{passwordError.confirmPwdError}</p>
            </div>
            <Button className="nextPage" onClick={resetPassword}>{t('userSetting.reset.next')}</Button>
            </div>
            )
            : (<div className="InputWrapper successWrapper">
            <div className="successIcon">
              <CheckOutlined />
            </div>
            <div className="successText">{t('userSetting.reset.resetSuccess')}</div>
            <p className="tips">{t('userSetting.reset.loginTips')}</p>
            <Button className="nextPage" onClick={(()=>{navigate('/user/login')})}>{t('userSetting.reset.loginNow')}</Button>
            </div>)
          }
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ResetPassword);