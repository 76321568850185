import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import ProNav from '../components/proNav'
import { Button, Input, Space, Popover } from 'antd';
import { ReactComponent as Logo } from './img/account.svg';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {fetcher} from '../../utils/fetcher'
import SparkMD5 from 'spark-md5';

function Register(params) {
  const t = params.t
  const location = useLocation()
  console.log(location.pathname);
  console.log("check md5",SparkMD5.hash('SpinQ0827') );
  const [status, setStatus] = useState(0) // 0: 注册中 1: 注册成功
  const [email, setEmailState] = useState('')
  const [validCode, setValidCodeState] = useState('')
  const [password, setPasswordState] = useState('')
  const [confirmPwd, setConfirmPwdState] = useState('')
  const [name, setNameState] = useState('')
  const [institution, setInstitutionState] = useState('')
  const [sendingStatus, setSendingStatus] = useState(0)

  // 错误提示
  const [emailError, setEmailError] = useState('')
  const [validCodeError, setValidCodeError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPwdError, setConfirmPwdError] = useState('')
  const [nameError, setNameError] = useState('')
  const [institutionError, setInstitutionError] = useState('')

  const setEmail = (e) => {
    setEmailState(e.target.value)
    let emailReg = /^([a-zA-Z0-9]+[_|.|]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.|]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,5}$/;    
    if (!emailReg.test(e.target.value)) {
      setEmailError(t('userRegister.errorTips.validEmail'))
    } else {
      setEmailError('')
    }
  }
  const setValidCode = (e) => { setValidCodeState(e.target.value) }
  const setPassword = (e) => { 
    setPasswordState(e.target.value) 
    // Password requirements: 8-20 characters, including a combination of letters and numbers, no special characters allowed.
    let passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/
    if (!passwordReg.test(e.target.value)) {
      setPasswordError(t('userRegister.errorTips.pwdTypeErr'))
    } else {
      setPasswordError('')
    }
  }
  const setConfirmPwd = (e) => {
    setConfirmPwdState(e.target.value)
    if (e.target.value !== password) {
      setConfirmPwdError(t('userRegister.errorTips.pwdDimiss'))
    } else {
      setConfirmPwdError('')
    }
  }
  const setName = (e) => {
    setNameState(e.target.value)
    // Name requirements: Supports any Chinese or English characters, no special characters allowed.
    let nameReg = /^[\u4E00-\u9FA5A-Za-z]+$/
    if (!nameReg.test(e.target.value)) {
      setNameError(t('userRegister.errorTips.nameTypeErr'))
    } else {
      setNameError('')
    }
  }
  const setInstitution = (e) => { setInstitutionState(e.target.value) }

  // 发送验证码，按钮倒计时
  const sendValidCode = () => {
    if (email.trim() === '') { return console.log('email is empty') }
    if (sendingStatus > 0) { return console.log('sending') }
    setSendingStatus(60)
    let fetcherUrl = `/user/teacher/sendCodeByEmail?email=${email}`
    fetcher(fetcherUrl,'POST').then(res => {
      console.log(res)
      return res.json()
    }).then(re => {
      console.log(re);
      // 这里有两种情况，发送成功，失败的话可能是已经注册过了，也可能是邮箱不合法
      if (re.status === 200) {

      }
    })
    let interval = setInterval(() => {
      setSendingStatus(prevSendingStatus => {
        if (prevSendingStatus === 0) { 
          clearInterval(interval);
          return prevSendingStatus;
        }
        return prevSendingStatus - 1;
      });
    },1000)
    console.log('send valid code')
  }

  // 提交注册信息
  const submitRegister = () => {
    if (email.trim() === '') { setEmailError(t('userRegister.errorTips.validEmail')) }
    if (validCode.trim() === '') { setValidCodeError(t('userRegister.errorTips.codeErr')) }
    if (password.trim() === '') { setPasswordError(t('userRegister.errorTips.pwdTypeErr')) }
    if (confirmPwd.trim() === '') { setConfirmPwdError(t('userRegister.errorTips.pwdDimiss')) }
    if (name.trim() === '') { setNameError(t('userRegister.errorTips.nameTypeErr')) }
    if (institution.trim() === '') { setInstitutionError(t('userRegister.errorTips.lisenseErr')) }
    if (emailError || validCodeError || passwordError || confirmPwdError || nameError || institutionError) { return  }
    let fetcherUrl = `/user/teacher/signUp`
    fetcher(fetcherUrl,'POST',JSON.stringify({email, name, institutionLicense: institution, password: SparkMD5.hash(password), verificationCode: validCode})).then(res => {
      console.log(res)
      return res.json()
    }).then(re => {
      console.log(re);
      if (re.status === 200) {
        setStatus(1)
      } else {
        if (re.status === 2012) {
          setEmailError(t('userRegister.errorTips.exist'))
        }
        if (re.status === 1020) {
          setValidCodeError(t('userRegister.errorTips.codeErr'))
        }
      }
    })
  }

  // 去登录页
  const navigate = useNavigate()
  const goLogin = () => {
    navigate('/user/login')
  }
  // 获取query参数，如果是注册成功
  return (
    <div className="registerWrapper">
      <div className="registerBlock">
        <div className="registerHeader">
          <p>
            <span onClick={goLogin}>&lt;  {t('userRegister.backToLogin')}</span>
          </p>
        </div>
        <div className="registerBody">
          <div className="registerLabel">
            <div className="normalTit">
              <p>{t('userRegister.register')}</p>
            </div>
            <Logo className="registerSvg" />
          </div>
          <div className="registermain">
            {
              status === 0 ? (
                <div className="beforeRegister">
                  <div className="bigTit">{t('userRegister.userSetting')}</div>
                  <div className="label">{t('userRegister.email')}</div>
                  <div className="inputItem">
                    <Input type="text" value={email} onChange={setEmail} placeholder={t('userRegister.inputEmail')}  />
                    {<div className="errorInput">{emailError}</div>}
                    {/* {<div className="errorInput">{t('userRegister.validEmail')}</div>} */}
                    {/* {<div className="errorInput">{t('userRegister.errorTips.exist')}</div>} */}
                  </div>
                  <div className="label">{t('userRegister.validCode')}</div>
                  <div className="inputItem">
                    <Input type="text" value={validCode} onChange={setValidCode} placeholder={t('userRegister.validInput')} />
                    {sendingStatus===0 ? (<Button onClick={sendValidCode}>{t('userRegister.getValidCode')}</Button>) : (<Button >{sendingStatus}s</Button>)}
                    <div className="errorInput">{validCodeError}</div>
                  </div>
                  <div className="label">{t('userRegister.password')}
                    <i>
                      <Popover
                        overlayClassName="passwordTips userRegisterPopoverLayClass"
                        placement="right"
                        trigger="hover"
                        content={t('userRegister.passwordRule')}
                      >
                        <ExclamationCircleOutlined className="iconSvg" />
                      </Popover>
                    </i>
                  </div>
                  <div className="inputItem">
                    <Input type="password" value={password} onChange={setPassword} placeholder={t('userRegister.inputPwd')} />
                    <div className="errorInput">{passwordError}</div>
                  </div>
                  <div className="label">{t('userRegister.confirmPwd')}<i></i></div>
                  <div className="inputItem">
                    <Input type="password" value={confirmPwd} onChange={setConfirmPwd} placeholder={t('userRegister.enterPwdAgain')} />
                    <div className="errorInput">{confirmPwdError}</div>
                  </div>
                  <div className="bigTit">{t('userRegister.userInfo')}</div>
                  <div className="label">{t('userRegister.name')}
                    <i>
                      <Popover
                        overlayClassName="passwordTips userRegisterPopoverLayClass"
                        placement="right"
                        trigger="hover"
                        content={t('userRegister.nameRules')}
                      >
                        <ExclamationCircleOutlined className="iconSvg" />
                      </Popover>
                    </i>
                  </div>
                  <div className="inputItem">
                    <Input type="text" value={name} onChange={setName} placeholder={t('userRegister.usernameInput')} />
                    <div className="errorInput">{nameError}</div>
                  </div>
                  <div className="label">{t('userRegister.institution')}<i></i></div>
                  <div className="inputItem">
                    <Input type="text" value={institution} onChange={setInstitution} placeholder={t('userRegister.inputLisenseTips')} />
                    {/* <div className="errorInput">{t('userRegister.errorTips.lisenseErr')}</div> */}
                    <div className="errorInput">{institutionError}</div>
                  </div>
                  <Button className="submit" onClick={submitRegister}>{t('userRegister.confirm')}</Button>
                </div>
              ) : (<></>)
            }
            {
              status === 1 ? (
                <div className="registerSuccess">
                  <div className="registerBlock">
                    <div className="regCircleBlock">
                      <CheckOutlined />
                    </div>
                    <p>{t('userRegister.registerSuccess')}</p>
                    <Button className="submit" onClick={goLogin}>{t('userRegister.login')}</Button>
                  </div>
                </div>
              ) : (<></>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}



export default withNamespaces()(Register);