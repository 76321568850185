const recordObj = {
  "irreversibleChangeRecord": [
    {
      "ids":["2_1","5_1","5_2","5_3","5_4","5_5","5_6","6_1","6_3","6_4","6_5","6_6","8_1","10_1"],
      "finalChangetimeStamp":1650349460000
    },
    {
      "ids":["9_1"],
      "finalChangetimeStamp":1650507470000
    }
  ]
}
export default recordObj