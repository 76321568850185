import FillBlankForm from "./FillBlankForm";
function MultiFillBlankForm(props) {
  if (props.TaskItemData.type !== 'MultiFillBlankForm') return <></>
  console.log(props);
  const table = props.TaskItemData['taskDataList'].map((Taskit,ind)=>{
    return <div className="MultiBlankForm" key={'MultiForm'+ind}>
      <FillBlankForm key={Taskit['taskId']+ind+'taskId'} TaskItemData={Taskit}  setTaskData={props.setTaskData}></FillBlankForm>
    </div>
  })
  return table
}
export default MultiFillBlankForm