import { withNamespaces } from 'react-i18next';
import "./proNav.less"
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";

function ProNav(props){
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location);
  const t = props.t
  return <>
  <div className="pro-nav-tit">
    <div className="nav-linker">
      <span className={classNames({"active":['/course/list','/course/detail'].includes(location.pathname)},"linker")} onClick={()=>{navigate('/course/list')}}>{t('CourseList.QuantumCourse')}</span>
      <span className={classNames({"active":['/task/list'].includes(location.pathname) || location.pathname.includes('/task/questions')},"linker")} onClick={()=>{navigate('/task/list')}}>{t('Camp.CourseTraining')}</span>
      <span className={classNames({"active":['/task/record'].includes(location.pathname)},"linker")} onClick={()=>{navigate('/task/record')}}>{t('exercise.record')}</span>
    </div>
  </div></>
}
// export {TaskList}
export default withNamespaces()(ProNav);