import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {courseNameList} from '../../data/CourseDetailData.json'
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import { splitLocationSearch } from '../../utils/index'
import ProNav from '../components/proNav'
import { Button, Input, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./header.less"
import { ReactComponent as Logo } from './img/user.svg';

function Header({t}) {
  let isLogin = false
  let loginType = "student"
  // 查看当前路径，如果路径为登录页面，要将登录信息清除
  const navigate = useNavigate()
  const location = useLocation();
  console.log(location,"location");
  if (location.pathname.includes('/user/login')) {
    sessionStorage.clear()
    // localStorage.clear()
    localStorage.removeItem('role')
    localStorage.removeItem('username')
    localStorage.removeItem('tableData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userId')
    localStorage.removeItem('showedStarCompassModal')
    localStorage.removeItem('institutionLicense')
    localStorage.removeItem('hardwareType')
  }

  let username = localStorage.getItem('username')
  localStorage.getItem('userId') && (isLogin = true)
  localStorage.getItem('role') && (loginType = localStorage.getItem('role'))
  const logout = () => {
    sessionStorage.clear()
    localStorage.clear()
    console.log("clear data start");
    navigate('/user/login')
  }
  return (
  <header className="webHeader">
    <div className="headerContent">
      <div className="pt11">
        <img src="/img/spinQ.png" />
        {/* 模块 */}
        {
          isLogin && loginType === "teacher" && (
            <>
              <span className="eduItem" onClick={()=>{navigate('/course/list')}}>{t('Header.edu')}</span>
              <span className="eduItem" onClick={()=>{navigate('/eduManager/stuRegister')}}>{t('Header.manager')}</span>
            </>
          )
        }
      </div>
      {
        isLogin && (
          <>
            <div className="userSetting">
              <div className="label">
                <span className="labelText">
                  <Logo className="userSvg" />
                  {username}
                  <DownOutlined className="arrDown" />
                </span>
              </div>
              <div className="dropDown">
                {
                  loginType === "teacher" && (
                    <div className="dropDownItem" onClick={()=>{navigate('/user/userSetting')}}>
                      <span>{t('Header.account')}</span>
                    </div>
                  )
                }
                <div className="dropDownItem" onClick={logout}>
                  <span>{t('Header.logout')}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
      <div className="langSetting userSetting">
        <div className="label">
          <span className="labelText">
            <svg className="langSvg" width="18" height="18" viewBox="0 0 18 18" fill="#808080" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM14.19 6H11.9775C11.7375 5.0625 11.3925 4.1625 10.9425 3.33C12.3225 3.8025 13.47 4.7625 14.19 6ZM9 3.03C9.6225 3.93 10.11 4.9275 10.4325 6H7.5675C7.89 4.9275 8.3775 3.93 9 3.03ZM3.195 10.5C3.075 10.02 3 9.5175 3 9C3 8.4825 3.075 7.98 3.195 7.5H5.73C5.67 7.995 5.625 8.49 5.625 9C5.625 9.51 5.67 10.005 5.73 10.5H3.195ZM3.81 12H6.0225C6.2625 12.9375 6.6075 13.8375 7.0575 14.67C5.6775 14.1975 4.53 13.245 3.81 12ZM6.0225 6H3.81C4.53 4.755 5.6775 3.8025 7.0575 3.33C6.6075 4.1625 6.2625 5.0625 6.0225 6ZM9 14.97C8.3775 14.07 7.89 13.0725 7.5675 12H10.4325C10.11 13.0725 9.6225 14.07 9 14.97ZM10.755 10.5H7.245C7.1775 10.005 7.125 9.51 7.125 9C7.125 8.49 7.1775 7.9875 7.245 7.5H10.755C10.8225 7.9875 10.875 8.49 10.875 9C10.875 9.51 10.8225 10.005 10.755 10.5ZM10.9425 14.67C11.3925 13.8375 11.7375 12.9375 11.9775 12H14.19C13.47 13.2375 12.3225 14.1975 10.9425 14.67ZM12.27 10.5C12.33 10.005 12.375 9.51 12.375 9C12.375 8.49 12.33 7.995 12.27 7.5H14.805C14.925 7.98 15 8.4825 15 9C15 9.5175 14.925 10.02 14.805 10.5H12.27Z"
              ></path>
            </svg>
            中/En
            <DownOutlined className="arrDown" />
            </span>
        </div>
        <div className="dropDown">
          <div className="dropDownItem" onClick={()=>{window.setGlobalLang('zh')}}>
            <span>中文</span>
          </div>
          <div className="dropDownItem" onClick={()=>{window.setGlobalLang('en')}}>
            <span>English</span>
          </div>
        </div>
      </div>
      
    </div>
  </header>
  )
}

export default withNamespaces()(Header);