import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from '../public/locales/en/translation.json';
import translationCN from '../public/locales/zh/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  cn: {
    translation: translationCN
  }
};
console.log(localStorage.getItem("i18nextLng"));
i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng")||"cn",
    fallbackLng: "cn",
    detection: {
      caches: ['localStorage']
    }, // init会向下传递
    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;