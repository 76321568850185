import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import {db} from '../../data/db'
import {getUrlToken,transformNumberToChinese} from "../../utils/index"
import TaskDetailData from '../../data/TaskDetailData.json'
import { Toast, Modal } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import ProNav from '../components/proNav'
import TaskDetail from './TaskDetail'

import './Record.less'
function Record(params) {
  const t = params.t
  const isTeacherRecord = params.isTeacherRecord
  let navigate = useNavigate();
  let location = useLocation()
  const hash = window.location.hash
  const [recordList, setRecordList] = useState([])
  // if (!hash.includes('?')) return false
  const currentUserId = localStorage.getItem('userId')
  const currentRole = localStorage.getItem('role')
  const accessToken = localStorage.getItem('accessToken')
  const accountId = localStorage.getItem('accountId')
  
  const [accessTokenState, setaccessToken] = useState(accessToken)
  const [dropDownState, setDropDownState] = useState([])
  const [finishedAndLeft,setFinishedAndLeftState] = useState([0,0])
  const [tempStr, setTempStr] = useState('')
  const [recordModalData, setRecordModalData] = useState({
    visible: false,
    courseId: 0,
    taskId: 0
  })

  // Modal.alert(accessToken,accessTokenState)
  if (accessToken!==accessTokenState) {
    setaccessToken(accessToken)
  }

  // 控制下拉
  function changeDropDownState(charpterId,courseId) {
    console.log(charpterId,courseId);
    const charpterInd = Number(charpterId) - 1
    const courseInd = Number(courseId)
    let flag = recordList[charpterInd][courseInd].dropActive 
    if (!flag) {flag = true}
    else {flag = false}
    if (recordList[charpterInd][courseInd]) {
      recordList[charpterInd][courseInd].dropActive = flag
    }
    setRecordList(JSON.parse(JSON.stringify(recordList)))
  }

  useEffect(()=>{
    console.log("effect");
    questionlist()
  },[accessTokenState,dropDownState])

  // 对于pro
  useEffect(()=>{
    console.log("effect");
    if (location.pathname.includes('/teamDetail')) {
      questionlist()
    }
  },[params.teamRecords])

  if (!currentUserId) return false

  const questionlist = async () => { 
    let res;
    console.log(location);
    if (location.pathname.includes('/teamDetail')) {
      res = params.teamRecords
    } else {
      console.log("else");
      try {
        res = await db['taskTable'].toArray()
        // setTempStr(JSON.stringify(res))
      } catch (error) {
        console.log(error)
      }
    }
    // console.log("record ---- res");
    let modifyNum = 0;
    let successNum = 0;
    console.log(TaskDetailData);
    // 0.根据完成情况聚合
    const cpTaskDetailData = JSON.parse(JSON.stringify(TaskDetailData))
    // console.log("record ---- cpTaskDetailData");
    console.log(cpTaskDetailData);
    res.map(resItem => {
      // 由于云端的状态是大写的要转小写
      console.log(resItem);
      if (!resItem || !resItem.taskStatus) return null
      console.log(resItem.taskStatus.toLowerCase());
      const taskIndex = Number(resItem.taskId.split('_')[1]);
      let task = cpTaskDetailData.taskList[resItem.courseId-1]
        .taskDetailList[taskIndex-1];
      if (task) {
        task.taskStatus = resItem.taskStatus.toLowerCase();
      }
      console.log(resItem.taskStatus.toLowerCase());
      console.log(['succeed','failed'].includes(resItem.taskStatus.toLowerCase()));
      if (['succeed','failed'].includes(resItem.taskStatus.toLowerCase())) {
        modifyNum++
        console.log("record ---- modifyNum");
        console.log(modifyNum);
        if (resItem.taskStatus.toLowerCase()==='succeed') {
          successNum++
        }
      }
      console.log("record ---- resItem.courseId,taskIndex");
      console.log(resItem.courseId,taskIndex);
      return true
    })
    // 1.根据章节进行聚合
    let maxCharpterNum = 5
    console.log(cpTaskDetailData.taskList);
    for(let i=0;i<cpTaskDetailData.taskList.length;i++) {
      if (cpTaskDetailData.taskList[i].charpter &&
          cpTaskDetailData.taskList[i].charpter > maxCharpterNum) {
        maxCharpterNum = cpTaskDetailData.taskList[i].charpter
      }
    }
    let newTaskList = new Array(5)
    for (let j = 0; j < newTaskList.length; j++) {
      newTaskList[j] = []
    }
    // 2.根据课程进行聚合
    (cpTaskDetailData.taskList).map(
      taskDetailItem => {
        // console.log(taskDetailItem);
        if (taskDetailItem.charpter) {
          // console.log(newTaskList[taskDetailItem.charpter-1]);
          newTaskList[taskDetailItem.charpter-1].push(taskDetailItem)
        }
        return true
      }
    )
    // Modal.alert("questionlist start"+JSON.stringify(newTaskList))
    
    console.log(newTaskList);
    console.log("record ---- newTaskList");
    sessionStorage.setItem("newTaskList", newTaskList)
    setRecordList(newTaskList)
    console.log(successNum,modifyNum);
    setFinishedAndLeftState([successNum,modifyNum])
  }
  
  const handleReadRecord = (recordLessionItem, exerciseItem) => {
    console.log("handleReadRecord", isTeacherRecord, recordLessionItem, exerciseItem);
    if (isTeacherRecord) {
      // 弹窗，在窗口中处理taskDetail
      // 先存相关的处理完毕的数据再打开弹窗
      let tmpRecord = JSON.parse(JSON.stringify(params.teamRecords))
      let record = tmpRecord.find(item => (`${item.taskId}` === `${exerciseItem.taskId}` && `${item.courseId}` === `${recordLessionItem.courseId}`))
      // if (!record) return
      console.log(record,"record exist open the modal");
      // 清洗数据，存储到window中。再跳转
      if (record) {
        record.taskInputData = JSON.parse(record.taskInputData)
      }
      window.recordData = record
      setRecordModalData({
        visible: true,
        courseId: recordLessionItem.courseId,
        taskId: exerciseItem.taskId,
        title: t(exerciseItem.typeStr)
      })
    } else {
      // 跳转
      navigate(`/task/detail/${recordLessionItem.courseId}-${exerciseItem.taskId}?from=record`)
    }
  }

  const closeSelf = (e) => {
    console.log(e.target.className,"closeSelf");
    if (e.target.className === 'selfModalContainer' || e.target.className === 'selfModalContent'  || e.target.className === 'closeModalBtn') {
      setRecordModalData({
        visible: false,
        courseId: 0,
        taskId: 0
      })
    }
  }
  // 已进行的列表页
  return (
    <>
      <ProNav t={t}></ProNav>
      <div className="padding-wrap">
        <div className="record-list">
          <div className="status">
            <p>
              {/* <div onClick={()=>{window.getDBData()}}>readTask</div> */}
              <span className="label">{t('record.answer')}：</span>
              <span className="recordNum">{t('record.answerNum',{num:finishedAndLeft[1]})}</span>
              <span className="label">{t('record.correctRate')}：</span>
              <span className="recordNum">{finishedAndLeft[0]===0?'0%':(finishedAndLeft[0]/finishedAndLeft[1]*100).toFixed(0)+'%'}</span>
            </p>
          </div>
          {/* {JSON.stringify(recordList)} */}
          {
            recordList.map((recordCharpterList, charpterIndex) => {
              return (
                <div className="charpter" key={charpterIndex+'charpter'}>
                  {/* TODO:章节名称 */}
                  {/* {JSON.stringify(recordCharpterList)} */}
                  <div className="ctitle">{t('CourseList.charpterInd',{ind:transformNumberToChinese(t('lang'),recordCharpterList[0].charpter)})}：{t('charpter.c'+recordCharpterList[0].charpter).replace('\n','')}</div>
                  {
                    recordCharpterList.map((recordLessionItem,lessonIndex) => {
                      return (<div className="lessonWrapper" key={lessonIndex+'wrapper'}>
                              <div className="lessontit">
                                <div className="tit">
                                {t('CourseList.courseInd',{ind:recordLessionItem.courseId})} {t(recordLessionItem.title)}
                                </div>
                                <div className="dropDown" onClick={()=>{changeDropDownState(recordLessionItem.charpter,lessonIndex)}}>
                                  <RightOutline className={classNames({dropActive:recordLessionItem.dropActive},"rightArr")} />{recordLessionItem.dropActive?t('record.fold'):t('record.unfold')}{t('lang')==='en'?' ' : ''}{t('record.details')}
                                </div>
                              </div>
                                <div className={classNames({dropActive:recordLessionItem.dropActive},"record-taskList")}>
                                  {
                                    recordLessionItem.taskDetailList.map((exerciseItem, exerciseId) => {
                                      return (
                                        <div key={exerciseId+'recordItem'}>
                                          <div className="recordItem" onClick={()=>{handleReadRecord(recordLessionItem, exerciseItem)}}>
                                            <div className="tag">
                                              <div className="item-tit">
                                                {t('lang')==='cn'?exerciseItem.taskName:(t(exerciseItem.taskName)).replace('练习','Exercise ').replace('选做题','Optional').replace('选题','Optional')}
                                              </div>
                                              {exerciseItem.taskStatus==="succeed"? <div className="itemStatus">{t('record.completed')}</div> :(exerciseItem.taskStatus==="failed"?<div className="itemStatus failed">{t('record.tobecorrected')}</div>:<></>)}
                                              <div className="clear"></div>
                                            </div>
                                            <div className="item-desc" dangerouslySetInnerHTML={{__html:t(recordLessionItem.taskDetailList[exerciseId].typeStr)}}></div>
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                </div>
                                )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        {/* 学生完成记录的弹窗 */}
        {
          recordModalData.visible  && 
            <div className="selfModalContainer" key={new Date().getTime()} onClick={(e)=>{closeSelf(e)}}>
              <div className="selfModalContent">
                <div className="selfModalAbsolute">
                  <div className="taskHead"><p className="taskTitle">{recordModalData.title}</p><span className="closeModalBtn" onClick={(e)=>{closeSelf(e)}}>+</span></div>
                  <TaskDetail t={t} courseId={recordModalData.courseId} taskId={recordModalData.taskId}></TaskDetail>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  )
}
export default withNamespaces()(Record);
