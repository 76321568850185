// import logo from './logo.svg';
// import './App.css';
import { useRoutes } from "react-router-dom";
import routes from './router';
import { withNamespaces } from 'react-i18next';

function App({ t }) {
  // console.log(t);
  return (
    useRoutes(routes)
  );
}

export default withNamespaces()(App);
