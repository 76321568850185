import React, { useState, useEffect } from "react";
import "./App.css";
import './TaskList.less';
import { useNavigate } from "react-router-dom";
import { Modal } from 'antd-mobile';
import classNames from 'classnames';
import {db} from '../../data/db';
import CourseDetailData from '../../data/CourseDetailData.json';
import TaskDetailData from '../../data/TaskDetailData.json';
import callEquipmentByMessage from '../../utils/bridge'
import imgSrc from './img/TaskList/background.jpg'
import imgSrcFull from './img/TaskList/full16-9background.jpg'
import imgSrc1 from './img/TaskList/center.png'
import imgSrc2 from './img/QuestionList/background.png'
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import ProNav from '../components/proNav'
function TaskList({t}) {

  callEquipmentByMessage('{"type":"tabChange","style":"transparent"}')
  let navigate = useNavigate();
  const currentRemUnit = Number(document.documentElement.style.fontSize.slice(0,-2));

  const [currentStatusState, setCurrentStatus] = useState(new Array(16)) // 使用state创建的内容将激活数据绑定,set与
  const [clickState, setClickStatus] = useState(new Array(16).fill(false))
  const [visibleMsgBox, setMsgBoxVisible] = useState(!(localStorage.getItem("showedStarCompassModal") && localStorage.getItem("showedStarCompassModal")==="true"))
  const [backGIState,setBackGround] = useState('')
  const [ballState,setBallImage] = useState('')
  var img = new Image();
  var img1 = new Image();
  img1.src = imgSrc1;
  img.src = imgSrcFull;

  if (!backGIState) {
    img.onload = () => {
      setBackGround(imgSrcFull)
    }
  }
  if (!ballState) {
    img.onload = () => {
      setBallImage(imgSrc1)
    }
  }
  var img2 = new Image();
  img2.src = imgSrc2;
  useEffect(()=>{
    async function fetchData() {
      updateHighlight()
    }
    fetchData()
  },[])
  function gotoQTHOME() {
    callEquipmentByMessage('{"type":"routeChange","path":"/home"}')
  }
  function mouseDown(idx) {
    console.log("visit mouse down")
    let copyClickState = JSON.parse(JSON.stringify(clickState))
    copyClickState[idx-1] = true
    setClickStatus(copyClickState)
  }

  function mouseUp(idx) {
    console.log("visit mouse up")
    let copyClickState = JSON.parse(JSON.stringify(clickState))
    copyClickState[idx-1] = false
    setClickStatus(copyClickState)
  }

  async function updateHighlight() {
    const currentUserId = localStorage.getItem('userId') || 'guest'
    let copyStatus = new Array(16).fill(false)
    for (let j = 0; j < CourseDetailData.courseNameList.length; j++) {
      const course = CourseDetailData.courseNameList[j];
      if (course.requiredQnum <= 0) {
        copyStatus[j] = false
        continue
      } 
      const questionlist = await db['taskTable'].where({userId:currentUserId,courseId:course.id.toString()}).toArray();
      if (questionlist.length < course.requiredQnum) {
        copyStatus[j] = false
      } else {
        let requiredStatusList = new Array(course.requiredQnum).fill(false)
        for (let i = 0; i < questionlist.length; i++) {
          const taskId = questionlist[i].taskId.split('_')[1]-1
          console.log(course.id,course.requiredQnum,questionlist);
          if (course.id === 16) {
            if (questionlist[i].taskStatus===TaskDetailData.taskStatus.succeed) {
              requiredStatusList = requiredStatusList.map(it => {
                return true
              })
            }
          } else {
            if (taskId <= course.requiredQnum && questionlist[i].taskStatus===TaskDetailData.taskStatus.succeed) {
              requiredStatusList[taskId] = true
            }
          }
        }
        copyStatus[j] = true
        console.log(JSON.parse(JSON.stringify(requiredStatusList)));
        for (let m = 0; m < requiredStatusList.length; m++) {
          copyStatus[j] = copyStatus[j] && requiredStatusList[m]
        }
      }
    }
    console.log(JSON.parse(JSON.stringify({copyStatus})));
    setCurrentStatus(copyStatus)
  }

  return (
    <>
      <ProNav></ProNav>
      <div className="basic-bg" style={{backgroundImage:"url('"+backGIState+"')"}}></div>
      <div className="task-list training">
        <div className="txt-wrapper">
        {
          currentStatusState.map((stateitem,sind)=>{
            return (
              <div className={classNames({'active-txt': (stateitem || clickState[sind])},"star-txt")} id={"txt-"+(sind+1)} key={"txt-"+(sind+1)}>
                <div className="en-wrapper">
                  <svg className='en' version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlSpace="preserve" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 226 591" width="100%" height="100%">
                    <defs><path id="path1" d="M12,54 A591 591, 1, 0, 1 210,98" /></defs>
                    <text><textPath xlinkHref="#path1">{CourseDetailData.courseNameList[sind].starEnName}</textPath></text>
                  </svg>
                </div>
                <div className="num-cn-wrapper">
                  <div className="num-wrapper"><div className={classNames('num',t('lang')==='en'?'iconfont icon-star-for-constellation-camp':'circleStyle')}><span>{CourseDetailData.courseNameList[sind].id}</span></div></div>
                  {t('lang')==='cn'?<div className="cn">{CourseDetailData.courseNameList[sind].starCnName}</div>:''}
                </div>
              </div>
            )
          })
        }
        </div>
        <div className="center-wrapper">
          <div  className={classNames({'rotating': true}, "animated", "center")}  style={{backgroundImage:"url('"+ballState+"')"}}></div>
        </div>
        <div className="circleBlock">
          {
            clickState.map((item,ind)=>{
              if (ind+1 <= 16) {
                return (<div className={classNames('circle'+(ind+1),'circle')} onTouchStart={()=>{mouseDown(ind+1)}} onTouchEnd={()=>{mouseUp(ind+1)}} onClick={()=>{navigate('/task/questions/'+(ind+1))}}></div>)
              } else {
                return (<div className={classNames('circle'+(ind+1),'circle')}></div>)
              }
            })
          }
        </div>

        <div className="star-compass-description">
          <div className="title">{t('Camp.ConstellationCamp')}</div>
          <div className="tips">{t('Camp.StartTraining')}</div>
        </div>
        <Modal
          className="task-list-modal"
          bodyClassName="task-list-modal-body"
          visible={visibleMsgBox}
          content={
            <>
              <div className="star-icon-wrap"></div>
              <div className="txt-wrap">
                <div className="tips">{t('Camp.CompleteTips')}</div>
                <div className="cheerup">{t('Camp.DoIt')}</div>
              </div>
            </>
          }
          closeOnMaskClick
          closeOnAction
          onClose={() => {
            localStorage.setItem("showedStarCompassModal", "true");
            setMsgBoxVisible(false)
          }}
          actions={[
            {
              key: 'confirm',
              text: t('Camp.CourseSelection'),
            },
          ]}>
        </Modal>
      </div>
    </>
  );
  
}
// export {TaskList}
export default withNamespaces()(TaskList);