import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import callEquipmentByMessage from '../../utils/bridge'
import classNames from "classnames";
import './StuRegister.less'
import i18n from '../../i18n';
import { Button, Table, Space, Modal, message, Pagination, Input, Tooltip  } from 'antd';
import Loading from '../components/loading'
import 'antd/dist/antd.css'
import { DownloadOutlined, SyncOutlined, InfoCircleOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import imgSrc from './img/noData.png'
import jschardet from 'jschardet'
import * as iconv from 'iconv-lite';
import {csv} from "csvtojson"
import { withNamespaces } from 'react-i18next';
import {fetcher} from '../../utils/fetcher'
import { column, resolve } from "mathjs";
import { ReactComponent as FirstPage } from "./img/pagination/first-page.svg"
import { ReactComponent as Prev } from "./img/pagination/prev.svg"
import { ReactComponent as LastPage } from "./img/pagination/last-page.svg"
import { ReactComponent as Next } from "./img/pagination/next.svg"
import { ReactComponent as ImportFile } from "./img/importFile.svg"
import { ReactComponent as CloseIcon } from "./img/close.svg"
import Import from "./img/import.png"
import Disconnect from './img/disconnect.png'

function StuRegister({t}) {
  const columns = [
    {
      title: t('stuRegister.form.name'),
      dataIndex: 'name',
      width:`${360 / 2560 * 100}vw`,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      render: name => (
        name.length > 21 ? 
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
        :
        <span>{name}</span>
      )
    },
    {
      title: t('stuRegister.form.code'),
      dataIndex: 'code',
      key: 'code',
      showSorterTooltip:false,
      sorter: true,
      width:`${400 / 2560 * 100}vw`,
      render: code => (
        code.length > 26 ? 
        <Tooltip placement="topLeft" title={code}>
          {code}
        </Tooltip>
        :
        <span>{code}</span>
      )
    },
    {
      title: t('stuRegister.form.className'),
      dataIndex: 'className',
      width:`${380 / 2560 * 100}vw`,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      render: className => (
        className.length > 25 ?
        <Tooltip placement="topLeft" title={className}>
          {className}
        </Tooltip>
        :
        <span>{className}</span>
      )
    },
    {
      title: t('stuRegister.form.groupId'),
      dataIndex: 'groupId',
      width:`${340 / 2560 * 100}vw`,
      ellipsis: {
        showTitle: false,
      },
      showSorterTooltip: false,
      render: groupId => (
        groupId.length > 23 ?
        <Tooltip placement="topLeft" title={groupId}>
          {groupId}
        </Tooltip>
        :
        <span>{groupId}</span>
      )
    },
    {
      title: t('stuRegister.form.teamId'),
      dataIndex: 'teamId',
      showSorterTooltip: false,
      width:`${360 / 2560 * 100}vw`
    },
    {
      title: t('stuRegister.form.operate'),
      key: 'action',
      showSorterTooltip: false,
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <Button key={record.id+"btn"} className="freshBtn del" type="link" onClick={()=>{showDelModal(record.id)}}>{t('stuRegister.form.del')}</Button>
        </Space>
      ),
      width:`${200 / 2560 * 100}vw`
    },
  ];
  const [data, setData] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const [keySearchData, setKeySearchData] = useState([])
  const [currentPageData, setCurrentPageData] = useState([])
  const [sortType, setSortType] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [delModalVisble,setDelModalVisible] = useState(false)
  const [confirmImportVisble,setConfirmImportVisble] = useState(false)
  const [deletedId, setDeletedId] = useState(0);
  const [postBody, setPostBody] = useState([])
  const [errorType, setErrorType] = useState(false)
  const [fileName, setFileName] = useState("")
  const [jumpPage, setJumpPage] = useState(1) // 分页器中输入的页面
  const [currentPage, setCurrentPage] = useState(1) // 当前页面
  const [pagination,setPagination] = useState({pageSize:10, total:0, showTotal:true, showSizeChanger:true, showQuickJumper: true})
  const [timeoutStatus, setTimeoutStatus] = useState(false)
  const [showLoadingState, setShowLoading] = useState(false)

  const showDelModal = (delId) => {
    setDelModalVisible(true)
    setDeletedId(delId)
  }
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    uploadFilterData()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setErrorType(false);
    setFileName("");
    setPostBody([]);
  };

  const handleDelStuData = () => {
    fetcher(`/user/student/delete?studentId=${deletedId}`,'DELETE').then(res => {
      if (res.status === 200) {
        message.success(t('stuRegister.form.delSuccess'))
        setDelModalVisible(false)
        getAllStuList()
      } else {
        message.error(t('stuRegister.form.delFail'))
      }
    })
  };

  const onChange = (anypage, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
    // 排序不会另数据丢失
    let tempData = data.concat([])
    const pageSize = pagination.pageSize
    let tempCurrentPageData,sortData
    if (sorter.order === 'ascend') {
      sortData = tempData.sort((a,b) => (Number(a.code) - Number(b.code)))
      console.log(sortData,currentPage,pagination.pageSize,(currentPage-1) * pageSize);
      setSortType('ascend')
    } else if (sorter.order === 'descend') {
      sortData = tempData.sort((a,b) => (Number(b.code) - Number(a.code)))
      setSortType('descend')
    } else {
      sortData = tempData
      setSortType('')
    }
    tempCurrentPageData = sortData.splice((currentPage-1) * pageSize , pageSize)
    console.log(JSON.parse(JSON.stringify(tempCurrentPageData)));
    setCurrentPageData(tempCurrentPageData)
  };
  const uploadFile = () => {
    document.querySelector('#upload').click()
  }
  /**
   * @description: 二进制形式读取txt文件 并判断其是否为utf-8
   * @param {type}
   * @return:
   */
  function readFileEncoding(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (evt) {
          let base64Str = reader.result
          let str = atob(base64Str.split(';base64,')[1])
          let encoding = jschardet.detect(str)  // 通过jschardet获取txt文件对应的编码格式
          encoding = encoding.encoding
          resolve(encoding)
        };
        reader.readAsDataURL(file);
    });
  }

  function readAndDecodeFile(file,encoding) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (evt) {
        let decodeStr = iconv.decode(Buffer.from(evt.target.result, 'hex'), encoding.toLowerCase());
        resolve(decodeStr)
      };
      reader.readAsArrayBuffer(file);
    });
  }
  
  const fileChange = async (e) => {
    console.log(e);
    const inputer = document.querySelector('#upload')
    const file = inputer.files[0]
    console.log(file);
    console.log('---------------')
    console.log(file.type);
    // 获取文件后缀与文件名，用于显示且后缀不为csv时要有提示报错
    if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
      setErrorType(true)
      return
    } else {
      setErrorType(false)
      setFileName(file.name)
    }
    // 需要注意浏览器的文件解析需要先预设编码，所以要先判断用户上传的是utf8还是gbk
    let encoding = await readFileEncoding(file)
    console.log(encoding);
    // 解析文件以获取的编码解析
    let decoded = await readAndDecodeFile(file,encoding)
    csv().fromString(decoded).then(res => {
      console.log(res);
      console.log(t('lang'));
      let tempPostBody = []
      let loseDataflag = false; 
      let loseInds = [];
      if (t('lang') === 'en') {
        tempPostBody = res.map((rit,rind) => {
          if (!rit['Name']||!rit['Student ID']) { loseDataflag = true; loseInds.push(rind) }
          return {name: rit['Name'],code:rit['Student ID'],className:rit['Class']}
        })
      } else {
        tempPostBody = res.map((rit,rind) => {
          if (!rit['姓名']||!rit['学号']) { loseDataflag = true; loseInds.push(rind) }
          return {name: rit['姓名'],code:rit['学号'],className:rit['行政班级']}
        })
      }
      if (loseDataflag) {
        if (t('lang') === 'cn') {
          // message.error(loseInds.join(',')+"行中姓名，学号，行政班级为必填项，请补充后上传");
          message.error('请选择语言模式合适的文件')
          setFileName("");
          setPostBody([]);
        } else {
          // message.error(`Name , Code, ClassName is required in lines ${loseInds.join(',')}`);
          message.error('Please select the appropriate file for the language mode')
          setFileName("");
          setPostBody([]);
        }
        return;
      }
      setPostBody(tempPostBody)
      // 选择完文件后需要显示文件名呢，提供删掉的功能，及校验非csv弹出红色提示
    })
  }

  /**
   * 上传过滤完毕的数据
   */
  function uploadFilterData() {
    if (postBody.length === 0) { setErrorType(true); return; }
    message.loading({ content: t('stuRegister.loadData'), duration: 0, key: "uploadFileDataKey"})
    fetcher('/user/student/batchImport','POST',JSON.stringify({items:postBody})).then(res => {
      console.log(res);
      setTimeout(() => {
        message.destroy("uploadFileDataKey")
      }, 500);
      if (res.status === 200) {
        // message.success("upload success")
        setIsModalVisible(false)
        getAllStuList()
        setFileName("");
        setPostBody([]);
      } else {
        console.log(res);
        message.error("upload error")
      }
    }).catch(e => {
      message.destroy("uploadFileDataKey")
      message.error(t('stuRegister.netWorkErr'))
    })
  }

  /**
   * 获取名册数据
   */
  function getAllStuList() {
    setShowLoading(true)
    fetcher('/user/student/getAllInfo','GET').then(res => {
      // setData(res)
      console.log(res);
      return res.json();
      // setData()
    }).then(parseData => {
      setTimeoutStatus(false)
      console.log(parseData);
      setTimeout(() => {
        message.destroy("fresh")
      }, 500);
      let tempPagination = Object.assign(pagination,{})
      tempPagination.total = parseData.items.length
      setPagination(tempPagination)
      let filterData = parseData.items.map(it => {
        if (!it.courseDTO) {it.groupId = "--"}
        else {
          it.groupId = it.courseDTO.name
        }
        if (!it.teamInfoDTO) {it.teamId = "--"}
        else {
          it.teamId = it.teamInfoDTO.username
        }
        it.key = it.id
        return it
      })
      setData(filterData)
      const pageSize = pagination.pageSize
      // 默认页码为1
      setCurrentPage(1)
      setJumpPage(1)
      const tempCurrentPageData = filterData.concat([]).splice(0,0+pageSize)
      console.log(tempCurrentPageData);
      setCurrentPageData(tempCurrentPageData)
      setShowLoading(false)
    }).catch(e => {
      // 超时
      setTimeoutStatus(true)
      setShowLoading(false)
      console.log(e);
      message.destroy("fresh")
      message.error(t('stuRegister.netWorkErr'))
    })
  }

  function fresh() {
    // 刷新并提供一个loading
    message.loading({content:t('stuRegister.loadData'),key:"fresh"})
    getAllStuList()
  }

  function batchImportTips() {
    if (data.length > 0) {
      setConfirmImportVisble(true)
    } else {
      showModal()
    }
  }

  const showTotal = (total) => t('lang')==='cn'?`共 ${total} 条`:`Total ${total} items`;
  const showCurrentPageData = (pageNum) => {
    // 根据页码截取对应PageSize的内容显示
    if (pageNum < 1 || (pageNum > Math.ceil(data.length/pagination.pageSize))) { return false }
    setCurrentPage(Number(pageNum))
    console.log(Number(pageNum),currentPage);
    const pageSize = pagination.pageSize
  }
  const itemRender = (_, type, originalElement) => {
    console.log(originalElement);
    if (type === 'prev') {
      return <Prev />;
    }
  
    if (type === 'next') {
      return <Next />;
    }
    return originalElement;
  };

  useEffect(()=>{
    // 首次调用触发获取名单
    getAllStuList()
  },[])

  useEffect(()=>{
    onChange('','',{order:sortType},'')
  },[currentPage])

  const searchDataByNameAndId = (e) => {
    let val = e.target.value
    setSearchKey(val)
    // 重置页码，升降顺序
    setCurrentPage(1)
    setSortType('')
    setJumpPage(1)
    // 检索所有数据的姓名和学号
    let tempData = data.concat([])
    let tempKeySearchData = tempData.filter(it => {
      return it.name.includes(val) || it.code.includes(val)
    })
    setKeySearchData(tempKeySearchData)
    // 更新到currentData
    const pageSize = pagination.pageSize
    const tempCurrentPageData = tempKeySearchData.concat([]).splice(0,0+pageSize)
    setCurrentPageData(tempCurrentPageData)
    // 更新总页码
    let tempPagination = Object.assign(pagination,{})
    tempPagination.total = tempKeySearchData.length
    setPagination(tempPagination)

  }

  return <>
    <div className="StuRegister">
      {!timeoutStatus && <Button className="importBtn" icon={<img src={Import} />} type="primary" onClick={()=>{batchImportTips()}}>{t('stuRegister.batchUpload')}</Button>}
      {
        data.length > 0 && (
          <>
            <Button className="freshBtn" type="link" icon={<SyncOutlined />} onClick={()=>{fresh()}}>{t('stuRegister.fresh')}</Button>
            <Input className="searchInput" placeholder={t('stuRegister.searchTips')} onChange={(e)=>{searchDataByNameAndId(e)}}  prefix={<SearchOutlined />} />
          </>
        )
      }
      {
      data.length === 0 ?
      <div className="noData">{timeoutStatus?<img src={Disconnect} />:<img src={imgSrc} />}<p>{timeoutStatus?t('global.netWorkDisconnected'):t('stuRegister.noData')}</p></div> :
      <>
        <Table className="tableList" showSizeChanger={true} scroll={{ y: '65vh' }} pagination={false} columns={columns} dataSource={currentPageData} onChange={onChange} />
        {/* 分页器需要与QT同步，因此要自己实现一个带前后翻滚，和跳转的分页 */}
        <ul className="ant-pagination" unselectable="unselectable">
          <li className="ant-pagination-total-text">{showTotal(data.length)}</li>
          <li onClick={()=>{showCurrentPageData(1);setJumpPage(1)}} className={classNames({"ant-pagination-disabled":currentPage===1},"ant-pagination-prev")}>
            <FirstPage />
          </li>
          <li  onClick={()=>{if (currentPage > 1) { showCurrentPageData(currentPage-1);setJumpPage(currentPage-1)}}} className={classNames({"ant-pagination-disabled":currentPage===1},"ant-pagination-prev")}>
            <Prev />
          </li>
          <li onClick={()=>{ if (Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize)) >= currentPage + 1){ showCurrentPageData(currentPage+1); setJumpPage(currentPage+1)}}} tabIndex="0" className={classNames({"ant-pagination-disabled":currentPage===Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize))},"ant-pagination-next")}>
            <Next />
          </li>
          <li onClick={()=>{showCurrentPageData(Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize)));setJumpPage(Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize)))}} className={classNames({"ant-pagination-disabled":currentPage===Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize))},"ant-pagination-next","end")}>
            <LastPage />
          </li>
          <li className="ant-pagination-options">
            <div className="ant-pagination-options-quick-jumper">
              <Input type="text" aria-label="" value={jumpPage} onChange={(e)=>{setJumpPage(e.target.value)}} onPressEnter={(e)=>{showCurrentPageData(e.target.value)}} />
              <span><button disabled className="showTotalPage">/{Math.ceil(searchKey ? (keySearchData.length/pagination.pageSize):(data.length/pagination.pageSize))+(t('lang')==='cn'?'页':'Pages')}</button></span>
            </div>
          </li>
        </ul>
      </>
      }
      <Modal
        closable={false}
        key="eduModal" className="eduModal" title={t('stuRegister.batchUploadTit')} visible={isModalVisible} onCancel={()=>{handleCancel()}}
        footer={[<Button key="cancelbtn2" onClick={()=>{handleCancel()}}>{t('global.cancel')}</Button>,<Button key="submit1" type="primary" onClick={handleOk}>
        {t('global.confirm')}
      </Button>]}
      >
        <div className="tips">
          <InfoCircleOutlined /> {t('stuRegister.uploadTips')}
          {/* <a className="freshBtn" download={t('lang')==='cn'?'SpinQuasar_temp_cn.csv':'SpinQuasar_temp_en.csv'} type="link" icon={<DownloadOutlined />} href={t('lang')==='cn'?"/csv/SpinQuasar_temp_cn.csv":"/csv/SpinQuasar_temp_en.csv"}>{t('stuRegister.downLoadbtn')}</a> */}
          <Button icon={<ImportFile />} type="link" className="freshBtn" href={t('lang')==='cn'?"/csv/SpinQuasar_temp_cn.csv":"/csv/SpinQuasar_temp_en.csv"}>{t('stuRegister.downLoadbtn')}</Button>
        </div>
        <div className={classNames({"chooseFile":true,"enChoose":t('lang')==='en',"txtCss":fileName})}>
          <span className="labelInFile">{t('stuRegister.chooseFile')}</span>
          {(!fileName || errorType) ? <Button onClick={uploadFile}>{t('stuRegister.upload')}</Button> : <span className="fileName">{fileName}<CloseIcon className="anticon-close" onClick={()=>{document.querySelector('#upload').value="";setFileName("");setPostBody([])}} /></span>}
           {errorType && <span className="errorType">{t('stuRegister.uploadFileErr')}</span>}
          <input type="file" name="upload" id="upload" style={{display:"none"}} onChange={fileChange} />
        </div>
        <p className={classNames({"fileTips":true,"enFileTips":t('lang')==='en'})}>{t('stuRegister.fileTypeTips')}</p>
      </Modal>
      <Modal
        closable={false}
        key="delModal" className="eduModal delModal" title={t('stuRegister.form.delMsgTit')} visible={delModalVisble} onCancel={()=>{setDelModalVisible(false)}}
        footer={[<Button key="cancelbtn1" onClick={()=>{setDelModalVisible(false)}}>{t('global.cancel')}</Button>,<Button key="submit2" type="primary" onClick={()=>{handleDelStuData()}}>
        {t('stuRegister.form.del')}</Button>]}
      >
        <div className="tips">
          <ExclamationCircleOutlined /> {t('stuRegister.form.delMsgContent')}
        </div>
      </Modal>
      <Modal
        closable={false}
        key="confirmImportModal" className="eduModal delModal" title={t('stuRegister.batchUploadTips1')} visible={confirmImportVisble} onCancel={()=>{setConfirmImportVisble(false)}}
        footer={[<Button key="cancelbtn1" onClick={()=>{setConfirmImportVisble(false)}}>{t('global.cancel')}</Button>,<Button key="submit2" type="primary" onClick={()=>{setConfirmImportVisble(false);showModal()}}>
        {t('global.yes')}</Button>]}
      >
        <div className="tips">
          <ExclamationCircleOutlined /> <span>{t('stuRegister.uploadCoverTips1')}</span>{t('lang')==='cn'?<br/>:''}<span>{t('stuRegister.uploadCoverTips2')}</span>
        </div>
      </Modal>
    </div>
    <div className={classNames({'showLoading':showLoadingState},"loading-wrap")}>
      <Loading />
    </div>
  </>
}
export default withNamespaces()(StuRegister);
